import Project from "./Project.js";
import Projects from "./Projects.js";

const projectList = [
    new Project(
        "Intergalactic Billiard",
        "Azathoths Awakening",
        require("../../media/projects/intergalactic-billiard/thumb-1080.png"),
        require("../../media/projects/intergalactic-billiard/thumb-32.png"),
        require("../../media/projects/intergalactic-billiard/headspace-firstframe.png"),
        require("../../media/projects/intergalactic-billiard/headspace.webm"),
        60,
        new Date(2020,11,24),
        "Ever wanted to fling planets around a procedurally generated universe like billiard balls?",
        ["Uni", "C++", "Games", "OpenGL", "Shaders"],
        "projectIntergalacticBilliard",
    ),
    
    new Project(
        "Bachelor Thesis",
        "Modelling cerebral blood flow",
        require("../../media/projects/bachelor-thesis/thumb-1080.jpg"),
        require("../../media/projects/bachelor-thesis/thumb-32.jpg"),
        require("../../media/projects/bachelor-thesis/headspace-1920.jpg"),
        null,
        80,
        new Date(2019,2,26),
        "Simulation study: parameterization of a hemodynamics Model and conception of a multidimensional sensitivity analysis",
        ["Uni", "BioMed Technology", "MatLab", "SimuLink", "LaTeX"],
        "projectBachelorThesis",
    ),

    new Project(
        "Hiwi Job: Cardiac ablation strategies",
        "Innovative ablation strategies to terminate reentrant activity on simplified left atrium geometry",
        require("../../media/projects/hiwi-cardiac-ablation-strategies/thumb-1080.png"),
        require("../../media/projects/hiwi-cardiac-ablation-strategies/thumb-32.png"),
        require("../../media/projects/hiwi-cardiac-ablation-strategies/headspace-firstframe.png"),
        require("../../media/projects/hiwi-cardiac-ablation-strategies/headspace.webm"),
        90,
        new Date(2021,4,20),
        "Simulating and terminating atrial fibrillations",
        ["Uni", "Job", "BioMed Technology", "Python", "C++"],
        "projectHiwiCardiacAblationStrategies" 
    ),
    /*
    new Project(
        "Hiwi Job: Integrating the Ginkgo Framework",
        "Nothing to see here either",
        require("../../media/static/empty.png"),
        require("../../media/static/empty-32.png"),
        require("../../media/static/empty.png"),
        null,
        70,
        new Date(1970,1,1),
        "Nothing to see here",
        ["Uni", "Job", "BioMed Technology", "C++"],
        "projectEmpty",
        // https://ginkgo-project.github.io/
    ),

//################################################################################################################################################################
    */
    new Project(
        "HaDiKo Heimfest 2023",
        "An over engineered marketing campaign",
        require("../../media/projects/hadiko-heimfest-2023/thumb-1080.png"),
        require("../../media/projects/hadiko-heimfest-2023/thumb-32.png"),
        require("../../media/projects/hadiko-heimfest-2023/headspace-firstframe.png"),
        require("../../media/projects/hadiko-heimfest-2023/headspace.webm"),
        50,
        new Date(2023,5,20),
        "Developing a corporate identity for a student dormitories anniversary party",
        ["Design", "Marketing", "Print", "Shaders"],
        "projectHaDiKoHeimfest2023",
    ),
    /*
    new Project(
        "HaDiKo Heimfest 2022",
        "Nothing to see here either",
        require("../../media/static/empty.png"),
        require("../../media/static/empty-32.png"),
        require("../../media/static/empty.png"),
        null,
        69,
        new Date(2022,7,2),
        "Nothing to see here",
        ["Design", "Marketing", "Print"],
        "projectEmpty",
    ),
    new Project(
        "HaDiKo site plan",
        "Nothing to see here either",
        require("../../media/static/empty.png"),
        require("../../media/static/empty-32.png"),
        require("../../media/static/empty.png"),
        null,
        50,
        new Date(1970,1,1),
        "Nothing to see here",
        ["Design", "Print"],
        "projectEmpty",
    ),
    */
    new Project(
        "HaDiKo newcomer handbook",
        "A guide book for Germanies largest self-administrated dormitory",
        require("../../media/projects/hadiko-handbook/thumb-1080.jpg"),
        require("../../media/projects/hadiko-handbook/thumb-32.jpg"),
        require("../../media/projects/hadiko-handbook/thumb.jpg"),
        null,
        32,
        new Date(2021,3,10),
        "Design and Print of a dormitory handbook",
        ["Design", "Print", "LaTeX"],
        "projectHaDiKoHandbook",
    ),
    
//################################################################################################################################################################

    new Project(
        "CNC milling a birdhouse",
        "Creating CAD files using openSCAD",
        require("../../media/projects/cnc-bird-house/thumb-1080.png"),
        require("../../media/projects/cnc-bird-house/thumb-32.png"),
        require("../../media/projects/cnc-bird-house/headspace-firstframe.png"),
        require("../../media/projects/cnc-bird-house/headspace.webm"),
        30,
        new Date(2021,11,6),
        "Who needs manual wood working when you can just code your CAD?",
        ["CAD", "openSCAD", "CNC"],
        "projectCNCBirdHouse",
    ),

    new Project(
        "3D Printing Majoras Mask",
        "«You've met with a terrible fate, haven't you?»",
        require("../../media/projects/majoras-mask/thumb-1080.jpg"),
        require("../../media/projects/majoras-mask/thumb-32.jpg"),
        require("../../media/projects/majoras-mask/thumb-2560.jpg"),
        null,
        30,
        new Date(2021,9,31),
        "3D printing, painting and soldering a Halloween mask",
        ["3D Printing", "Design"],
        () => require("./projects/ProjectMajorasMask.js").default,
    ),
    

//################################################################################################################################################################

    new Project(
        "GGJ 2017: rem",
        "An experimental game using an eye tracker",
        require("../../media/projects/ggj-2017-rem/thumb-1080.jpg"),
        require("../../media/projects/ggj-2017-rem/thumb-32.jpg"),
        require("../../media/projects/ggj-2017-rem/headspace-firstframe.png"),
        require("../../media/projects/ggj-2017-rem/headspace.webm"),
        3,
        new Date(2017,1,20),
        "Discover a dream world",
        ["GameJam", "Games", "Unity", "EyeTracker"],
        "projectGGJRem",
        [
            {
                icon : "fa-brands fa-github",
                text : "Check out repo",
                href : "https://github.com/perelin/ggj2017_dreamteam"
            },
            {
                icon : "fa-up-right-from-square",
                text : "GGJ page",
                href : "https://globalgamejam.org/2017/games/rem"
            },
        ]
    ),
    
    new Project(
        "GGJ 2018: Headlines Please!",
        "A game about the hectic day of a journalist",
        require("../../media/projects/ggj-2018-headlines-please/thumb-1080.png"),
        require("../../media/projects/ggj-2018-headlines-please/thumb-32.png"),
        require("../../media/projects/ggj-2018-headlines-please/headspace-firstframe.png"),
        require("../../media/projects/ggj-2018-headlines-please/headspace.webm"),
        20,
        new Date(2018,1,20),
        "Do you cash in on easy headlines or do you investigate properly for a differentiated article?",
        ["GameJam", "Games", "Godot", "Play now"],
        "projectGGJHeadlinesPlease",
        [
            {
                icon : "fa-gamepad",
                text : "Play now",
                href : "/play-now/headlines-please"
            },{
                icon : "fa-brands fa-github",
                text : "Check out repo",
                href : "https://github.com/AnJ95/headlines-please"
            },
            {
                icon : "fa-up-right-from-square",
                text : "GGJ page",
                href : "https://globalgamejam.org/2018/games/headlines-please"
            },
        ]
    ),
    
    new Project(
        "GGJ 2019: Home God",
        "«The feeling of home»",
        require("../../media/projects/ggj-2019-homegod/thumb-1080.png"),
        require("../../media/projects/ggj-2019-homegod/thumb-32.png"),
        require("../../media/projects/ggj-2019-homegod/headspace-firstframe.png"),
        require("../../media/projects/ggj-2019-homegod/headspace.webm"),
        2,
        new Date(2019,1,20),
        "Help the Home God and deliver furniture to the people of the world!",
        ["GameJam", "Games", "Godot"],
        () => require("./projects/ProjectGGJHomegod.js").default,
        [
            {
                icon : "fa-brands fa-github",
                text : "Check out repo",
                href : "https://gitlab.com/maschm/homegod"
            },{
                icon : "fa-up-right-from-square",
                text : "GGJ page",
                href : "https://globalgamejam.org/2019/games/home-god"
            },
        ]
    ),
    /*
    new Project(
        "GGJ 2020: Role & Repair",
        "Nothing to see here either",
        require("../../media/static/empty.png"),
        require("../../media/static/empty-32.png"),
        require("../../media/static/empty.png"),
        null,
        3,
        new Date(2020,1,20),
        "Nothing to see here",
        ["GameJam", "Games", "Godot"],
        "projectEmpty",
        // https://globalgamejam.org/2020/games/dicerepair-4
        // https://github.com/matzebond/DiceRepair
    ),
    
//################################################################################################################################################################

    new Project(
        "Ludum Dare 53: That time ...",
        "... I got reincarnated as a barn",
        require("../../media/static/empty.png"),
        require("../../media/static/empty-32.png"),
        require("../../media/static/empty.png"),
        null,
        4,
        new Date(2023,1,9),
        "Nothing to see here",
        ["GameJam", "Games", "Godot"],
        "projectEmpty",
        // https://ldjam.com/events/ludum-dare/52/that-time-i-got-reincarnated-as-a-barn
        // https://github.com/matzebond/FetchTogether
    ),

    new Project(
        "Generic Ultimate Rainbow Race",
        "Nothing to see here either",
        require("../../media/static/empty.png"),
        require("../../media/static/empty-32.png"),
        require("../../media/static/empty.png"),
        null,
        5,
        new Date(2021,3,23),
        "Nothing to see here",
        ["GameJam", "Games", "Web", "JavaScript", "Node.js"],
        "projectEmpty",
        // https://github.com/H0rn0chse/GenericUltimateRainbowRace
        // https://gurr.onrender.com/
    ),

//################################################################################################################################################################

    new Project(
        "Godot Toolbox Project",
        "Nothing to see here either",
        require("../../media/static/empty.png"),
        require("../../media/static/empty-32.png"),
        require("../../media/static/empty.png"),
        null,
        6,
        new Date(2022,3,20),
        "Nothing to see here",
        ["GameJam", "Games", "Godot"],
        "projectEmpty",
        // https://github.com/AnJ95/godot-toolbox-project  
    ),
    
    */

    new Project(
        "An educational game: ReKiT",
        "Learning design patterns by doing!",
        require("../../media/projects/rekit-game/thumb-1080.png"),
        require("../../media/projects/rekit-game/thumb-32.png"),
        require("../../media/projects/rekit-game/gameplay-3-firstframe.png"),
        require("../../media/projects/rekit-game/gameplay-3.webm"),
        29,
        new Date(2016,7,15),
        "Developing a Java game from stratch and using it to teach programming",
        ["Uni", "Games", "Java", "Continuous Integration", "Play now", "Eclipse"],
        "projectRekit",
        [
            {
                icon : "fa-gamepad",
                text : "Download JAR",
                href : "/play-now/rekit-game/ReKIT.jar"
            },{
                icon : "fa-brands fa-github",
                text : "Check out repo",
                href : "https://github.com/rekit-group/rekit-game"
            },
        ]
    ),

    new Project(
        "MarbleTilt",
        "A marble game of skill using the mobile gyrometer",
        require("../../media/projects/marble-tilt/thumb-1080.png"),
        require("../../media/projects/marble-tilt/thumb-32.png"),
        require("../../media/projects/marble-tilt/headspace-firstframe.png"),
        require("../../media/projects/marble-tilt/headspace.webm"),
        40,
        new Date(2018,4,12),
        "Using new HTML5 features to make a creative web app",
        ["Uni", "Games", "Web", "WebGL", "JavaScript", "HTML", "CSS", "jQuery", "Babylon.js", "Play now"],
        "projectMarbleTilt",
        [
            {
                icon : "fa-gamepad",
                text : "Play now",
                href : "/play-now/marble-tilt"
            }
        ]
    ),

    new Project(
        "Wearable technology and sci-fi clothes",
        "Putting vibrating wearable technology on your head",
        require("../../media/projects/notify-me/wearable-technology-glove-1080.png"),
        require("../../media/projects/notify-me/wearable-technology-glove-32.png"),
        require("../../media/projects/notify-me/wearable-technology-glove.png"),
        null,
        20,
        new Date(2018,4,12),
        "An assignment in Ubiquitous Computing and Bluetooth Low Energy",
        ["Uni", "Bluetooth", "Microcontroller", "App", "Web", "PhoneGap", "Backbone.js", "JavaScript", "HTML", "CSS"],
        "projectNotifyMe"
    ),

//################################################################################################################################################################

    new Project(
        "Quizzle",
        "There can never be enough block-pushing puzzle games",
        require("../../media/projects/quizzle-game/thumb-1080.png"),
        require("../../media/projects/quizzle-game/thumb-32.png"),
        require("../../media/projects/quizzle-game/headspace-firstframe.png"),
        require("../../media/projects/quizzle-game/headspace.webm"),
        20,
        new Date(2014,1,6),
        "Rendering 3D in the browser for the first time using canvas",
        ["Web", "Games", "WebGL", "JavaScript", "HTML", "CSS", "jQuery", "Three.js", "Play now"],
        "projectQuizzle",
        [
            {
                icon : "fa-gamepad",
                text : "Play now",
                href : "/play-now/quizzle-game"
            }
        ]
    ),

    /*
    new Project(
        "Uncharted Territory",
        "",
        require("../../media/projects/quizzle-game/thumb-1080.png"),
        require("../../media/projects/quizzle-game/thumb-32.png"),
        require("../../media/projects/quizzle-game/headspace-firstframe.png"),
        require("../../media/projects/quizzle-game/headspace.webm"),
        20,
        new Date(2014,1,6),
        "Combining an OpenStreetMap mixed reality game with tower defence",
        ["Web", "Games", "WebGL", "JavaScript", "HTML", "CSS", "jQuery", "Three.js", "Play now"],
        "projectQuizzle",
        [
            {
                icon : "fa-gamepad",
                text : "Play now",
                href : "/play-now/quizzle-game"
            }
        ]
    ),
    */
    

//################################################################################################################################################################
    
    new Project(
        "This website",
        "A React App with precompiled CSS",
        require("../../media/projects/this-website/thumb-1080.png"),
        require("../../media/projects/this-website/thumb-32.png"),
        require("../../media/projects/this-website/thumb.png"),
        null,
        40,
        new Date(2023,7,8),
        "My personal webspace and project portfolio",
        ["Web", "JavaScript", "HTML", "CSS", "SCSS", "React.js", "Shaders", "Continuous Integration"],
        () => require("./projects/ProjectThisWebsite.js").default,
    ),
    
    new Project(
        "Trainercard",
        "A PHP- and MySQL-based Pokémon browser game",
        require("../../media/projects/trainercard/thumb-1080.png"),
        require("../../media/projects/trainercard/thumb-32.png"),
        require("../../media/projects/trainercard/thumb.png"),
        null,
        35,
        new Date(2009,1,1),
        "Developing and administrating a browsergame as a teenager",
        ["Web", "PHP", "SQL", "JavaScript", "jQuery", "HTML", "CSS"],
        () => require("./projects/ProjectTrainercard.js").default,
    ),

//################################################################################################################################################################
    
];

const projects = new Projects(projectList);
projects.linkTags();
export default projects;