import React from "react";

import Page from './Page.js';
import Button from "../component/Button";
import ContactForm from "../component/ContactForm";
import {address} from "../data/helpers";
import {Box} from "../component/Box";


export default class PageContact extends Page {

    constructor(props) {
        super(props);
        this.setTitle("Contact");
    }

    /*
    renderHeadspace() {
        return (
            <Headspace height="160px" img={k1_aussen}>
                <div className="center--vertical">
                    <h1>Contact</h1>
                </div>
            </Headspace>
        );
    }
    */

    renderContent() {
        return (
            <>
                <Box>
                    <div className="space-content">
                        <h2><i className="h--i fa fa-message" />Contact</h2>
                        <p>Piqued your interest? Feel free to contact me using this contact form or via of the below methods!</p>
                    </div>
                    <ContactForm />
                </Box>
                <div className="row">
                    <div className="col-sm-6">
                        <Box>
                            <div className="space-content">
                                <h2><i className="h--i fa fa-envelope" />Directly</h2>
                                <p>Don't want to waste any time? Contact me directly via one of the following methods:</p>
                            </div>
                            <div className="space-content">
                                <Button
                                    icon="fa-envelope"
                                    size="lg"
                                    href={"mailto:" + address.mail}
                                    block={true}
                                    preventPageTransition={true}
                                >Send a mail</Button>
                                <Button
                                    icon="fab fa-telegram"
                                    size="lg"
                                    href={address.linkTelegram}
                                    block={true}
                                    preventPageTransition={true}
                                >Text me on Telegram</Button>
                                <Button
                                    icon="fa-phone"
                                    size="lg"
                                    href={"tel:" + address.phone}
                                    block={true}
                                    preventPageTransition={true}
                                >Give me a call</Button>
                            </div>
                        </Box>

                    </div>

                    <div className="col-sm-6">
                        <Box className="space-content">
                            <div className="space-content">
                                <h2><i className="h--i fa fa-globe" />Platforms</h2>
                                <p>Alternatively, find and follow my accounts on the following online platforms:</p>
                            </div>
                            <div className="space-content">
                                <Button
                                    icon="fab fa-github"
                                    size="lg"
                                    href={address.linkGitHub}
                                    block={true}
                                    external={true}
                                    preventPageTransition={true}
                                >GitHub</Button>

                                <Button
                                    icon="fab fa-linkedin"
                                    size="lg"
                                    href={address.linkLinkedIn}
                                    block={true}
                                    external={true}
                                    preventPageTransition={true}
                                >LinkedIn</Button>
                            </div>
                        </Box>
                    </div>
                </div>

                <div className="space-content--negative" />
                <div />
            </>
        )
    }
}