
import React, { Component } from 'react';
import {Box} from "../component/Box";
import OnScrollIn from "../component/OnScrollIn";
import TagList from "./TagList";

export default class Project extends Component {
    constructor(props) {
        super(props);
        this.model = this.props.model;
    }
    render() {
        return (
            <article>
                <OnScrollIn delay={this.props.delay} delayGroup={this.props.projectListId}>
                    <Box
                        href={this.model.getHref()}
                        title={this.model.title/* + " (" + this.model.score + ")"*/}
                        imgTitle={this.model.subtitle}
                        img={this.model.imgThumb}
                        imgPreload={this.model.imgThumbPreload}
                        imgSize="lg"
                    >
                        <p>{this.model.description}</p>
                        <TagList
                            models={this.model.tags}
                            preventPageTransition={this.props.preventPageTransition}
                        />
                    </Box>
                </OnScrollIn>
            </article>
        );
    }
}