import React, { Component } from 'react';
import Link from '../component/Link';
import Img from '../component/Img';
import Collapsible from 'react-collapsible';

export class CollapseBox extends Component {
    render() {
        return (
            <Collapsible
                contentContainerTagName="section"
                className="box"
                openedClassName="box"
                triggerTagName="div"
                triggerClassName="box__colored--solid box__colored--top"
                triggerOpenedClassName="box__colored--solid box__colored--top"
                trigger={(<h3>{this.props.title}<i className="Collapsible__collapse" /></h3>)}
                transitionTime={600}
            >
                <div className="box__content">{this.props.children}</div>
            </Collapsible>
        )
    }
}

export class Box extends Component {
    render() {

        let imgClass = "box__img-container"
        if (this.props.imgSize) imgClass += " box__img-container--" + (this.props.imgSize ? this.props.imgSize : "md");
        if (!this.props.title) imgClass += " box__img-container--top";
        if (!this.props.children) imgClass += " box__img-container--bottom";


        const inner = (
            <>
                {this.props.title &&
                    <div className="box__colored--solid box__colored--top"><h3>{this.props.title}</h3></div>
                }
                {this.props.img &&
                    <div className={imgClass}>
                        { /*<div className="box__img" style={{backgroundImage: `url(${this.props.img})`}}></div>*/ }
                        <Img
                            src={this.props.img}
                            srcPreload={this.props.imgPreload}
                            alt={this.props.imgAlt ? this.props.imgAlt : (this.props.title + ": " + this.props.imgTitle)}
                        />
                        {this.props.imgTitle &&
                            <div className="box__img-title">{this.props.imgTitle}</div>
                        }
                    </div>
                }
                {this.props.children &&
                    <div className={"box__content" + (this.props.noSpace ? " box__content--no-space" : "")}>{this.props.children}</div>
                }
            </>
        )

        let style = {}
        if (this.props.height) {
            style.height = this.props.height;
        }


        return this.props.href
            ? (<Link className={"box box--link"} to={this.props.href} style={style}>{inner}</Link>)
            : (<div className={"box"} style={style}>{inner}</div>);
    }
}