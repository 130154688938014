import React, { Component } from 'react';
import Button from "../component/Button";

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();

        this.refName = React.createRef();
        this.refMail = React.createRef();
        this.refMessage = React.createRef();

        this.refNameTooltip = React.createRef();
        this.refMailTooltip = React.createRef();
        this.refMessageTooltip = React.createRef();

        this.clearInputs = this.clearInputs.bind(this);
    }
    getValues() {
        return {
            name: this.refName.current.value,
            mail: this.refMail.current.value,
            message: this.refMessage.current.value
        }
    }
    validateMail(mail) {
        return mail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }
    validate() {
        const values = this.getValues();

        let valid = true;

        if (values.name.length <= 0) {
            this.showTooltip(this.refNameTooltip, "Please provide a name")
            valid = false;
        } else if (values.name.length < 3) {
            this.showTooltip(this.refNameTooltip, "Name must be at least 3 characters")
            valid = false;
        } else if (values.name.length > 100) {
            this.showTooltip(this.refNameTooltip, "Name can't be longer than 100 characters")
            valid = false;
        } else {
            this.hideTooltip(this.refNameTooltip);
        }

        if (values.mail.length <= 0) {
            this.showTooltip(this.refMailTooltip, "Please provide a mail")
            valid = false;
        } else if (!this.validateMail(values.mail)) {
            this.showTooltip(this.refMailTooltip, "Please provide a valid mail")
            valid = false;
        } else {
            this.hideTooltip(this.refMailTooltip);
        }

        if (values.message.length <= 0) {
            this.showTooltip(this.refMessageTooltip, "Please provide a message")
            valid = false;
        } else if (values.message.length < 10) {
            this.showTooltip(this.refMessageTooltip, "Message must be at least 10 characters")
            valid = false;
        } else if (values.message.length > 5000) {
            this.showTooltip(this.refMessageTooltip, "Message can't be longer than 5000 characters")
            valid = false;
        } else {
            this.hideTooltip(this.refMessageTooltip);
        }


        return valid;
    }
    showTooltip(tooltipRef, message) {
        tooltipRef.current.classList.add("invalid-tooltip--active")
        tooltipRef.current.innerHTML = message
    }
    hideTooltip(tooltipRef) {
        tooltipRef.current.classList.remove("invalid-tooltip--active")
    }
    trySend(resolve, reject) {
        if (!this.validate()) {
            reject();
            this.clearInputs();
            return;
        }

        fetch('https://angeloaracri.de/sendmail.php', {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(this.getValues()),
          })
         .then((res) => res.json())
         .then((data) => {
            console.log(data);
            if (!data.success) {
                console.log(
                    "Mail could not be sent" +
                    (data.message ? ": " + data.message : "")
                );
                reject();
                return;
            }

            this.clearInputs();
            resolve();
         })
         .catch((err) => {
            console.log("Remote could not be requested: ", err.message);
            reject();
         });
    }
    clearInputs() {
        this.ref.current.classList.add("form--success")
        window.setTimeout((() => {
            this.refName.current.value = "";
            this.refMail.current.value = "";
            this.refMessage.current.value = ""    
        }).bind(this), 2000);
    }
    render() {
        return (
            <form ref={this.ref}>
                <div className="space-content--half row">
                    <div className="col-sm-5 col-md-4 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="form-name">name</label>
                            <input type="email" className="form-control" id="form-name" ref={this.refName} />
                            <div className="invalid-tooltip" ref={this.refNameTooltip}></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="form-mail">mail</label>
                            <input type="email" className="form-control" id="form-mail" placeholder="name@example.com" ref={this.refMail} />
                            <div className="invalid-tooltip" ref={this.refMailTooltip}></div>
                        </div>
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-9">
                        <div className="form-group">
                            <label htmlFor="form-name">message</label>
                            <textarea className="form-control" id="form-message" rows="8" ref={this.refMessage} />
                            <div className="invalid-tooltip" ref={this.refMessageTooltip}></div>
                        </div>
                    </div>
                    <div className="form__success center--horizontal center--vertical">
                        <i className="fa fa-circle-check" />
                    </div>
                </div>
                <div className="space-content--half center--horizontal">
                    <Button
                        icon="fa-share"
                        size="lg"
                        textPromiseStart="Sending..."
                        textPromiseResolve="Sent!"
                        textPromiseCatch="Could not be sent!"
                        onClickPromise={this.trySend.bind(this)}
                        width="400px"
                    >
                        Send
                    </Button>
                </div>
            </form>
        )
    }
}

                    