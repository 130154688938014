import {encodeNameToURI} from "./helpers";

const MONTHS = ["INVALID", "January", "February", "March", "April", "May", "June", "Juli", "August", "September", "October", "November", "December"]
export default class Project {
    constructor(title, subtitle, imgThumb, imgThumbPreload, imgHeadspace, videoHeadspace, score, date, description, tagStrings, content, sideButtons=[]) {
        this.title = title;
        this.subtitle = subtitle;
        this.imgThumb = imgThumb;
        this.imgThumbPreload = imgThumbPreload;
        this.imgHeadspace = imgHeadspace
        this.videoHeadspace = videoHeadspace
        this.score = score;
        this.date = date;
        this.description = description;
        this.tagStrings = tagStrings
        this.content = content;
        this.sideButtons = sideButtons
        this.tags = [];
        
        this.hasEscapedTag = this.hasEscapedTag.bind(this);
        this.hasAnyEscapedTag = this.hasAnyEscapedTag.bind(this);
    }
    hasTag(tag) {
        return this.tags.includes(tag);
    }
    hasAnyTag(tags) {
        return tags.some(this.hasTag);
    }

    hasEscapedTag(escapedTag) {
        return this.tags.map(encodeNameToURI).includes(escapedTag);
    }
    hasAnyEscapedTag(escapedTags) {
        const thisEscapedTags = this.tags.map(encodeNameToURI);
        return escapedTags.some((escapedTag) => thisEscapedTags.includes(escapedTag));
    }

    sameTagCount(project) {
        let count = 0;
        for (const tag of this.tags) {
            if (project.hasTag(tag)) {
                count ++;
            }
        }
        return count;
    }

    getID() {
        return encodeNameToURI(this.title)
    }

    getHref() {
        return "/project/" + this.getID();
    }

    getPrettyDate() {
        return `${MONTHS[this.date.getMonth()]} ${this.date.getDate()}, ${this.date.getFullYear()}`
    }
    getDaySuffix(day) {

    }

    getReducedDate() {
        return `${this.date.getFullYear()}-${this.date.getMonth()}-${this.date.getDate()}`
    }
}