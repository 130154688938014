import React, { Component } from 'react';
import { Portal } from 'react-portal';

import Img from "../component/Img";
import OnScrollIn from "../component/OnScrollIn";
import Ribbon from "./Ribbon";

const SCROLL_FACTOR = 1.8;
const SCROLL_OVERSHOOT = 20; // percent

export default class ImgGallery extends Component {
    constructor(props) {
        super(props);
        this.refLightbox = React.createRef();
    }
    render() {
        this.images = this.props.images;
        for (let i = 0; i < this.images.length; i++) {
            this.images[i].id = i;
        }
        return (
            <>
                <Ribbon color="primary">
                    {this.renderRows()}
                </Ribbon>
                <Portal>
                    <Lightbox
                        ref={this.refLightbox}
                        images={this.images}
                    />
                </Portal>
            </>
        );
    }

    renderRows() {
        const cols = this.props.cols ? this.props.cols : 1000;
        const rows = Math.ceil(this.images.length / cols)

        let result = [];
        for (let i = 0; i < rows; i++) {
            result.push(<div className="gallery" key={"row-" + i}>{this.renderImgs(i * cols, cols)}</div>);
        }
        return result
    }

    renderImgs(start, num) {
        let result = [];
        for (let i = start; i < Math.min(start+num, this.images.length); i++) {
            result.push(this.renderImg(i, this.images[i], i-start));
        }
        return result;
    }

    renderImg(i, img, iRow) {
        return (
            <OnScrollIn key={"img-" + i} delay={iRow}>
                <Img
                    src={img.src_thumb ? img.src_thumb : img.src}
                    srcPreload={img.src_thumb_preload}
                    alt={img.alt}
                    title={img.title}
                    height={img.height}
                    onClick={() => this.refLightbox.current.open(i)}
                />
            </OnScrollIn>
        )
    }
}

class Lightbox extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.refSlides = React.createRef();
        this.refCircles = React.createRef();
        this.refTitle = React.createRef();

        this.currentSlide = 0;
        this.numSlides = this.props.images.length;

        this.keyHandler = this.keyHandler.bind(this);
        this.close = this.close.bind(this);
        this.left = this.left.bind(this);
        this.right = this.right.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchStart = this.touchStart.bind(this);
        this.touchEnd = this.touchEnd.bind(this);

        this.state = {open: false, wasOpened: false};
    }
    render() {
        return (
            <div className={"lightbox" + (this.state.open ? " lightbox--active" : "")} ref={this.ref} onClick={this.close}>
                    <div className="lightbox__content">
                        <div className="lightbox__slides" ref={this.refSlides}>
                            {this.state.wasOpened && <>
                                {this.props.images.map((img) => this.renderImg(img))}
                            </> }
                        </div>
                        { (this.props.images.length > 1) && <>
                            <div className="lightbox__control lightbox__control--left" onClick={this.left} />
                            <div className="lightbox__control lightbox__control--right" onClick={this.right} />
                        </> }
                    </div>
                    <div className="lightbox__bar">
                        <div className="lightbox__bar__title" ref={this.refTitle} />
                        <div className="lightbox__bar__controls">

                            <div className="lightbox__bar__controls__center" style={(this.props.images.length <= 1) ? {visibility: "hidden"} : {}}>
                                <div className="lightbox__bar__controls__left" onClick={this.left}>
                                    <i className="fa fa-chevron-left" />
                                </div>
                                <div className="lightbox__circles" ref={this.refCircles}>
                                    {this.props.images.map((img) => this.renderThumb(img))}
                                </div>
                                <div className="lightbox__bar__controls__right" onClick={this.right}>
                                    <i className="fa fa-chevron-right" />
                                </div>
                            </div>

                            <div className="lightbox__bar__controls__close">
                                <div
                                    key={-1}
                                    className="lightbox__circle"
                                    onClick={this.close}
                                >
                                    <i className="fa fa-xmark fa-xl" />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

    renderImg(img) {
        return (
            <div
                key={img.id}
                className="lightbox__slide"
                style={{left: `${img.id}00%`}}
            >
                <Img
                    src={img.src}
                    srcPreload={img.src_thumb}
                    alt={img.alt}
                    title={img.title}
                />
            </div>
        );
    }


    renderThumb(img) {
        return (
            <div
                key={img.id}
                className="lightbox__circle lightbox__circle--thumb"
                onClick={(e) => {this.goto(img.id); e.stopPropagation()}}
            />
        );
    }

    componentWillUnmount() {
        this.dettachHandlers();
    }
    
    attachHandlers() {
        document.addEventListener("keydown", this.keyHandler, false);
        document.body.classList.add("no-scroll");

        document.body.addEventListener("touchstart", this.touchStart);
        document.body.addEventListener("touchmove", this.touchMove);
        document.body.addEventListener("touchend", this.touchEnd);
    }
    dettachHandlers() {
        document.removeEventListener("keydown", this.keyHandler, false);
        document.body.classList.remove("no-scroll");

        document.body.removeEventListener("touchstart", this.touchStart);
        document.body.removeEventListener("touchmove", this.touchMove);
        document.body.removeEventListener("touchend", this.touchEnd);
    }

    touchMove(e)  {
        const curMousePx = e.touches[0].screenX;
        const translatePx = curMousePx - this.startMousePx;
        const translatePercent = 100 * (translatePx / window.innerWidth);
        this.curTranslatePercent = this.startTranslatePercent + SCROLL_FACTOR * translatePercent;

        this.curTranslatePercent = Math.max(this.curTranslatePercent, -100 * (this.numSlides-1) - SCROLL_OVERSHOOT)
        this.curTranslatePercent = Math.min(this.curTranslatePercent, SCROLL_OVERSHOOT)

        this.refSlides.current.style.transform = `translateX(${this.curTranslatePercent}%)`;

        e.preventDefault();
    }

    touchStart(e) {
        this.startTranslatePercent = -100 * this.currentSlide;
        this.curTranslatePercent = this.startTranslatePercent;

        this.startMousePx = e.touches[0].screenX;
    }

    touchEnd(e) {
        const newSlideId = Math.round(this.curTranslatePercent / -100.0);
        this.goto(newSlideId)
    }

    keyHandler(e) {
        switch (e.key) {
            case "Escape":
                this.close();
                break;
            case "a":
            case "A":
            case "ArrowLeft":
                this.left();
                break;
            case "d":
            case "D":
            case "ArrowRight":
                this.right();
                break;
            default:
                break;
        }
    }

    getThumb(id) {
        return this.refCircles.current.childNodes[id];
    }

    open(id) {
        this.setState({open: true, wasOpened: true});
        this.goto(id, true);
        this.attachHandlers();
    }

    close(e) {
        if (e) e.stopPropagation();
        this.setState({open: false});
        this.dettachHandlers();
    }

    left(e) {
        if (e) e.stopPropagation();
        this.goto(Math.max(0, this.currentSlide - 1))
    }

    right(e) {
        if (e) e.stopPropagation();
        this.goto(Math.min(this.numSlides - 1, this.currentSlide + 1))
    }

    goto(id, skipAnim = false) {
        if (this.numSlides > 1) {
            this.getThumb(this.currentSlide).classList.remove("lightbox__circle--thumb--active");
            this.getThumb(id).classList.add("lightbox__circle--thumb--active");
        }
        this.currentSlide = id;

        if (skipAnim) {
            this.refSlides.current.classList.add("lightbox__slides--no-anim")
        }

        this.refSlides.current.style.transform = `translateX(-${id}00%)`;

        this.refTitle.current.innerHTML = this.props.images[id].title;

        if (skipAnim) {
            // eslint-disable-next-line no-unused-vars
            let hacky = this.refSlides.current.offsetHeight; // trigger reflow
            this.refSlides.current.classList.remove("lightbox__slides--no-anim")
        }
    }
}