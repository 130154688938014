import React from "react";

import {ExternalLink} from "../../component/IconLink";
import ImgGallery from "../../component/ImgGallery";
import OnScrollIn from "../../component/OnScrollIn";
import Ribbon from "../../component/Ribbon";
import Video from "../../component/Video";
import {SpecificTag} from "../../views/Tag";
import Img from "../../component/Img";

export default function PageContent() {
    return (<>
        <section>
            <h2>Global Game Jam 2019</h2>
            <p>
                This years <SpecificTag name="gamejam" showCount={true} /> we got a little confused when the topic &lsquo;the feeling of home&rsquo; was announced.
                The <SpecificTag name="godot" showCount={true} /> GameEngine had just gotten a big update for its <code>TileMap</code> and <code>TileSet</code> features, so I was set on experimenting with them no matter the topic.
                I am a long-time fan of jump &rsquo;n&rsquo; run games, so I suggested the genre right from the start.
            </p>
            <p>
                My team this year was comprised of two good friends from uni whom I had been going to previous GameJams and two other coders we met on site.
                One of my friends wanted to try out his new midi keyboard, so he focussed on sound design.
                Since all of us were programmers by trade, I was the member with the most experience in graphics, so I primarily focussed on both the <code>TileSet</code> design as well as the <code>TileMap</code> functionality.
            </p>
        </section>
        <section>
            <h2>The Game</h2>
            <p>
                Our idea to create <i>a feeling of home</i> was to make the protagonist collect and set up furniture for villagers in their home.
                Everyone needs a place where they feel sheltered and comfortable and the goal in this game is making people happy by giving them that.
                The villagers walking about their sad and empty houses don't want just any furniture but have specific needs that they show in speech bubbles.
                As our ideas got a little more crazy, we also added a <i>home god</i> that magically makes furniture appear in the world, which must then be delivered by the player.
                (shout-out to all that recognize the gods visuals).
            </p>
            <ImgGallery cols="2" images={[
                {
                    src : require("../../../media/projects/ggj-2019-homegod/screenshot-god.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/screenshot-god-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/screenshot-god-32.png"),
                    alt : "HomeGod is a game where you give villagers the feeling of home.",
                    title : "God tells you whenever new furniture has magically spawned in the world."
                },{
                    src : require("../../../media/projects/ggj-2019-homegod/screenshot-cave-1.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/screenshot-cave-1-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/screenshot-cave-1-32.png"),
                    alt : "The player needs to explore the world to find furniture for the villagers gomes",
                    title : "Furniture is often hidden and must be found and carried back to correct house."
                },{
                    src : require("../../../media/projects/ggj-2019-homegod/screenshot-cave-2.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/screenshot-cave-2-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/screenshot-cave-2-32.png"),
                    alt : "Furniture is sometimes hidden in deep underground caves that must be explored with a wall-jump",
                    title : "Creating interesting level structures to wall-jump in was a lot of fun."
                },{
                    src : require("../../../media/projects/ggj-2019-homegod/screenshot-bridge.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/screenshot-bridge-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/screenshot-bridge-32.png"),
                    alt : "Sometimes, the best hiding place is in plain sight",
                    title : "I like clever teasing in games like this situation, where you see the result but don't know how to get it."
                }
            ]} />
            <p>
                Early in development, we had the idea to generate the map but we scratched it sometime late during the first night and settled on a pre-build level instead.
                Even a simple grid-based generation using pre-build components would have taken a lot of time and we feared that the generated maps would not make a fun level without a lot of tweaking.
            </p>
            <p>
                While I was probably drawing sprites and fiddling around with Tile collision boxes, the team developed the logic for the villagers and houses.
                The NPC villagers got some logic to walk around and show speech bubbles to feel alive.
                When the player carries a furniture to the correct house, it magically hovers to its perfect place and makes the villager happy, while also unlocking more sections of the map.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../../media/projects/ggj-2019-homegod/screenshot-sofa-cave.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/screenshot-sofa-cave-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/screenshot-sofa-cave-32.png"),
                    alt : "Pieces of furniture are hidden all over the level",
                    title : "«Who put this sofa here?»"
                },{
                    src : require("../../../media/projects/ggj-2019-homegod/screenshot-sofa-house.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/screenshot-sofa-house-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/screenshot-sofa-house-32.png"),
                    alt : "The player in HomeGod has to assist the HomeGod to deliver furniture to villagers",
                    title : "When delivering the piece of furniture to the right villager, he thanks you with his love."
                }
            ]} />
        </section>
        <section>
            <h2>TileMap</h2>
            <p>
                <code>TileMaps</code> are a brilliant concept that allows drawing a map on a regular grid.
                Each location on the grid can show one <code>Tile</code> that is specified in a <code>TileSet</code>.
                GameEngines are often able to optimize <code>TileMaps</code> by caching, batching redraws and utilizing the GPU. <code>TileMaps</code> can also be stacked to create layers with a custom rendering order!
            </p>
            <p>
                Creating a spritesheet for a TileSet is a fun process where you try to create a cohesive style while drawing only in a ridiculously small area.
                I created the graphics with <b>Adobe Photoshop</b> and chose a grid resolution of 64 pixels, which retrospectively, is much too big, especially in a GameJam where there is not much time.
                As you can see below, I was inexperienced with creating TileSets and looking at it later, I think the terrains look too empty and the single sprites are just very bad with no common perspective.
            </p>
            <p>
                Drawing TileSet can get tricky if you want to have terrain Tiles that fit seamlessly, so I decided on a relatively clean look with minimal texture.
                I was happy how the houses Tiles turned out to be quite versatile - especially when using multiple TileMap layers.
                My favorite sprites on this spritesheet are probably the rocks at the very bottom.
                Unfortunately, we never got to creating character art, so bot the villagers and the players are taken from royalty-free spritesheets online.
            </p>
            <Ribbon color="primary">
                <div className="center--horizontal space-content">
                    <Img
                        src={require("../../../media/projects/ggj-2019-homegod/tilesheet.png")}
                        srcPreload={require("../../../media/projects/ggj-2019-homegod/tilesheet-32.png")}
                        alt={"All sprites for the games map can be found in one spritesheet"}
                        title={"The spritesheet includes the terrain Tiles and single sprites used for the furniture and decoration."}
                        height={"1000px"}
                    />
                </div>
            </Ribbon>
            <p>
                To create the level, I used the TileMap map editor software <ExternalLink href="https://www.mapeditor.org/">Tiled</ExternalLink>.
                Tiles is a separate application and offers a GUI to define TileSets and create TileMap levels.
                Both can then be imported into Godot 3 with the <ExternalLink href="https://godotengine.org/asset-library/asset/158">Tiled Map Importer</ExternalLink> plugin.
                <br />
                <b>Note from 2023:</b> Godot 4 now has a decent TileMap editor build-in, so there is really no need to use Tiled anymore.
            </p>
            <p>
                Many useful features can be directly specified in Tiled, including layers, collision shapes and dynamic markers to define a locations and ares that can be used from the games code.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../../media/projects/ggj-2019-homegod/tiled-map.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/tiled-map-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/tiled-map-32.png"),
                    alt : "Tiled is a TileMap level editor software",
                    title : "Complete view of the level in the Tiled level editor software."
                },{
                    src : require("../../../media/projects/ggj-2019-homegod/tiled-collision.png"),
                    src_thumb : require("../../../media/projects/ggj-2019-homegod/tiled-collision-540.png"),
                    src_thumb_preload : require("../../../media/projects/ggj-2019-homegod/tiled-collision-32.png"),
                    alt : "Tile collision shapes can be defined in the TileSet",
                    title : "Notice the collision shapes and the markers used to define furniture spawn points."
                }
            ]} />
            <p>
                Tiled also has a lot of quality-of-life features, like <i>Terrains</i> (other applications also call it <i>AutoTiles</i>).
                With AutoTiles, you can specify a set of Tiles that are then dynamically selected when drawing a region.
                AutoTiles can be a little confusing to set up, but its worth the time.
                In this case, there are only a handful simple AutoTile sets for the basic grass and cave Tiles.
            </p>
            <Ribbon color="primary">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <OnScrollIn delayGroup="homegod-autotiles">
                            <Img
                                src={require("../../../media/projects/ggj-2019-homegod/tiled-terrain.png")}
                                srcPreload={require("../../../media/projects/ggj-2019-homegod/tiled-terrain.png")}
                                alt="The TileMap level editor allows creating terrains in the TileSet that can be freely drawn in the TileMap"
                                title="The red regions define the relationship between the Tiles."
                            />
                        </OnScrollIn>
                    </div>
                    <div className="clearfix--xs space-content" />
                    <div className="col-xs-12 col-sm-6">
                        <Video
                            src={require("../../../media/projects/ggj-2019-homegod/video-autotiles.webm")}
                            srcFallback={require("../../../media/projects/ggj-2019-homegod/video-autotiles-firstframe.png")}
                            scrollIn={true}
                            scrollInDelayGroup="homegod-autotiles"
                        />
                    </div>
                </div>
            </Ribbon>
        </section>
         <section>
            <h2>Retrospective</h2>
            <p>
                During play-testing and especially after ditching some features that couldn't make it into the game because of the 48 time constraint, the gameplay is a little tedious to be honest :)
                The fact that the NPCs walk around and talk using speech bubbles and especially the sound that god makes when he talks add to the games charm.
                After playing for a couple minutes however, you start to notice how the gameplay loop is basically a chain of fetch-quests, the most infamous kind of quest (except escort missions maybe):
                find something, deliver something, find something, deliver something...
            </p>
            <p>
                So all in all, this GameJams result is not my favorite, but I still learned a lot concerning TileMaps, AutoTiles and level design, which helped me a lot in future projects.
                I also remember collaborating with the team to be a demanding but exciting experience - everyone wanted to get their personal ideas into the game while coordinating development of the games various components.
            </p>
        </section>
    </>);
}
