import React, { Component } from 'react';
import Link from '../component/Link';

export default class IconLink extends Component {
    render() {
        const propRel = this.props.external ? "noreferrer" : ""
        const propTarget = this.props.external ? "_blank" : ""

        const words = this.props.children.split(" ");
        const wordFirst = words.shift();

        return (
            <Link
                className="a--icon"
                to={this.props.href || null}
                onClick={this.props.onClick || null}
                rel={propRel}
                target={propTarget}
            >
                {(this.props.icon)
                    ? (<>
                        <span className="text--no-break">
                            <i className={`fa fa-${this.props.icon}`}></i> {wordFirst}
                        </span> {words.join(" ")}
                    </>)
                    : (<>
                        {this.props.children}
                    </>)
                }
            </Link>
        );
    }
}

export class ExternalLink extends Component {
    render() {
        let props = Object.assign({}, this.props);
        
        props.external = true;

        props.icon = "up-right-from-square";
        if (this.props.type === "wiki") {
            props.icon = "wikipedia-w fa-brands"
        }
        if (this.props.type === "github") {
            props.icon = "github fa-brands"
        }

        return (
            <IconLink {...props} />
        );
    };
}