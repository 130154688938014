import {encodeNameToURI} from "./helpers";
import {randomGenerator} from "../Utils";


export default class Tag {
    static TAG_COLOR_HUE = {
        "Uni" : 180
    }
    static TAG_CATEGORIES = {
        "Categories" : [
            "Uni", "Games", "Job", "Design", "Web", "App", "Marketing", "Print", "BioMed Technology", "GameJam"
        ],
        "Languages" : [
            "HTML", "JavaScript", "SCSS", "CSS", "C++", "C#", "C", "MatLab", "LaTeX", "Python", "openSCAD", "Java", "PHP", "SQL"
        ],
        "Libraries" : [
            "jQuery", "Babylon.js", "Backbone.js", "Three.js", "React.js", "libGDX"
        ],
        "Technologies" : [
            "Shaders", "OpenGL", "WebGL", "CAD", "Bluetooth", "Microcontroller", "CNC", "EyeTracker", "Continuous Integration", "3D Printing"
        ],
        "IDEs & Tools" : [
            "Unity", "Godot", "PhoneGap", "SimuLink", "Eclipse", "Android Studio"
        ],
        "Others" : []
    }

    constructor(title, count) {
        this.title = title;
        this.count = count;

        this.hue = this.getHue();
        this.category = this.getCategory();
    }

    getID() {
        return encodeNameToURI(this.title);
    }

    getHref() {
        return "/projects/" + this.getID();
    }

    getHue() {
        return (this.title in Tag.TAG_COLOR_HUE)
            ? Tag.TAG_COLOR_HUE[this.title]
            : Math.round(randomGenerator(this.title)()*360);
    }
    getColor() {
        return `hsl(${this.hue}, 70%, 25%)`;
    }
    getHoverColor() {
        return `hsl(${this.hue}, 80%, 32%)`;
    }

    getCategory() {
        for (const [category, tagList] of Object.entries(Tag.TAG_CATEGORIES)) {
            if (tagList.includes(this.title)) {
                return category;
            }
        }
        return "Others"
    }
}