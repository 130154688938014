import React, { Component } from 'react';

export default class Ribbon extends Component {
    render() {
        const classNameExtra = this.props.color ? " ribbon--" + this.props.color : ""
        return (
            <>
                <div className={"ribbon" + classNameExtra}>
                    {this.props.children}
                </div>
            </>
        );
    }
}