import React from "react";

import Page from './Page.js';

import projects from "../data/getProjects";

import Code from "../component/Code";

const BASE_URL = "https://angeloaracri.de";

export default class PageSitemap extends Page {
    constructor(props) {
        super(props);
        this.setTitle("Sitemap");
    }

    renderContent() {
        return (
            <>
                <Code language="xml" languageIndicator="XML" content={this.getSitemapCode()} />
            </>
        )
    }

    getSitemapCode() {
        let locations = [];

        locations.push({
            loc: "",
            //lastmod : "2023-08-07"
        });
        locations.push({
            loc: "/about",
        });
        for (const project of projects.getAll()) {
            locations.push({
                loc: project.getHref()
            });
        }
        locations.push({
            loc: "/projects",
        });
        for (const tag of projects.getAllTags()) {
            locations.push({
                loc: tag.getHref()
            });
        }
        locations.push({
            loc: "/contact",
        });
        locations.push({
            loc: "/legal-notice",
        });
        locations.push({
            loc: "/privacy",
        });

        const result = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
${locations.map((location) => {
    return `  <url>
    <loc>${BASE_URL + location.loc}</loc>${""/*location.lasmod ? `\n    <lastmod>${location.lastmod}</lastmod>` : `\n    <lastmod>2023-08-07</lastmod>`*/}
  </url>`})
    .join('\n')}
</urlset>`;
        console.log(result);
        return result;

    }
}