import React, {Component} from "react";

import projects from "../data/getProjects";

import Page from './Page.js';
import {ExternalLink} from "../component/IconLink";
import {Box} from "../component/Box";
import CursorEffectImg from "../component/CursorEffectImg";
import Link from "../component/Link";
import Button from "../component/Button";
import TagList from "../views/TagList";
import {SpecificTag} from "../views/Tag";
import Collapsible from 'react-collapsible';


class ReadMore extends Component {
    render() {
        return (<>
            <Collapsible
                contentContainerTagName="section"
                className="read-more"
                openedClassName="read-more"
                triggerTagName="div"
                triggerClassName="anim--fade-in"
                triggerOpenedClassName="anim--fade-out"
                trigger={(
                    <p className="center--horizontal">
                        <Button
                            icon="fa-angles-down"
                            size="lg"
                            onClick={null}
                            preventPageTransition={true}
                        >
                        Read more
                        </Button>
                    </p>)}
                transitionTime={600}
            >
                {this.props.children}
            </Collapsible>
        </>);
    }
}

export default class PageAbout extends Page {

    constructor(props) {
        super(props);
        this.setTitle("About me");
    }

    renderContent() {
        return (
            <div className="row">
                <div className="col-sm-5">
                    <Box noSpace={true}>
                        <CursorEffectImg
                            src={require("../../media/static/portrait-bw-1080.jpg")}
                            srcPreload={require("../../media/static/portrait-bw-32.jpg")}
                            srcEffect={require("../../media/static/portrait-1080.jpg")}
                            alt="Portrait Angelo Aracri"
                            height="450px"
                        />
                    </Box>
                    <Box
                        title="Education"
                    >
                        <ul className="ul--text">
                            <li>
                                <b>2013</b> german abitur<br />
                                <ExternalLink href="https://schubart-gymnasium.eu/">Schubart Gymnasium Ulm</ExternalLink>
                            </li>
                            <li>
                                <b>2019</b> bachelor of computer science<br />
                                <ExternalLink href="https://kit.edu/">Karlsruher Institut für Technologie</ExternalLink>
                            </li>
                        </ul>
                    </Box>
                    <Box
                        title="Languages"
                    >
                        <ul className="ul--text">
                            <li>C++</li>
                            <li>C#</li>
                            <li>Java</li>
                            <li>Python and numpy</li>
                            <li>HTML</li>
                            <li>CSS and SCSS</li>
                            <li>JavaScript and TypeScript</li>
                            <li>PHP</li>
                            <li>SQL</li>
                            <li>MatLab and SimuLink</li>
                            <li>Haskell</li>
                            <li>LaTeX</li>
                            <li>Godot Script</li>
                            <li>and Scratch ;)</li>
                        </ul>
                    </Box>
                    {/*<Box
                        title="Technologies"
                    >
                        <ul className="ul--text">
                            <li>npm</li>
                            <li>Apache Maven</li>
                            <li>React</li>
                            <li>Node.js</li>
                            <li>jQuery</li>
                            <li>Three.js</li>
                        </ul>
                    </Box>*/}
                </div>
                <article className="col-sm-7">
                    <Box title="Facts about me">
                        <div className="space-content">
                            <ul className="ul--text">
                                <li>I was born in <b>1995</b> in Stuttgart</li>
                                <li>I have lived in 3 cites in my life, all in <b>Baden-Württemberg</b></li>
                                <li>My interests in school were the <b>natural sciences and mathematics</b></li>
                                <li>I taught myself multiple <b>programming languages</b> from books</li>
                                <li>I played <b>handball</b> and <b>volleyball</b> in my youth but never learnt an instrument</li>
                                <li>After school I <b>worked and traveled</b> for 6 months and did an <b>internship</b> for 4</li>
                                <li>I started studying <b>computer science</b> in Karlsruhe in 2014</li>
                                <li>In my bachelor I majored in <b>software engineering</b> and minored in <b>biomedical technology</b></li>
                                <li>In my master I majored in <b>robotics</b> and <b>computer graphics</b> and minored in <b>biology</b></li>
                            </ul>
                        </div>
                    </Box>

                    <Box title={(<><i className="h--i fa fa-code" />Software Engineering</>)}>
                        
                        <div className="space-content">
                            <p>
                                I consider myself lucky for finding my passion for programming at the young age of 12.
                                Books taught me enough of the basics to experiment and play with multiple programming languages and by the age of 15 I had <Link to="/project/trainercard">my own browser game</Link> with 5000 registered users.
                                Over the years, I pursued many private projects, created websites for small companies as a freelancer and did an internship at a <ExternalLink href="https://zeroseven.de">digital design studio</ExternalLink>.
                                Even before beginning my studies of computer science at the <ExternalLink href="https://kit.edu/">Karlsruher Institut für Technologie</ExternalLink>, I had become a backend and frontend developer with a decent amount of experience.
                                University has given me all the theoretical background and a holistic view on computer science turned me from a passionate coder to a software engineer.
                            </p>
                        </div>

                        <div className="space-content">
                            <h4>I program to&hellip;</h4>
                            <ul className="ul--text">
                                <li>&hellip;find that one clever solution to a tricky puzzle</li>
                                <li>&hellip;envision an entire system and then actually build it</li>
                                <li>&hellip;create something that never existed before</li>
                                <li>&hellip;perfect that one detail</li>
                            </ul>
                        </div>

                        <div className="space-content">
                            <h4>My languages</h4>
                            <TagList models={projects.getAllTags().filter((tag) => tag.category === "Languages")} showCount={true} />
                        </div>

                        <ReadMore>
                            <div className="space-content">
                                <h4>How I got to programming</h4>

                                <p>
                                    As a child I would use that wonderful spray can tool in <i>MS Paint</i> and later in school we were introduced to the usage of <i>MS Office</i>.
                                    While I recognized the ability to create semi-automatic calculations in <i>Excel</i>, I was much more interested in what <i>PowerPoint</i> offered.
                                    Obviously, the animation tools were a lot of fun as a kid, but a simple feature was significantly more profound to me:
                                    Creating hyperlinks on objects that would redirect to a certain slide in the presentation.
                                </p>
                                <p>
                                    To me, that was the difference between <b>boring, linear presentations</b> and <b>interactive roller coasters</b>.
                                    I faintly remember a period of time where I would create interactive quizzes, stories with choices and games that suffered brutally on the visual side purely in PowerPoint.
                                </p>
                                <p>
                                    Blindly following my vague interest in computers, I ended up in the IT section of the cities library and found a &lsquo;Programming for Kids&rsquo; book series.
                                    That way I learned the basics of multiple programming and coding languages chapter by chapter from books filled to the brim with playful examples.
                                    &lsquo;C++ for Kids&rsquo; was the one that sounded the most interesting to me at first, so <SpecificTag name="c%2B%2B" showCount={true} /> and later <SpecificTag name="python" showCount={true} /> are my first languages, I guess.
                                </p>
                                <p>
                                    I soon ran into a problem with command line tools and desktop applications.
                                    Showing my results and silly gimmicks to family and friends was fun for a while, but I wanted my stuff out there <i>in the world</i> and the internet was conveniently on the rise.
                                    That got me into the frontend languages <SpecificTag name="html" showCount={true} />, <SpecificTag name="css" showCount={true} /> and <SpecificTag name="javascript" showCount={true} />. 
                                    Of course, I created the obligatory &lsquo;My personal homepage&rsquo; in bright colors and yes, I used the infamous <code>&lt;blink&gt;</code> tag (shout-out to those who know).
                                </p>
                                <p>
                                    Over the years I added the backend technologies <SpecificTag name="php" showCount={true} /> and <SpecificTag name="sql" overwriteTitle="mySQL" showCount={true} /> to my repertoire.
                                    Some results were my <b>personal chat platforms</b> for me and my friends, a site where a couple friends and me would <b>rate and review video games</b> and even a <Link to="/project/trainercard">browser game</Link>!
                                    Having an active fan base on my browser game was quite the motivation, so I updated it regularly and maintained it for many years.
                                </p>
                            </div>
                            <div className="space-content">
                                <h4>Software engineering at uni</h4>
                                <p>
                                    Though I remember being disappointed that studying computer science included significantly less programming that I had expected at first, <SpecificTag name="uni" overwriteTitle="university" showCount={true} /> slowly lead me to rediscover my love for high-level languages like <SpecificTag name="java" showCount={true} />, <SpecificTag name="c%2B%2B" showCount={true} /> and C#.
                                    This time not in self-taught manner, but a much more holistic and scientific way.
                                    During my studies, I would frequently hear other students complain about having to cram what seemed like useless or antiquated information.
                                    From the electrical and physical fundamentals of semiconductors, along logic gates and the architecture that make up our CPUs, computers and infrastructure, through the operating systems we use, to the finest details of how different programming languages are compiled or interpreted, to how executables are structured and run and finally how to puzzle pieces of software together.
                                    It might have seemed that way at the time, but having all this in the back of your mind puts you in a place where you can - even before writing a single line of code - look closely at a task from many angles, envision a solution, identify potential problems and sketch a complete system design that would be required to solve it.
                                </p>
                                <p>
                                    During my time at uni, I took on a <b>tutoring job</b> to teach younger students in software engineering (Softwaretechnik) for three semesters, explaining how to determine software requirements, structure a project development process and how to engineer systems by making conscious and calculated architecture decisions and following proven software principles in your code design.
                                    My favorite topic were <b>design patterns</b>, pieces of abstract architecture and coding design for commonly re-appearing problems.
                                    Ever since learning of <i>observers</i>, <i>iterators</i>, <i>factories</i>, I have been seeing these patterns all over other peoples and even my own code and started using them actively.
                                    I actually organized a <Link to="/project/an-educational-game-rekit">design pattern workshop</Link> where students could use design patterns in the playful context of a game.
                                </p>
                                <p>
                                    Apart from the tutoring job, I have also worked on two projects as research assistant at the <ExternalLink href="https://www.ibt.kit.edu/">IBT</ExternalLink>, the Institute for Biomedical Technology.
                                    Being a computer scientist at an electrical engineering institute with focus on the biomedical field gave me insights I never could have gotten at the computer science faculty.
                                    I had already written my <Link to="/project/bachelor-thesis">bachelor thesis</Link> at the IBT, so I knew the researchers and PhD students and had apparently left impression.
                                    The <Link to="/project/hiwi-job-cardiac-ablation-strategies">first project</Link> was research on atrial fibrillations and how to terminate them by ablation techniques using electrophysiology simulation.
                                    The second time, I improved the very same simulator called <ExternalLink href="https://opencarp.org/">openCARP</ExternalLink> by integrating a linear algebra library for manycore systems called <ExternalLink href="https://github.com/ginkgo-project/ginkgo" type="github">Ginkgo</ExternalLink>.
                                </p>
                            </div>
                            <div className="space-content">
                                <h4>Hacking as a side dish</h4>
                                <p>
                                    If I think about it, I have always been coding <i>something</i> ever since I first got to it.
                                    It has become an important hobby to me and no matter my age or stage in life, I have always had some side project I was working on privately, freelancing or for a job.
                                    This website is actually somewhat of a testimony to that, where you can find many of my past coding adventures, websites and games!
                                </p>
                            </div>
                        </ReadMore>
                    </Box>
                
                    <Box title={(<><i className="h--i fa fa-pen-nib" />Interface Design</>)}>
                        <div className="space-content">
                            <p>
                                Developing websites, games and apps, interface design was never far away.
                                I experienced the transition first-hand from stale, static <code>&lt;table&gt;</code>-layouted PHP websites with little to no JavaScript to the highly-interactive world of web apps that smoothly adapts to your device.
                                Both the progression of web design and my increasing grasp over it can be seen comparing <Link to="/project/trainercard">my first big project (2009)</Link> and <Link to="/project/this-website">this website (2023)</Link>.
                            </p>
                        </div>
                        <div className="space-content">
                            <h4>Coding projects with interface design</h4>
                            <TagList models={projects.getTags(["web", "app", "games"])} showCount={true} />
                            <h4>Projects that mainly focus on design</h4>
                            <TagList models={projects.getTags(["design", "print"])} showCount={true} />
                        </div>

                        <ReadMore>
                            <div className="space-content">
                                <h4>Interface Design</h4>
                                <p>
                                    I have always found a weird joy in discovering subtle details that interface designers put int their work and to this day I can't suppress a smile whenever I see a unique image hover effect, a clever arrangement of elements or just a fun animation I haven't seen yet.
                                    Of course, interface design is not about gimmicky animations, but rather about speaking a language that is consistent, easy to understand, aesthetically pleasing and (best case) fun to use.
                                    General design principles like <i>contrast, repetition, alignment and proximity</i> as well as abiding common interface conventions are essential to speak that language.
                                </p>
                                <p>
                                    What I like about interface design is the need to completely overhaul its principles when the means of interaction change.
                                    Just think how touch screens linked the input and the visual content together, thereby eliminating the need of an abstraction like arrow keys.
                                    And who knows what the next big is gonna be?
                                    Are digital assistants and text-to-speech ever going to feel natural enough?
                                    Are we going to look at the world through augmented reality overlays?
                                    Or will future phones just unfold to bigger and bigger screen sizes?
                                    I love envisioning these future paradigms and how they would change what we know of interface design now.
                                    I tried some interesting means of interaction when using an <Link to="/project/ggj-2017-rem">eye tracker for a game</Link> or when I was experimenting with a <Link to="/project/wearable-technology-and-sci-fi-clothes">vibrating beanie</Link>.
                                    Sometimes I dream of how a virtual reality interface for productive and professional application would look like.
                                    I can vividly imagine how you could link MS Office features with mail, online search and communication to create a virtual representation of an actual desktop in the original sense (which would be quite ironic).
                                </p>
                            </div>
                            <div className="space-content">
                                <h4>User experience</h4>
                                <p>
                                    When designing an interface, you also create the user experience.
                                    It can make the difference between a frustrating two minutes and a new user of your application.
                                    Often times when apps compete in an App Store it is not the amount of features that decides the winner, but in what way the features are conveyed.
                                </p>
                                <p>
                                    Seeing other people click through my websites, use my tools and <i>especially</i> playing my games, has taught me that interfaces are often not at all as intuitive as they seem.
                                    Carefully mapping out your target users goals and expectations before getting to the drawing board can help layouting content elements, but every visual element, every button click effect and every small loading indication can contribute to a better experience.
                                    Even with vast knowledge and lots of experience, there is no way around proper usability studies in some cases.
                                    My goal when designing a complex interface is to find clever ways to teach its functionality to a user without having to explain it.
                                </p>
                            </div>
                            <div className="space-content">
                                <h4>Design & Art</h4>
                                <p>
                                    I never considered myself artistically gifted as a kid, but time and time again I was confronted with the need to create art one way or another.
                                    Be it logos, any graphical website elements, photography or photo editing, interface design, or game art - design continuously snuck up on me while programming.
                                    Over the years, I learned the usage of Gimp, Inkscape, Photoshop and more and deeply integrated them into my workflow.
                                    I learned that being able to create beautiful art is not a matter of talent, but simply a craft that must be practiced and honed like so many other things.
                                </p>
                                <p>
                                    In recent years, I even found myself working on design-only projects that didn't even have to do with interfaces or programming.
                                    Creating a flashy poster, drawing silly memes and info graphics and even free-hand paintings are a lot more fun when you know your tools and you have a fine-tuned sense for aesthetics.
                                    During my time living in the HaDiKo student dormitory, I became the <i>design guy</i> by creating many posters, printouts, price lists, flyers, the dormitories map, and even a <Link to="/project/hadiko-newcomer-handbook">handbook</Link>.
                                    The culmination of my general design skills can perhaps be seen when looking at my involvement at the <Link to="/project/hadiko-heimfest-2023">HaDiKo Heimfest</Link> as the person in charge of marketing, design and sponsoring.
                                </p>
                                <p>
                                    I now see design and art as an integral part of my life and skill set.
                                </p>
                            </div>
                        </ReadMore>
                    </Box>
                    
                    <Box title={(<><i className="h--i fa fa-gamepad" />Game Design</>)}>
                        <div className="space-content">
                            <p>
                                When I was younger, I remember thinking of game design as the highest art of programming.
                                It has complex systems and algorithms, everything has to be efficient and timed, there are even real-life physics involve, projects can become enormous, there are a million potential bugs everywhere, usability is arguably more important than even nowadays apps and every gameplay feature has to be fine-tuned extensively.
                                <br />
                                Ah yeah I forgot, game design also has visual art, music and sound design, story telling and interface design.
                            </p>
                        </div>
                        <div className="space-content">
                            <h4>Games</h4>
                            <TagList models={projects.getTags(["games", "gamejam", "play-now"])} showCount={true} />
                            <h4>GameEngines & Frameworks</h4>
                            <TagList models={projects.getTags(["godot", "unity", "opengl", "webgl"])} showCount={true} />
                        </div>
                        <ReadMore>
                            <div className="space-content">
                                <h4>What I like in games</h4>
                                <p>
                                    I cannot deny that my love for video games has contributed strongly to my interest in game design and honestly, computer science as a whole.
                                    There is just something magical to the way a video game can create another world with its own rules, possibilities and feelings.
                                </p>
                                <p>
                                    My favorite games are those, that take you on a stand-alone journey in a well-designed campaign.
                                    Those that tell a story, have adventure waiting for you, make you use your head in clever puzzles and those that have unique gameplay features.
                                    If you are interested in a couple names: Zelda, Portal, the Witcher, Metroid and Amnesia were some of my favorites. Ah well, and Minecraft.
                                    I have always especially liked Nintendo games, not just because of their cheerfulness but because I preferred their approach of gameplay first.
                                    They were never afraid to go all in with their unique game mechanics and sometimes wacky gimmicks.
                                </p>
                            </div>
                            <div className="space-content">
                                <h4>Early game development</h4>
                                <p>
                                    Being a teenager that had discovered his love for programming partially through gaming, it came to no surprise that I wanted to create games on my own.
                                    One of my oldest achievements was the browser game <Link to="/project/trainercard">Trainercard</Link> and before starting my studies I had a collection of many small games.
                                    The technologies I used ranged from <SpecificTag name="c%2B%2B" showCount={true} /> and <SpecificTag name="python" showCount={true} /> to <SpecificTag name="javascript" showCount={true} /> 3D rendering using <code>&lt;canvas&gt;</code>.
                                    Eventually I found game engines like <SpecificTag name="unity" showCount={true} /> and <SpecificTag name="godot" showCount={true} /> and learnt to love the latter.
                                </p>
                            </div>
                            <div className="space-content">
                                <h4>GameJams</h4>
                                <p>
                                    During my bachelor, a good friend of mine found out about the <ExternalLink href="https://globalgamejam.org">Global Game Jam</ExternalLink> that simultaneously takes place all over the world once a year in various locations organized by dedicated game enthusiasts.
                                    Forming a team with other like-minded people and obsessing over the development of a game with a time limit of 48 hours sounded exciting, so we went.
                                    The unpaid organizers of the art school made the weekend an incredibly fun event and a lot of different people with different skill sets came together to envision crazy ideas and make them reality.
                                </p>
                                <p>
                                    That participation in the Global Game Jam was only the first of five (currently) and I also took part in online jams like <ExternalLink href="https://ldjam.com">Ludum Dare</ExternalLink> with smaller groups and friends.
                                    GameJams are an excellent way to try out new technologies or to pursue a crazy game idea you had for a while - if it works and is fun, great. If it is not, no harm done, it was only a weekend.
                                </p>
                            </div>
                            <div className="space-content">
                                <h4>Computergraphics at uni</h4>
                                <p>
                                    Taking almost all advanced computer graphics lessons the <ExternalLink href="https://kit.edu/">KIT</ExternalLink> offered, I learned of rendering techniques for both interactive <i>(=realtime)</i> and photo realistic <i>(=pre-rendered)</i> appliances and got comfortable using <SpecificTag name="opengl" showCount={true} /> and <SpecificTag name="shaders" showCount={true} />.
                                    While interactive rendering tries to find new data structures, clever techniques, simplifications and models to improve the realism while keeping a stable FPS,
                                    photo realistic rendering can get eerily close to simulating photons moving in space.
                                </p>
                                <p>
                                    I was pleasantly surprised to find the linear algebra I learned as an undergraduate, to be incredibly useful when implementing anything from camera perspective and transformation to ray tracing.
                                    In one lecture, we were given the task to <Link to="/project/intergalactic-billiard">develop a game</Link> making use of advanced rendering techniques and together with a fellow student and friend, I developed simple a 3D game engine from scratch using OpenGL.
                                    Using the massive parallelity of our GPUs in creative ways (and not only for rendering purposes!) is a challenging task but often incredibly rewarding.
                                    Shaders got especially interesting when I actually <i>understood</i> what model/view/projection matrices do and how linear algebra could be used to create realistic lighting and shadows.
                                    Since then, shaders have found their way into my games both in 2D effects like dynamic outlines, distortions, screen effects or fog and in 3D effects to shade objects and create shadows but Shaders can actually be <Link to="/project/hadiko-heimfest-2023">useful in design</Link> as well!
                                </p>
                            </div>
                        </ReadMore>
                    </Box>
                </article>
            </div>
        )
    }
}
