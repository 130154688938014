import React, { Component } from 'react';
import {Nav, NavLink} from "./Nav";
import { Outlet, Link } from "react-router-dom";
import IconLink from "../component/IconLink";
import {address} from "../data/helpers";

export default class Document extends Component {
    constructor(props) {
        super(props);
        this.refNav = React.createRef();
    }
    render() {
        return (
        <div className="wrapper">
            <Nav ref={this.refNav}>
                {/*<NavLinkLogo text="Home" nav={this.refNav} />*/}
                <NavLink text="Home" href="/" nav={this.refNav} />
                <NavLink text="About" href="/about" nav={this.refNav} />
                <NavLink text="Projects" href="/projects" nav={this.refNav} />
                <NavLink text="Contact" href="/contact" nav={this.refNav} />
            </Nav>

            <Outlet />

            <footer>
                <div className="container">

                    <ul className="footer__left">
                        <li className="li--fa li--fa--linkedin">
                            <Link to={address.linkLinkedIn} target="_blank" rel="noreferrer">
                                <i className="li--fa__bg"></i>
                                <i className="li--fa__icon"></i>
                            </Link>
                        </li>
                        <li className="li--fa li--fa--github">
                            <Link to={address.linkGitHub} target="_blank" rel="noreferrer">
                                <i className="li--fa__bg"></i>
                                <i className="li--fa__icon"></i>
                            </Link>
                        </li>
                        <li><IconLink icon="envelope" href={"mailto:" + address.mail}>{address.mail}</IconLink></li>
                        <li><IconLink icon="phone" href={"tel:" + address.phone}>{address.phone}</IconLink></li>
                    </ul>

                    <ul className="footer__right" role="contentinfo">
                        <li><Link to="/legal-notice">Legal Notice</Link></li>
                        <li><Link to="/privacy">Privacy</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>

                </div>
            </footer>
        </div>
        );
    }
}