import React, { Component } from 'react';
import Link from '../component/Link';

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.size = this.props.size ? this.props.size : "md";
        this.color = this.props.color ? this.props.color : "primary";

        this.ref = React.createRef();
        this.refIcon = React.createRef();
        this.refContent = React.createRef();

        this.disable = this.disable.bind(this);
        this.state = {disabled : false}
    }
    disable(disable=true) {
        if (this.ref.current) {
            this.setState({disabled : disable})
        }
    }
    onPromiseStart() {
        const elIcon = this.refIcon.current;
        elIcon.classList.remove(...elIcon.classList);

        elIcon.classList.add("fa");
        elIcon.classList.add("fa-spinner");
        elIcon.classList.add(this.props.iconPromiseStart || "fa-spin");

        this.refContent.current.innerHTML = this.props.textPromiseStart || "Loading..."

        this.disable();
    }
    onPromiseResolve() {
        const elIcon = this.refIcon.current;
        elIcon.classList.remove(...elIcon.classList);

        elIcon.classList.add("fa");
        elIcon.classList.add(this.props.iconPromiseResolve || "fa-check");

        this.refContent.current.innerHTML = this.props.textPromiseResolve || "Done"

        if (this.props.resetAfterResolve) {
            window.setTimeout(this.onPromiseReset.bind(this), 3000);
        }
    }
    onPromiseCatch() {
        const elIcon = this.refIcon.current;
        elIcon.classList.remove(...elIcon.classList);

        elIcon.classList.add("fa");
        elIcon.classList.add(this.props.iconPromiseCatch || "fa-xmark");

        this.refContent.current.innerHTML = this.props.textPromiseCatch || "Error"
        
        window.setTimeout(this.onPromiseReset.bind(this), 3000);
    }
    onPromiseReset() {
        const elIcon = this.refIcon.current;
        elIcon.classList.remove(...elIcon.classList);

        elIcon.classList.add("fa");
        elIcon.classList.add(this.props.icon);

        this.refContent.current.innerHTML = this.lastChildren;

        this.disable(false)
    }
    render() {
        let className = "bttn";
        if (this.props.size) {
            className += " bttn--" + this.props.size;
        }
        if (this.props.color) {
            className += " bttn--" + this.props.color;
        }
        if (this.props.block) {
            className += " bttn--block";
        }
        if (this.state.disabled) {
            className += " bttn--disabled";
        }

        let onClick = this.props.onClick || null;

        this.lastChildren = this.props.children;

        if (this.props.onClickPromise) {
            const onClickPromise = this.props.onClickPromise.bind(this);
            onClick = (e) => {
                if (e) e.stopPropagation();
                if (e) e.preventDefault();
                this.onPromiseStart();
                new Promise((resolve, reject) => onClickPromise(resolve, reject))
                    .then(this.onPromiseResolve.bind(this))
                    .catch(this.onPromiseCatch.bind(this));

            }
        }

        const propRel = this.props.external ? "noreferrer" : ""
        const propTarget = this.props.external ? "_blank" : ""

        const inner = (<>
            {this.props.icon &&
            <div className="bttn__i">
                <i ref={this.refIcon} className={`fa ${this.props.icon}`}></i>
            </div>
            }
            {this.props.children &&
            <div className="bttn__content" ref={this.refContent}>
                {this.props.children}
            </div>
            }
        </>)

        if (this.state.disabled) {
            return (
            <div
                className={className}
                style={this.props.width ? {width: this.props.width} : {}}
                ref={this.ref}
            >
                {inner}
            </div>
        );
        } else {
            return (
            <Link
                className={className}
                to={this.props.href || null}
                onClick={onClick}
                style={this.props.width ? {width: this.props.width} : {}}
                rel={propRel}
                target={propTarget}
                ref={this.ref}
                preventPageTransition={this.props.preventPageTransition}
            >
                {inner}
            </Link>
        );
        }
        
    }
}