import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import Document from './scripts/layout/Document';
import PageHome from './scripts/pages/PageHome';
import PageProjects from './scripts/pages/PageProjects';
import PageContact from './scripts/pages/PageContact';
import PageProject from "./scripts/pages/PageProject";
import PageLegalNotice from "./scripts/pages/PageLegalNotice";
import Page404 from "./scripts/pages/Page404";
import PageSitemap from "./scripts/pages/PageSitemap";
import PagePrivacy from "./scripts/pages/PagePrivacy";
import PageAbout from "./scripts/pages/PageAbout";

import projects from "./scripts/data/getProjects";

// import reportWebVitals from './reportWebVitals';

import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    useLocation,
} from "react-router-dom";

function getUrlParam() {
    const url = window.location.pathname.split('/');
    return (url.length >= 3) ? url[2] : null
}

let PageProjectsSwitch = () => {
    let location = useLocation();
    React.useEffect(() => {}, [location]);

    const tagUrl = getUrlParam();
    
    if (tagUrl === null) {
        return <PageProjects key={"PageProjects"} />;
    } else {
        const tag = projects.getTag(tagUrl);
        if (tag) {
            return <PageProjects key={"PageProjects-" + tag.title} filterTags={[tag]} />;
        }
    }

    return <Page404 />;
}

let PageProjectSwitch = () => {
    let location = useLocation();
    React.useEffect(() => {}, [location]);

    const model = projects.getProject(getUrlParam());
    if (model) {
        return <PageProject model={model} key={"PageProject-" + model.title} />;
    } else {
        return <Page404 />;
    }
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Document />}>
            <Route path="" element={<PageHome />} />
            <Route path="projects/:tag?" element={<PageProjectsSwitch />} />
            <Route path="project/:project" element={<PageProjectSwitch />} />
            <Route path="about" element={<PageAbout />} />
            <Route path="contact" element={<PageContact />} />
            <Route path="legal-notice" element={<PageLegalNotice />} />
            <Route path="privacy" element={<PagePrivacy />} />
            <Route path="sitemap" element={<PageSitemap />} />
            <Route path="*" element={<Page404 />} />
        </Route>
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
