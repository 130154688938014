import React from "react";

import {ExternalLink} from "../../component/IconLink";
import ImgGallery from "../../component/ImgGallery";

export default function PageContent() {
    return (<>
        <section>
            <h2>A unique Halloween costume</h2>
            <p>
                In October 2021, I wanted an extravagant Halloween costume, use my dormitories 3D printer and also fulfill a life-long dream.
                The masks design originates from an old game of the <i>The Legend of Zelda</i> franchise called <ExternalLink href="https://en.wikipedia.org/wiki/The_Legend_of_Zelda:_Majora%27s_Mask" type="wiki">Majoras Mask</ExternalLink>.
                The world and its setting is rather creepy and unsettling in this iteration of the series, and on a story-level it tackles death, denial, despair, regret and many more adult topics.
            </p>
            <p>
                The eponymous Majoras Mask contains a powerful entity and at the beginning of the games event it is stolen by an unsuspecting, playful forest creature called a <i>skull kid</i>.
                Whispering to the skull kid, the mask slowly starts to manipulate its host and with its magic, it draws the moon towards the earth to make it come crashing down after 3 days.
                The protagonist of the game is stuck in a 3 day time loop to prevent the moon from falling, resetting time whenever it runs out.
            </p>
        </section>
        <section>
            <h2>Printing</h2>
            <p>
                I found a decent fan-made 3D model online and adjusted it to fulfill my needs before converting it to a printable STL format.
                Most importantly, I wanted the masks horns to be separate parts so the mask would fit in the printer and because I wanted them to have a transparent material.
            </p>
            <p>
                Luckily, my dormitory has a 3D printing room with multiple printers and an entire community of people that use them frquently and were able to help with any of my issues!
                They checked my model which seemed to be fine and helped me set up the printer.
                In total, the mask took around 12 hours to print, since it does have a lot of material and is actually quite heavy.
            </p>
            <p>
                The 3D printing software we used created a lot of support structures that increase stability during the printing process.
                I carefully had to break them off and remove them one by one.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../../media/projects/majoras-mask/printing-1.jpg"),
                    src_thumb : require("../../../media/projects/majoras-mask/printing-1-540.jpg"),
                    src_thumb_preload : require("../../../media/projects/majoras-mask/printing-1-32.jpg"),
                    alt : "The masks parts after 3D printing",
                    title : "The mask fresh out of the printer! The horns are detachable and fit neatly into the masks holes."
                },{
                    src : require("../../../media/projects/majoras-mask/printing-2.jpg"),
                    src_thumb : require("../../../media/projects/majoras-mask/printing-2-540.jpg"),
                    src_thumb_preload : require("../../../media/projects/majoras-mask/printing-2-32.jpg"),
                    alt : "3D printers often print support structures that increase stability during the printing process",
                    title : "Using a cutter and tweezers I removed the many support structures."
                }
            ]} />
        </section>
        <section>
            <h2>Electronics</h2>
            <p>
                To make this mask a true eye-catcher, I wanted the horns to glow in different colors.
                So I gathered a couple LEDs, matching resistors, a switch and a 9V battery together and soldered everything together.
                To fixate the battery and switch, I simply used hot glue.
            </p>
            <p>
                The process was a little fiddly, but the LEDs fit perfectly through the holes and into the horns, so apparently my measurements during the 3D model design paid off!
                I was also quite happy with how the translucent horns diffuse the light, creating a beautiful glow.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../../media/projects/majoras-mask/soldering-1.jpg"),
                    src_thumb : require("../../../media/projects/majoras-mask/soldering-1-540.jpg"),
                    src_thumb_preload : require("../../../media/projects/majoras-mask/soldering-1-32.jpg"),
                    alt : "Soldering the LEDs, the 9V battery and the switch together and putting the LEDs into Majoras Masks horns",
                    title : "Ever felt like you don't have enough hands while soldering? These clamps make soldering a easier."
                },{
                    src : require("../../../media/projects/majoras-mask/soldering-2.jpg"),
                    src_thumb : require("../../../media/projects/majoras-mask/soldering-2-540.jpg"),
                    src_thumb_preload : require("../../../media/projects/majoras-mask/soldering-2-32.jpg"),
                    alt : "At the back of Majoras Mask you can see the wiring that is generously isolated with shink tubing.",
                    title : "Using shrink tubing improves your cable management and can also help with isolation"
                }
            ]} />
            <p>
                Since I didn't want any wires or batteries in my eyes while carrying the mask, I cut out a black cloth, punched out holes for the eyes and hot-glued it to the back of the mask, hiding all the electronics.
            </p>
        </section>
        <section>
            <h2>Painting & Result</h2>
            <p>
                The mask has many bright colors and especially the eyes glow in yellow, orange and red.
                To get that high level of saturation, I decided on acrylic paint.
                Most of the surfaces are monochrome, so I only had to mix a couple colors to get them right.
                The eyes however took multiple attempts until I was happy.
                After the paint was dry, I added a matt finish to give the mask a cracked look.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../../media/projects/majoras-mask/result-bright.jpg"),
                    src_thumb : require("../../../media/projects/majoras-mask/result-bright-540.jpg"),
                    src_thumb_preload : require("../../../media/projects/majoras-mask/result-bright-32.jpg"),
                    alt : "I painted Majoras Mask with acrylic colors",
                    title : "The bright colors of the acrylic paint turned out quite well! Don't look into the eyes for too long though..."
                },{
                    src : require("../../../media/projects/majoras-mask/result-dark.jpg"),
                    src_thumb : require("../../../media/projects/majoras-mask/result-dark-540.jpg"),
                    src_thumb_preload : require("../../../media/projects/majoras-mask/result-dark-32.jpg"),
                    alt : "The LEDs are positioned in the translucent horns of Majoras Mask and glow brightly",
                    title : "The LEDs are quite bright when in darkness and glow in different colors!"
                }
            ]} />
            <p>
                This project was perfect to get to know 3D printing a little and during the Halloween Party I got many compliments, although people would sometimes not believe the mask was self-made at first...
                As a long-time fan of the Zelda series and this game especially, I'm happy to have Majoras Mask  decorating my wall up until today!
            </p>
        </section>
    </>);
}
