import React, { Component } from 'react';
import {encodeNameToURI} from "../data/helpers";
import { HashLink } from 'react-router-hash-link';

export default class TOC extends Component {
    constructor(props) {
        super(props);  
        this.state = {};  
    }

    scan(el) {

        const h2s = el.querySelectorAll("h2")

        let toc = [];
        for (const h2 of h2s) {
            const text = h2.innerHTML;
            const id = encodeNameToURI(text);
            toc.push({id: id, text: text});

            h2.classList.add("anchor")
            h2.id = id;
        }
        
        this.setState({toc: toc});
    }

    render() {
        if (this.state.toc) {
            return (
                <ul className="ul--toc" role="navigation">
                    {this.state.toc.map((toc) =>
                        <li key={toc.id}>
                            <HashLink to={"#" + toc.id}>
                                <i className="fa fa-chevron-right" />
                                <span>{toc.text}</span>
                            </HashLink>
                        </li>)}
                </ul>
            );
        } else {
            return (
                <p>Loading...</p>
            );
        }
        
    }
}