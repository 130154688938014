import React, { Component } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark.js';

export default class Code extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="code">
                <SyntaxHighlighter language={this.props.language} style={style}>
                    {this.props.content}
                </SyntaxHighlighter>
                {this.props.languageIndicator &&
                    <div className="code__lang">{this.props.languageIndicator}</div>
                }
            </div>
        );
    }
}