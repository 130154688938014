import React from "react";

import Link from '../component/Link';
import Video from "../component/Video";
import Ribbon from "../component/Ribbon";
import {ExternalLink} from "../component/IconLink";
import Img from "../component/Img";
import ImgGallery from "../component/ImgGallery";
import Code from "../component/Code";
import {SpecificTag} from "../views/Tag";

let projectContents = {};

projectContents.projectEmpty = function() {
    return <></>
};

//################################################################################################################################################################

projectContents.projectHaDiKoHandbook = function() {
    return <>
        <section>
            <h2>The HaDiKo</h2>
            <p>
                The <ExternalLink href="https://www.hadiko.de/">HaDiKo</ExternalLink> is Germanies largest self-administrated dormitory and with a total of 1102 rooms quit large in general.
                Self-administration for the HaDiKo means that the inhabitants themselves <b>decide on funds and priorities</b> in boards and meetings but also that they <b>take responsibility</b> and pull through on ideas and keep them alive.
                It also means that the application and moving-in procedures are done <b>nonsalaried by students</b>. The same goes for managing parking spots, constantly hotfixing the dormitory printer and even the internet administration!
                In return, every inhabitant gets an <b>insane amount of offers and services</b>.
                Just have a look at a couple of them:
            </p>
            <ul className="ul--text">
                <li>multiple study rooms</li>
                <li>rentable roof terraces, barbecue areas and billiard table</li>
                <li>a local self-build and -maintained internet and phone (!) infrastructure</li>
                <li>well equipped workshops for wood, metal and electrical working</li>
                <li>a CNC milling machine (have a look <Link to="/project/cnc-milling-a-birdhouse">what I used it for here</Link>)</li>
                <li>multiple 3D printers</li>
                <li>a VR room</li>
                <li>this handbook</li>
                <li>many groups of common interest around things like music, sports, photography, art, board games and more that get yearly funds</li>
            </ul>
            <p>
                So you can see why the HaDiKo would benefit from a handbook, because often times the list of things of interest is overwhelming.
                Part of my motivation for layouting, writing and continuously printing the handbook was that I found many of the HaDiKos offers after already living there for years.
                I wanted to make sure that people that move in don't miss out on everything there is to experience.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/hadiko-handbook/handbook-open-1.jpg"),
                    src_thumb : require("../../media/projects/hadiko-handbook/handbook-open-1-1080.jpg"),
                    src_thumb_preload : require("../../media/projects/hadiko-handbook/handbook-open-1-32.jpg"),
                    alt : "The HaDiKo newcomer handbook is a reference for students that move into the dorm to ease their way into the community and learn about its exciting offers",
                    title : "The HaDiKo handbook includes the structure of the dormitories self-administration, its committees and positions and how they interact."
                },{
                    src : require("../../media/projects/hadiko-handbook/handbook-open-2.jpg"),
                    src_thumb : require("../../media/projects/hadiko-handbook/handbook-open-2-1080.jpg"),
                    src_thumb_preload : require("../../media/projects/hadiko-handbook/handbook-open-2-32.jpg"),
                    alt : "The handbook includes important places, contact persons, mails and many offers of the dormitory",
                    title : "All the important information is there: how to get your internet, who to contact for which issue and when and where to reach them."
                }
            ]} />
        </section>
        <section>
            <h2>The handbook</h2>
            <p>
                Even though it is neither the most user-friendly, nor most fun tool to <SpecificTag name="design" showCount={true} /> with, we decided to create the handbook using <ExternalLink href="https://en.wikipedia.org/wiki/LaTeX" type="wiki">LaTeX</ExternalLink>.
                Main reason was to ensure that anyone could change and expand upon the handbook in the future without requiring a professional application like Adobe InDesign.
            </p>
            <p>
                First, we had to create a <SpecificTag name="latex" showCount={true} /> template, filter all the dormitories important information that should be in the handbook and then write the text in both German and English.
                We were working in a team of two and later three to get the initial version ready.
                My task was mainly the LaTeX part, meaning I created the template and all the non-trivial visual elements like tables and graphics.
            </p>
            <p>
                Deciding which contents and information to put in and in what order was challenging, but we ended up with a good mix of what is most important after moving in, the structure of the self-administration, how the HaDiKo works and an overview of all the exciting offers there are.
                While the most relevant information is easy to find on the first few pages, the handbook with its total of 44 pages also motivates to browse through activities, services and offers.
                I also added a couple fun things like a HaDiKo-specific crossword puzzle as well as a list of achievements to earn.
                Unfortunately, I cannot upload the digital version of the handbook for legal reasons, so you'll have to make due with the photos.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/hadiko-handbook/thumb.jpg"),
                    src_thumb : require("../../media/projects/hadiko-handbook/thumb-1080.jpg"),
                    src_thumb_preload : require("../../media/projects/hadiko-handbook/thumb-32.jpg"),
                    alt : "Depending on the month, about 20-100 copies of the handbook have to be printed",
                    title : "Every month, the newcomers receive a copy of the handbook - in german or english depending on their preferred language. In octobers, there can be up to 100 newcomers!"
                },{
                    src : require("../../media/projects/hadiko-handbook/handbooks-and-stapler.jpg"),
                    src_thumb : require("../../media/projects/hadiko-handbook/handbooks-and-stapler-1080.jpg"),
                    src_thumb_preload : require("../../media/projects/hadiko-handbook/handbooks-and-stapler-32.jpg"),
                    alt : "Every month the handbooks have to be printed, folded and stapled",
                    title : "We printed the handbooks with the dormitories printer, folded them and stapled them before distributing them into the newcomers post boxes. In the back you can see the monstrosity of a stapler we use!"
                }
            ]} />
        </section>
        <section>
            <h2>Print and distribution</h2>
            <p>
                Of course, the project never ends.
                First, the handbook has to be updated every couple months to include any changes in the services and offers.
                And more importantly, every month, the handbook have to be printed, folded, stapled and distributed into the newcomers respective post boxes.
            </p>
            <p>
                We used the dormitories printer to print 20-100 copies per month (the number varies strongly depending on the month).
                Stapling is actually kind of fun for a while, since we thankfully have an electric stapler that looks like a drilling machine.
            </p>
            <p>
                The total maintenance time is not as long when done as 2 or 3, so it is definitely worth-while.
                Actually, we were very happy to hear that many people liked having a guide book and that they actually found new communities within the dormitory by looking through the handbook.
                I can only hope that the handbook's maintenance will be continued long into the future even without me!
            </p>
        </section>
    </>
};

//################################################################################################################################################################

projectContents.projectNotifyMe = function() {
    return (<>
        <section>
            <h2>The assignment</h2>
            <p>

            </p>
            <p>
                This project is the result of a <SpecificTag name="uni" showCount={true} /> assignment from a lecture called <b>Mobile computing and Internet of Things</b> by a somewhat unique institute called the <ExternalLink href="https://www.teco.edu/">Telecooperation Office</ExternalLink>.
                It was an optional course and to be honest it felt like patch-work, as topics would range from new mobile browser features and sensors to wearable hardware and then to IoT architecture and protocols.
                The professor called this patch-work <b>ubiquitous computing</b> and it is the vision to connect all our devices, sensors and even motors to create pervasive systems all around us.
            </p>
            <p>
                To pass the lecture two assignments had to be submitted.
                Both required the creation of a website or mobile app, which was lucky for me given my background.
                Your can find the result of the other assignment right <Link to="/project/marbletilt">here</Link>.
            </p>
        </section>
        <section>
            <h2>Wearable Technology</h2>
            <p>
                I have to apologize for baiting you onto this project using a thumbnail with a sci-fi glove, but I had no good photo of my actual project (and frankly, it looks less impressive...).
                For this first assignment of the course, we were given quite a lot of hardware to work with:
            </p>
            <ul className="ul--text">
                <li>A tiny <b>bluetooth low energy</b> microcontroller (RedBear BLE Nano V2.0 SoC)</li>
                <li>6 <b>vibration</b> wearables that can be connected to the microcontroller</li>
                <li>Either a beanie, scarf or glove</li>
            </ul>
            <p>
                The task was to use those devices and create a mobile website that would control the vibration wearable remotely.
                Apart from that, we were given complete freedom over the appliance!
            </p>
            <p>
                I chose the beanie and attached the vibration wearables to a plastic ring and hid it in the beanie (it was not exactly comfortable, but I'm no tailor).
                My idea was to differentiate categories of mobile phone notifications using unique vibration patterns.
                So a social media notification would feel different than a mail or when your battery runs low.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/notify-me/wearable-technology-hat.png"),
                    src_thumb : require("../../media/projects/notify-me/wearable-technology-hat-540.png"),
                    src_thumb_preload : require("../../media/projects/notify-me/wearable-technology-hat-32.png"),
                    alt : "The microcontroller and vibration wearables are small enough to fit into the beanie and communicate with your phone via Bluetooth Low Energy",
                    title : "The ReadBear BLE Nano is small enough to be hidden in a beanie"
                },{
                    src : require("../../media/projects/notify-me/wearable-technology-glove.png"),
                    src_thumb : require("../../media/projects/notify-me/wearable-technology-glove-540.png"),
                    src_thumb_preload : require("../../media/projects/notify-me/wearable-technology-glove-32.png"),
                    alt : "Wearable technology glove with vibration wearables the fingers with separately addressable vibration strengths",
                    title : "This glove is not the interface to a mech-suit but has vibration wearables on 4 of the fingers"
                }
            ]} />
        </section>
        <section>
            <h2>Bluetooth Low energy</h2>
            <p>
                <ExternalLink href="https://en.wikipedia.org/wiki/Bluetooth_Low_Energy" type="wiki">Bluetooth Low Energy</ExternalLink>, while similar to regular bluetooth, is a separate protocol that minimizes energy consumption by sacrificing range and speed.
                That makes it an obvious choice for small mobile devices and sensors that broadcast their data.
                In my case, I had to periodically control 6 motors with individual 8 bit data to represent a vibration strength between 0 and 255.
                That was more than enough to create different vibration patterns and I ended up creating 8 unique patterns that are easily distinguishable and could be adjusted in strength and speed.
            </p>
            <p>
                Most modern phones support Bluetooth Low Energy but using it from a website is another story.
                So to even get there we had to use <ExternalLink href="https://github.com/phonegap/phonegap-cli" type="github">Phone Gap</ExternalLink>, a tool that builds native mobile apps by taking a HTML/CSS/JS website and giving it access to native APIs.
                The PhoneGap Build tool was discontinued in 2020 and <ExternalLink href="https://cordova.apache.org/">Cordova</ExternalLink> is pretty much its successor.
            </p>
            <p>
                With the help of PhoneGaps Bluetooth Low Energy API I created this BluetoothConnector to handle the connection state.
                Making the BluetoothConnector a Backbone.js <code>Model</code> allowed automatic rerendering of the <code>Views</code>.
                If you're interested, have a look at the code:
            </p>
            <Code language="javascript" languageIndicator="JavaScript" content={`(function() {

    window.app = window.app || {}
    window.app.models = window.app.models || {};

    window.app.models.BluetoothConnector = Backbone.Model.extend({

        DEVICE_NAME : "TECO Wearable 3",
        SCAN_TIMEOUT : 5,
        SERVICE : "713D0000-503E-4C75-BA94-3148F18D941E",
        CHARACTERISTIC : "713D0003-503E-4C75-BA94-3148F18D941E",

        initialize : function() {
            Backbone.Model.prototype.initialize.call(this);

            if (window.ble === undefined) {
                this.bluetoothIsDisabled();
            } else {
                window.ble.isEnabled(
                    this.bluetoothIsEnabled.bind(this),
                    this.bluetoothIsDisabled.bind(this)
                );
            }
        },

        // Define default state object
        defaults: function() {
            return {
                bluetoothEnabled : null,
                deviceFound : null,
                deviceId : null,
                deviceConnected : null,
                lastSendSuccessful : null
            }
        },

        // Called once if bluetooth is enabled
        bluetoothIsEnabled : function() {
            this.set("bluetoothEnabled", true);
            this.startScan();
        },

        // Called once if bluetooth is not enabled
        bluetoothIsDisabled : function() {
            this.set("bluetoothEnabled", false);
            console.error("Bluetooth not enabled or accessible");
        },

        // Start scanning for bluetooth devices
        startScan : function() {
            ble.scan([], this.SCAN_TIMEOUT,
                this.scanHandler.bind(this),
                this.scanError.bind(this)
            );

            window.setTimeout(function() {
                if (!this.get("deviceFound")) {
                    this.scanError();
                }
            }.bind(this), 1000 * (this.SCAN_TIMEOUT + 1));
        },

        // Called for each found device
        scanHandler : function(device) {
            if (device.name === this.DEVICE_NAME) {
                this.set("deviceFound", true);
                this.set("deviceId", device.id)
                this.connect(device);
            }
        },

        // Called upon error while scanning
        scanError : function() {
            console.error("Could not find device with name " + this.DEVICE_NAME + " after " + this.SCAN_TIMEOUT + " seconds, retrying...");
            this.set("deviceFound", false);
            this.startScan();
        },

        // Starts a connection to the device of the internally stored id
        connect: function() {
            ble.connect(this.get("deviceId"),
                this.connectSuccess.bind(this),
                this.connectError.bind(this)
            );
        },

        // Called when connections to specific device was successfully established
        connectSuccess: function() {
            this.set("deviceConnected", true);
        },

        // Called when connection to specific device failed
        connectError: function() {
            this.set("deviceConnected", false);
            this.set("lastSendSuccessful", null);
            console.error("Could not connect to device with id " + this.get("deviceId"), " or device disconnected, retrying...");
            this.connect();
        },

        // Sends an array via bluetooth, e.g.:
        // uint8Array = new Uint8Array([0x00, 0xFF, ...])
        send: function(uint8Array) {
            if (!this.get("deviceConnected")) {
                return;
            }
            ble.write(this.get("deviceId"), this.SERVICE, this.CHARACTERISTIC, uint8Array.buffer,
                this.sendSuccess.bind(this),
                this.sendError.bind(this)
            );
        },

        // Called upon successfully sending a message to the connected device
        sendSuccess: function() {
            this.set("lastSendSuccessful", true);
        },

        // Called when sending a message failed
        sendError: function() {
            this.set("lastSendSuccessful", false);
        },
    });

})();
`} />
        </section>
        <section>
            <h2>The App</h2>
            <p>
                Unfortunately, I found no way to access the phones actual notifications despite using PhoneGap, so instead I hacked together a mockup &lsquo;operating system&rsquo; that would generate fake notifications over time.
                There are a couple of screens to allow for a fake simulation with notifications.
            </p>
            <ImgGallery cols={2} images={[
                {
                    src : require("../../media/projects/notify-me/os-mockup-lockscreen-1.png"),
                    src_thumb : require("../../media/projects/notify-me/os-mockup-lockscreen-1-540.png"),
                    src_thumb_preload : require("../../media/projects/notify-me/os-mockup-lockscreen-1-32.png"),
                    alt : "Lockscreen of the mobile os mockup",
                    title : "Empty lockscreen with a cheap iOS look. The top left indicates whether the bluetooth connection with the microcontroller is properly setup and if geolocation is active and permitted"
                },{
                    src : require("../../media/projects/notify-me/os-mockup-lockscreen-2.png"),
                    src_thumb : require("../../media/projects/notify-me/os-mockup-lockscreen-2-540.png"),
                    src_thumb_preload : require("../../media/projects/notify-me/os-mockup-lockscreen-2-32.png"),
                    alt : "The lockscreen show various notifications along with their calculated importance score",
                    title : "After some time, the mockup generates random notifications each with its estimated importance"
                },{
                    src : require("../../media/projects/notify-me/os-mockup-home.png"),
                    src_thumb : require("../../media/projects/notify-me/os-mockup-home-540.png"),
                    src_thumb_preload : require("../../media/projects/notify-me/os-mockup-home-32.png"),
                    alt : "",
                    title : ""
                },{
                    src : require("../../media/projects/notify-me/os-mockup-mail.png"),
                    src_thumb : require("../../media/projects/notify-me/os-mockup-mail-540.png"),
                    src_thumb_preload : require("../../media/projects/notify-me/os-mockup-mail-32.png"),
                    alt : "Mockup home screen showing notifications on the various apps",
                    title : "There are 5 different fake apps and a debug settings app"
                }
            ]} />
            <p>
                Though the website would be converted into a native App by PhoneGap, I still had to implement a website with multiple screens with many views and manage the state for things like the notifications.
                To facilitate that, I decided to use <ExternalLink href="https://backbonejs.org/">Backbone.js</ExternalLink>, a web app framework with a <ExternalLink href="https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93controller" type="wiki">model-view-controller</ExternalLink> design.
            </p>
            <p>
                On top of assigning unique vibrations pattern to each of the fake apps the mockup also &lsquo;learns&rsquo; which notifications are more relevant to you by remembering the time you took to read the notification.
                Having these data points, I was able to assign a score to each notification and then modulate the vibration strength according to its importance.
                I went all out at some point and decided to add the current geolocation and time to these data points.
                To calculate the importance score, I did a cluster search in all the previous notification data points and calculated the average <b>time to notification-read</b>.
                As the dimensions for the cluster search I used:
            </p>
            <ul className="ul--text">
                <li>App</li>
                <li>Notification title</li>
                <li>Weekday</li>
                <li>Time of day</li>
                <li>Geolocation</li>
            </ul>
            <p>
                I remember debugging to be quite the experience.
                Like all appliances that connect more than one device wirelessly, testing and debugging the connection (and re-connecting on disconnect) was nerve-racking.
                But you have to imagine that during the process I had a beanie with wires hanging out that would sometimes start vibrating violently.
                That must have been quite the sight.
            </p>
            <p>
                Focusing on innovative human machine interfaces, the TECO institute holds usability and user experience in high regards.
                To show the professor (who would grade my project during a demonstration) I had put thought into the appliance, I actually walked around with this ridiculous hat with the app constantly on for two days!
                While going about my daily life as a student, whenever I was outside checking my <i>actual</i> notifications, I would also check the fake ones my app had generated.
                To generate some meaningful data, I looked at important-looking notifications while ignoring or swiping spam mail and game notifications.
                My study showed, that (after some tweaking) I was in fact able to distinguish between the generation patterns and thus the app.
                Unfortunately I also found that my notification importance learning was not as sophisticated as I had hoped, resulting in vibration strengths that felt random.
                My 2 days and fake behaviour was either not able to generate enough realistic data points or you might need a little something called a neural net to improve the calculation.
            </p>
        </section>
        <section>
            <h2>The demonstration</h2>
            <p>
                At the of the semester it was time to demonstrate the two projects to the professor.
                He liked the concept of using vibration pattern for notifications quite a bit, but mainly he was surprised by the size and complexity of my Frontend!
                After explaining what I just explained in this article, the professor actually offered me a job to redevelop his institutes website :)
            </p>
        </section>
    </>)
};

//################################################################################################################################################################

projectContents.projectMarbleTilt = function() {
    return (<>
        <section>
            <h2>The assignment</h2>
            <p>
                Believe it or not, this was actually the result of a <SpecificTag name="uni" showCount={true} /> assignment from a lecture called <b>Mobile computing and Internet of Things</b> by a somewhat unique institute called the <ExternalLink href="https://www.teco.edu/">Telecooperation Office</ExternalLink>.
                It was an optional course and to be honest it felt like patch-work, as topics would range from new mobile browser features and sensors to wearable hardware and then to IoT architecture and protocols.
                The professor called this patch-work <b>ubiquitous computing</b> and it is the vision to connect all our devices, sensors and even motors to create pervasive systems all around us.
            </p>
            <p>
                To pass the lecture two assignments had to be submitted.
                Both required the creation of a website or mobile app, which was lucky for me given my background.
                Your can find the result of the other assignment right <Link to="/project/wearable-technology-and-sci-fi-clothes">here</Link>.
            </p>
        </section>
        <section>
            <h2>New mobile browser features</h2>
            <p>
                The HTML5 specification was finalized in 2014 (4 years prior to this lecture) by the <ExternalLink href="https://www.w3.org/">World Wide Web Consortium</ExternalLink> and added many features developers had long awaited.
                The professor taught us about some of the HTML5 features like
            </p>
            <ul className="ul--text">
                <li><ExternalLink href="https://developer.mozilla.org/en-US/docs/Glossary/Semantics">Semantic html tags</ExternalLink> like <code>&lt;nav&gt;</code>, <code>&lt;aside&gt;</code> or <code>&lt;section&gt;</code></li>
                <li>The <code>&lt;video&gt;</code> tag which allows playing videos without flash</li>
                <li>The <code>&lt;canvas&gt;</code> tag for custom rendering</li>
                <li>New <code>&lt;input&gt;</code> types like <code>range</code>, <code>color</code> or <code>date</code></li>
                <li>Webcam/mobile camera access</li>
                <li>Geolocation</li>
                <li>Accelerometer, gyrometer and magnetometer sensor data</li>
            </ul>
            <p>
                Our task was then to utilize some of these features and build a creative web app.
                So the professor gave us the freedom to make our own appliance!
            </p>
        </section>
        <section>
            <h2>The game</h2>
            <p>
                I had never used the accelerometer, gyrometer and magnetometer in a mobile app and since I was also able to use the <code>&lt;canvas&gt;</code> tag, I decided to create a small 3D game.
                The games look might be a little reminiscent of another <Link to="/project/quizzle">3D mobile game I had previously developed</Link> but this time I used another <SpecificTag name="webgl" showCount={true} /> framework called <ExternalLink href="https://www.babylonjs.com/">Babylon.js</ExternalLink>.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/marble-tilt/screenshot-menu.png"),
                    src_thumb : require("../../media/projects/marble-tilt/screenshot-menu-540.png"),
                    src_thumb_preload : require("../../media/projects/marble-tilt/screenshot-menu-32.png"),
                    alt : "The main menu of MarbleTilt tells you if your browsers supports the canvas tag, WebGL rendering, the gyrometer and accelerometer",
                    title : "When you open the game, it tells you whether your browser supports all required features"
                },{
                    src : require("../../media/projects/marble-tilt/screenshot-gameplay-1.png"),
                    src_thumb : require("../../media/projects/marble-tilt/screenshot-gameplay-1-540.png"),
                    src_thumb_preload : require("../../media/projects/marble-tilt/screenshot-gameplay-1-32.png"),
                    alt : "The player must tilt his phone to move the marble across the level",
                    title : "The maze-like path is generated with a branching random walk algorithm"
                }
            ]} />
            <p>
                To win, the player has to tilt his mobile phone to roll a marble towards the goal of a maze.
                The maze-like paths are generated and get longer every level.
                Just for fun there are also optional coins to collect that emit particles on collection.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/marble-tilt/screenshot-gameplay-2.png"),
                    src_thumb : require("../../media/projects/marble-tilt/screenshot-gameplay-2-540.png"),
                    src_thumb_preload : require("../../media/projects/marble-tilt/screenshot-gameplay-2-32.png"),
                    alt : "The map generation increases the labyrinth size and number of branches with each level",
                    title : "Level size and complexity rise with each level"
                },{
                    src : require("../../media/projects/marble-tilt/screenshot-lost.png"),
                    src_thumb : require("../../media/projects/marble-tilt/screenshot-lost-540.png"),
                    src_thumb_preload : require("../../media/projects/marble-tilt/screenshot-lost-32.png"),
                    alt : "",
                    title : "I noticed early on in development that the game can bee infuriating, so I decided to quote demotivational phrases when you plunge into your death"
                }
            ]} />
            <p>
                While every play tester died instantly the first time they try it, after a minute or two, the gyrometer controls are surprisingly intuitive!
                Make sure you try the game on mobile, because the fallback to the mouse cursor isn't quite as fun.
            </p>
        </section>
    </>);
};

//################################################################################################################################################################

projectContents.projectQuizzle = function() {
    return (<>
        <section>
            <h2>The canvas tag and how it changed the web</h2>
            <p>
                With the introduction of the <code>&lt;canvas&gt;</code> tag to HTML, web developers were suddenly able to freely render anything they wanted using JavaScript.
                But what was fascinating was not the ability to draw textures, rectangles and pixels but the addition of the <SpecificTag name="webgl" showCount={true} /> which allowed using the GPU to get some <i>proper</i> rendering - even in 3D!
            </p>
            <p>
                Of course, libraries like <ExternalLink href="https://threejs.org/">Three.js</ExternalLink> were quick to make use of these new browser features allowing developers like me to create 3D-scenes without having to deal with WebGL (which I would definitely not have been able to do at the time).
                Already having developed web games based on JavaScript and PHP before, I was hyped to create a 3D game in the browser for the first time.
            </p>
            <ImgGallery cols={2} images={[
                {
                    src : require("../../media/projects/quizzle-game/screenshot-1.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-1-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-1-32.png"),
                    alt : "In Quizzle, the player needs to push the blocks to create bridges, build stairs and more",
                    title : "The first few levels are easy and more like tutorials"
                },{
                    src : require("../../media/projects/quizzle-game/screenshot-2.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-2-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-2-32.png"),
                    alt : "The games puzles becomes increasingly more complex with each level",
                    title : "Good puzzle game design does not mean over-burdening the player. Instead you should teach the player the games concepts in simple levels and then re-use and combine them in complexer version"
                },{
                    src : require("../../media/projects/quizzle-game/screenshot-3.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-3-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-3-32.png"),
                    alt : "The player has to carefully plan which blocks to push where to",
                    title : "Pushing blocks in tight spaces can be challenging and one wrong move can destroy everything"
                },{
                    src : require("../../media/projects/quizzle-game/screenshot-4.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-4-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-4-32.png"),
                    alt : "An interesting level with a central junction",
                    title : "Please don't judge me for the lava texture, that could have gone better :)"
                }
            ]} />
        </section>
        <section>
            <h2>Technologies used</h2>
            <p>
                Three.js makes it easy to create and add 3D objects with textures and of course, it has many 3D engine features like camera movement, lighting and so on.
                Behind the scenes, it uses the WebGL API to do all the complicated GPU stuff.
                Since quizzle is a game entirely made of cubes, creating the meshes and texturizing was quite simple.
            </p>
            <p>
                Manipulating the HTML DOM through JavaScript was a pain at the time and even CSS was way less powerful than it is today.
                To mitigate these problems and to make sure everything worked on every browser (which was also a much bigger pain than today) I resorted - like so many others - to using the extensive <ExternalLink href="https://jquery.com/">jQuery</ExternalLink> library.
            </p>
        </section>
        <section>
            <h2>The game</h2>
            <p>
                I have always been a fan of puzzle games and especially the ones where you would have to cleverly push blocks around to win.
                Since I had no idea how to create 3D models I stuck with cubes.
            </p>
            <p>
                The first few levels are simple and ease the player into the game but I must admit, some of the later levels get increasingly convoluted.
                Why don't you click that play button and see if you can solve the levels yourself? ;)
            </p>
            <ImgGallery cols={2} images={[
                {
                    src : require("../../media/projects/quizzle-game/screenshot-5.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-5-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-5-32.png"),
                    alt : "",
                    title : "This is actually my favorite level. I has multiple floors and the goal is to create one big stack of cubes to reach the end"
                },{
                    src : require("../../media/projects/quizzle-game/screenshot-6.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-6-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-6-32.png"),
                    alt : "",
                    title : "There is even a 'world 2' with a different look and some new blocks"
                },{
                    src : require("../../media/projects/quizzle-game/screenshot-7.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-7-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-7-32.png"),
                    alt : "",
                    title : "For some levels, rotating the camera is essential to find the solution"
                },{
                    src : require("../../media/projects/quizzle-game/screenshot-8.png"),
                    src_thumb : require("../../media/projects/quizzle-game/screenshot-8-540.png"),
                    src_thumb_preload : require("../../media/projects/quizzle-game/screenshot-8-32.png"),
                    alt : "",
                    title : "Sorry about this level, it looks cool but is enormously annoying"
                }
            ]} />
        </section>
    </>
    );
};

//################################################################################################################################################################

projectContents.projectRekit = function() {
    return (<>
        <section>
                <h2>The game and how it came to be</h2>
                <p>
                    I started working on this project shortly after I had learnt Java formally at <SpecificTag name="uni" showCount={true} />.
                    Learning about proper code engineering, UML diagrams, software architecture and design patterns, I must have been quite eager to try them out.
                    For whatever reason I thought programming a game from scratch along with an entire game engine was a good idea...
                </p>
                <p>
                    I was probably annoying my fellow students by constantly talking about this game but I actually managed to get some of them on board.
                    Over the next couple months that small project developed into a proper jump &rsquo;n&rsquo; run game with multiple levels along with a multitude of special blocks, enemies and even boss enemies.
                    Under the hood, we had created a 2D game engine with everything we needed:
                </p>
                
                <ul className="ul--text">
                    <li>A <code>GameElement</code> class as the game&rsquo;s building blocks</li>
                    <li>Scene management for adding and removing GameElements</li>
                    <li>A logic and a render loop</li>
                    <li>Physics that apply velocities and detect collisions</li>
                    <li>A graphical interface for drawing graphics and geometric shapes</li>
                    <li>Double buffered rendering</li>
                    <li>A movable camera</li>
                    <li>Screen filters</li>
                    <li>Particles</li>
                </ul>
                
                <ImgGallery cols={2} images={[
                    {
                        src : require("../../media/projects/rekit-game/screenshot-arcade-1.png"),
                        src_thumb : require("../../media/projects/rekit-game/screenshot-arcade-1-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/screenshot-arcade-1-32.png"),
                        alt : "RεKiT is a jump 'n' run game written from scratch in Java",
                        title : "In the arcade mode, hand-crafted levels showcase unique special blocks"
                    },{
                        src : require("../../media/projects/rekit-game/screenshot-arcade-2.png"),
                        src_thumb : require("../../media/projects/rekit-game/screenshot-arcade-2-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/screenshot-arcade-2-32.png"),
                        alt : "There are many unique special blocks in the game",
                        title : "Of course, trampolin and wall-jump blocks can't be missing in any jump 'n' run game"
                    },{
                        src : require("../../media/projects/rekit-game/screenshot-infinite-1.png"),
                        src_thumb : require("../../media/projects/rekit-game/screenshot-infinite-1-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/screenshot-infinite-1-32.png"),
                        alt : "RεKiT has an infinite mode with a generated level",
                        title : "There is also an infinite level that is assembled from pre-defined level fragments"
                    },{
                        src : require("../../media/projects/rekit-game/screenshot-infinite-2.png"),
                        src_thumb : require("../../media/projects/rekit-game/screenshot-infinite-2-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/screenshot-infinite-2-32.png"),
                        alt : "The levels are generated by compositing hand-crafted pieces of levels together",
                        title : "In the infinite mode, it's all about the highscore"
                    },{
                        src : require("../../media/projects/rekit-game/screenshot-boss-1.png"),
                        src_thumb : require("../../media/projects/rekit-game/screenshot-boss-1-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/screenshot-boss-1-32.png"),
                        alt : "The bosses in game are harder and have more complex movement patterns than the rest of the enemies",
                        title : "There are two bosses in the game. This one changes its moving direction whenever it hits a wall and must be defeated by jumping onto the side of him that temporarly has no spikes"
                    },{
                        src : require("../../media/projects/rekit-game/screenshot-boss-2.png"),
                        src_thumb : require("../../media/projects/rekit-game/screenshot-boss-2-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/screenshot-boss-2-32.png"),
                        alt : "This world-dominating robot shoots lasers and rockets at you.",
                        title : "This crazy robot keeps moving around and shoots rockets and lasers at you. We were actually able to just reuse both the rocket and the cannon, as they already existed as enemies. To defeat it, you need to jump on top of the rockets and then his head."
                    }
                ]} />
                <p>
                    I wouldn't exactly call RεKiT particularly pretty, but the design choice to render most of the game using simple geometric shapes gives it a somewhat unique look.
                    Working on this project with my fellow computer scientist friends was a new experience to me, because I had never worked on a hobby programming project with other programmers.
                    We were determined to follow the rules of code style and architecture we had studied to practice and internalize them.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/rekit-game/concept-1.jpg"),
                        src_thumb : require("../../media/projects/rekit-game/concept-1-540.jpg"),
                        src_thumb_preload : require("../../media/projects/rekit-game/concept-1-32.jpg"),
                        alt : "Level design concept sheet",
                        title : "With no level creator, designing levels was mostly done on paper and then hard-coded in matrices"
                    },{
                        src : require("../../media/projects/rekit-game/concept-2.jpg"),
                        src_thumb : require("../../media/projects/rekit-game/concept-2-540.jpg"),
                        src_thumb_preload : require("../../media/projects/rekit-game/concept-2-32.jpg"),
                        alt : "Concept art for one of RεKiTs bosses",
                        title : "Iterative design of the bosses visuals"
                    }
                ]} />
                <p>
                    If you want to try out the game yourself, download the JAR file and try executing it.
                    If it doesn't work, you might have to open it from the terminal using <code>java -jar RekiT.jar</code> if you have the means.
                </p>
                <Ribbon color="primary">
                    <div className="center--horizontal space-content">
                        <Video
                            src={require("../../media/projects/rekit-game/gameplay-1.webm")}
                            srcFallback={require("../../media/projects/rekit-game/gameplay-1-firstframe.png")}
                            scrollIn={true}
                        />
                    </div>
                    <div className="center--horizontal space-content">
                        <Video
                            src={require("../../media/projects/rekit-game/gameplay-2.webm")}
                            srcFallback={require("../../media/projects/rekit-game/gameplay-2-firstframe.png")}
                            scrollIn={true}
                        />
                    </div>
                    <div className="center--horizontal space-content">
                        <Video
                            src={require("../../media/projects/rekit-game/gameplay-3.webm")}
                            srcFallback={require("../../media/projects/rekit-game/gameplay-3-firstframe.png")}
                            scrollIn={true}
                        />
                    </div>
                </Ribbon>
            </section>
            <section>
                <h2>Using the game as an educational tool</h2>
                <p>
                    After some the time, my friend Dominik who had been working on RεKiT with me the most and me started tutoring at university, teaching <i>software engineering</i> to second semesters.
                    Trying to create engaging slides and examples, I often found myself using video games as examples, sometimes even showing examples from RεKiT.
                </p>
                <p>
                    The topic of <ExternalLink href="https://en.wikipedia.org/wiki/Software_design_pattern" type="wiki">design patterns</ExternalLink> was especially important for the students exam preparations.
                    Design patterns are reusable pieces of architecture and coding strategies for commonly re-appearing software design problems.
                    However, most students just memorized their name, idea and (if time allowed it) UML structure like it was a list of facts to remember.
                    To me, that was tragic because I had already experienced their helpfulness in practice.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/rekit-game/slide-design-patterns.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-design-patterns-1080.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-design-patterns-32.png"),
                        alt : "Overview and categorization of programming design patterns",
                        title : "Overview of all design patterns that students had to learn for the exam. Notice how the slide seems to be to small to handle the table"
                    }
                ]} />
                <p>
                    To prevent my students from never actually <i>applying</i> design patterns, I offered an additional tutorial were they could use them in a real application.
                    That application was, you guessed it, none other than RεKiT.
                    In fact, that was when we decided to call the project RεKiT, as it is a not-so-clever play on words containing the name of our uni (and a nerdy Greek letter).
                    And since Dominik and I had been working on RεKiT together, he invited his tutorials students as well.
                </p>
                <p>
                    It was a fun event, and the students that showed up, programmed an enemy or special block while using design patterns like state machines, abstract factories, singletons and iterators.
                    Dominik and I would just walk around the seminar room and answer any questions that arose.
                </p>
            </section>
            <section>
                <h2>Creating a programming event</h2>
                <p>
                    A year passed, and we continued our studies as well as our tutor jobs.
                    When the topic of design patterns returned, we expanded our extra tutorial into something bigger.
                    So we asked the professor's permission to announce the event to all students of software engineering, reserved a larger room for the students to program and created some slides.
                </p>
                <p>
                    Meanwhile, we prepared RεKiTs codebase as well.
                    We added a modding feature that allowed enemies, special blocks and items to be dynamically patched into the game using Java reflections (which I still find a little scary).
                    Thus, the students wouldn't have to work inside a gigantic project they had never seen but instead focus on their idea.
                </p>
                <p>
                    Creating the slides that would explain the students how the project works, and more importantly, how they would add their own content was quite challenging, since we didn't want to bore the students to long and get to programming as fast as possible.
                </p>
                <ImgGallery cols={2} images={[
                    {
                        src : require("../../media/projects/rekit-game/slide-gameelement.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-gameelement-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-gameelement-32.png"),
                        alt : "At the start of the programming tutorial we held a presentation to make the students familiar with the most important concepts of the game",
                        title : "The most important thing for the students to know was how GameElements work and what methods and features they offer"
                    },{
                        src : require("../../media/projects/rekit-game/slide-gameelement-rendering.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-gameelement-rendering-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-gameelement-rendering-32.png"),
                        alt : "We showcased how one would render a pizza from simple geometric shapes",
                        title : "We found it important that the students would have an immediate visual feedback when testing their code, so we made sure to explain the drawing methods in detail"
                    },{
                        src : require("../../media/projects/rekit-game/slide-implementation-1.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-implementation-1-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-implementation-1-32.png"),
                        alt : "The attendants of the programming event would work on a mod that is dynamically loaded into the game",
                        title : "The students were given an example mod to work with, already containing some new GameElements as reference"
                    },{
                        src : require("../../media/projects/rekit-game/slide-implementation-2.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-implementation-2-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-implementation-2-32.png"),
                        alt : "Any blocks, enemies and items can be added into the games levels",
                        title : "Unfortunately, we never got to coding a level creator, so levels had to be painstakingly written in array form"
                    }
                ]} />
                <p>
                    After illustrating the most important concepts using the computer scientist cliché of a pizza as an example enemy, we gave them some inspiration on what <i>they</i> could do.
                    We came up with some ideas for enemies and blocks along with suitable design patterns that they could apply.
                    To make sure the students would have some immediate visual result while focusing on coding, I had created a texture pack with some basic blocks and enemies.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/rekit-game/slide-ideas-1.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-ideas-1-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-ideas-1-32.png"),
                        alt : "We gave the students some ideas for enemies and design patterns that would help to implement them",
                        title : "How about using the observer pattern to create an enemy with a weak spot? Or the factory pattern to create a cannon that shoots different types of projectiles?"
                    },{
                        src : require("../../media/projects/rekit-game/slide-ideas-2.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-ideas-2-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-ideas-2-32.png"),
                        alt : "The state machine design pattern is often use in games to change the behavior of game elements after some trigger and/or over time",
                        title : "The state and state machine pattern are quite useful in games. Of course, we also had a couple weird ideas, for example a block that moves forewards and backwards in time using the memento pattern"
                    },{
                        src : require("../../media/projects/rekit-game/slide-assets.png"),
                        src_thumb : require("../../media/projects/rekit-game/slide-assets-540.png"),
                        src_thumb_preload : require("../../media/projects/rekit-game/slide-assets-32.png"),
                        alt : "A texture pack for new blocks and enemies",
                        title : "In addition to rendering geometric shapes, the students were also supplied with a custom texture pack"
                    }
                ]} />
                <p>
                    The event was a complete success and many students saw it as an opportunity both to practice and to just have fun coding a game.
                    In the end, we filled two seminar rooms with students and their laptops, created heaps of crazy game features, ordered some pizza (of course we did) and got to know each other.
                </p>
                <p>
                    We would actually continue to repeat the design pattern extra tutorial a third time, getting more participants but also more software engineering tutors that supported us during the event.
                </p>
                <p>
                    I would like to thank Matthias, Julian, Florian, Fabian, Simon, Christian, Max and of course Dominik for making this amazing event possible!
                </p>
            </section>
    </>);
};

//################################################################################################################################################################

projectContents.projectHiwiCardiacAblationStrategies = function () {
    return (
        <>
            <section>
                <h2>Atrial fibrillation</h2>
                <p>
                    <ExternalLink href="https://en.wikipedia.org/wiki/Atrial_fibrillation" type="wiki">Atrial fibrillation</ExternalLink> is the most common arrhythmia and it increases the risk of both strokes and critical heart failures.
                    It occurs when the pathway for electrical signals that make the left atrium contract is disrupted by patient-specific anatomical abnormalities.
                </p>
                <Ribbon color="primary">
                    <div className="center--horizontal">
                        <Video
                            maxWidth="400px"
                            src={require("../../media/projects/hiwi-cardiac-ablation-strategies/atrial-fibrillation.webm")}
                            srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/atrial-fibrillation-firstframe.png")}
                            scrollIn={true}
                        />
                    </div>
                </Ribbon>
                <p>
                    A common therapy is catheter ablation, which effectively means scarring heart tissue to block irregular signals.
                    In cases where the fibrillations persist or return, patients must often undergo multiple ablation attempts, increasing the amount of scared tissue and overall risk with each procedure.
                    As such, computational models using patient-specific imaging data promise to increase the success rate of terminating the fibrillations by ablation while potentially minimizing the resulting scar tissue.
                </p>
            </section>
            <section>
                <h2>My job</h2>
                <p>
                    My goal as a research assistant was to generate ablation patterns, map them onto the surface of 3D heart models, introduce fibrillations and investigate the resulting electrical signals, hopefully terminating the arrhythmia.
                    Of course, I did not start from scratch, relying heavily on the cardiac electrophysiology simulator <ExternalLink href="https://opencarp.org/">OpenCARP</ExternalLink> that I had already been working with previously.
                    The open source <SpecificTag name="c%2B%2B" showCount={true} /> software was developed in co-operation by two universities, a research institute and experts in the industry.
                    You can even check out the <ExternalLink href="https://git.opencarp.org/openCARP">OpenCARP source code</ExternalLink>.
                </p>
            </section>
            <section>
                <h2>Starting on a 2D surface</h2>
                <p>
                    After setting up OpenCARP and experimenting with its tools and infrastructure, I got right into the ablation pattern generation, starting with 2D first.
                    That step was important to lay some groundwork for generating patterns from geometric primitives as well as to get a feel for fibrillations and how to terminate them on a flat surface.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-2d-patterns.png"),
                        src_thumb : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-2d-patterns-540.png"),
                        src_thumb_preload : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-2d-patterns-32.png"),
                        alt : "Transmission of electrical stimuli on simplified 2D surfaces and how ablation patterns influence it",
                        title : "First experiments with ablation patterns on a simplified 2D surface."
                    },{
                        src : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-2d-pattern-analysis.png"),
                        src_thumb : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-2d-pattern-analysis-540.png"),
                        src_thumb_preload : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-2d-pattern-analysis-32.png"),
                        alt : "Altering ablation patterns just slightly has potentially a huge influence on the rate of success",
                        title : "Evaluation of slight variances of the ablation patterns."
                    }
                ]} />
            </section>
            <section>
                <h2>Working with the 3D heart models</h2>
                <p>
                    The actual 3D atrium models I got to work with are actually a bilayered graph divided into segments.
                    On top of geometric properties and connections to neighbors, each node of the graph is associated with additional electrophysiological properties.
                    These electrophysiological properties are then used by OpenCARP to simulate the movement of electrical signals along the atrial tissue.
                    Altering these values was easy enough, thanks to the openCARP infrastructure.
                </p>
                <p>
                    Selecting the nodes proved to be much more challenging than anticipated.
                    If I was to easily add geometric patterns, I would need an entire toolset of operations.
                    Among those was path finding (Dijkstra and A* with down sampling and smoothing), a way to query nodes located in a certain area, tools to create unions, intersections and differences of node sets and of course, a set of geometric primitives that would be mapped onto the surface.
                    The irregular structure of the atrium made many operations surprisingly complex and on top of that, any operation I created was hard to debug with the limited heart models available to me.
                    I actually wrote a small tool to randomly transform and distort the heart model just to have a bigger test set.
                </p>
                <p>
                    One of my accomplishments during my research and development was an automatic <i>pulmonary vein isolation</i>.
                    Isolating the pulmonary veins as seen in the two videos below is a common therapy approach and is often combined with additional ablations.
                </p>
                <Ribbon color="primary">
                    <div className="row">
                        <div className="space-content col-xs-6"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/pvi-1.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/pvi-1-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pvi"} /></div>
                        <div className="space-content col-xs-6"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/pvi-2.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/pvi-2-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pvi"} /></div>
                    </div>
                    <div className="clearfloat space-content--negative" />
                </Ribbon>
                <p>
                    Using my toolkit of geometric primitives made creating ablation patterns such as the following a matter of a few lines of code (actually just one line in the first three cases).
                </p>
                <Ribbon color="primary">
                    <div className="row">
                        <div className="space-content col-xs-6 col-lg-4"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-1.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-1-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pattern"} /></div>
                        <div className="space-content col-xs-6 col-lg-4"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-2.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-2-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pattern"} /></div>
                        <div className="space-content col-xs-6 col-lg-4"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-3.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-3-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pattern"} /></div>
                        <div className="space-content col-xs-6 col-lg-4"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-4.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-4-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pattern"} /></div>
                        <div className="space-content col-xs-6 col-lg-4"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-5.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-5-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pattern"} /></div>
                        <div className="space-content col-xs-6 col-lg-4"><Video src={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-6.webm")} srcFallback={require("../../media/projects/hiwi-cardiac-ablation-strategies/ablation-pattern-6-firstframe.png")} scrollIn={true} scrollInDelayGroup={"ablation-pattern"} /></div>
                    </div>
                    <div className="clearfloat space-content--negative" />
                </Ribbon>
            </section>
            <section>
                <h2>Simulating on the bwUniCluster</h2>
                <p>
                    I might have forgotten to mention, that simulating just 10 seconds of this complex electrophysiological problem took around 15 minutes on my personal machine and the institutes computers were not much faster in that regard.
                    Therefore, setting up a way to remotely simulate in batches was essential.
                    Thankfully, I was given remote access to the <ExternalLink href="https://www.scc.kit.edu/en/services/bwUniCluster_2.0.php">bwUniCluster</ExternalLink>, a joint high-performance computer system of Baden-Württemberg's universities.
                </p>
                <p>
                    Setting up all dependencies (again) and installing openCARP along with my additions was frustrating at times, but in the end I got a decent pipeline up and running.
                    The pipeline allowed me to define a batch of simulations along with all parameters required and a list of ablation patterns to apply.
                    The server would then start openCARP and perform the simulations, making use of its massive parallelity.
                </p>
            </section>
            <section>
                <h2>Batch simulation and ablation pattern evaluation</h2>
                <p>
                    With all the tools I needed, I could finally focus on trying out the ablation patterns I had envisioned, investigate their impact and compare them to success rates of previous works.
                    I set up multiple big simulation badges with different fibrillation rotor locations as well as strategies to attempt to terminate them.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-rotor-positions.png"),
                        src_thumb : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-rotor-positions-540.png"),
                        src_thumb_preload : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-rotor-positions-32.png"),
                        alt : "Fibrillation rotors can have different origins on the atrial surface",
                        title : "All initial locations used for the fibrillations rotor."
                    },{
                        src : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-ablation-positions.png"),
                        src_thumb : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-ablation-positions-540.png"),
                        src_thumb_preload : require("../../media/projects/hiwi-cardiac-ablation-strategies/slide-ablation-positions-32.png"),
                        alt : "Therapies against atrial fibrillations often include pulmonary vein isolation and additional ablations",
                        title : "Some strategies combining pulmonary vein isolation with additional ablation patterns."
                    }
                ]} />
                <p>
                    In the end, I was happy with the results I was able to present.
                    I had created a multitude of promising ablation strategies and delivered an evaluation for each of them.
                    Additionally, the pattern generation and simulation tools will give future researchers a way to freely explore more possibilities and hopefully discover more ablation strategies!
                </p>
            </section>
        </>
    );
}

//################################################################################################################################################################

projectContents.projectGGJRem = function() {
    return (
        <>
            <section>
                <h2>Global Game Jam 2017</h2>
                <p>
                    The <ExternalLink href="https://globalgamejam.org/2017/games">Global Game Jam 2017</ExternalLink> was the first I ever attended and this year's topic was &lsquo;waves&rsquo;.
                    I didn't know much about how <SpecificTag name="gamejam" overwriteTitle="GameJams" showCount={true} /> work but the concept of focusing on one small project in a team sounded like a lot of fun.
                    A friend of mine and me got ourselves a huge team with other developers but also multiple art students.
                    At the time, the <ExternalLink href="https://unity.com/de">Unity game engine</ExternalLink> was the most obvious choice because most members of our team had at least worked a bit with it.
                </p>
                <p>
                    The game is quite artistic in nature, drawing visual inspiration from <ExternalLink href="https://en.wikipedia.org/wiki/Gustav_Klimt" type="wiki">Gustav Klimts</ExternalLink> work.
                    This was, of course only possible because of the art students who brought not only beautiful artwork but also crazy ideas for the gameplay and story.
                    It is also more of a proof-of-concept for using the <ExternalLink href="https://gaming.tobii.com/">Tobii eye tracker</ExternalLink> as a main input instead of the mouse!
                    The eye tracker looks like one of those Wii senor bar but is packed with cameras to detect and follow the movement of your eyes.
                    Unfortunately that means, that playing the game without the proper hardware does not yield the intended gameplay experience.
                </p>
                <Ribbon color="primary">
                    <div className="center--horizontal">
                        <Video
                            src={require("../../media/projects/ggj-2017-rem/owl-1.webm")}
                            srcFallback={require("../../media/projects/ggj-2017-rem/owl-1-firstframe.png")}
                            scrollIn={true}
                        />
                    </div>
                </Ribbon>
            </section>
            
            <section>
                <h2>Idea</h2>
                <p>
                    REM is an exploration game about dream waves, in which you guide a nameless protagonist through the different dream stages.
                    However, instead of using a controller, you use your eyes to navigate, solve puzzles, or fight off monsters.
                    The point of relief and restoration while we sleep begins when we enter the REM (rapid eye movement) phase.
                    While we sleep, we work through what we experienced during the day.
                    We create distorted images and realms of our own imagination.
                </p>
                <p>
                    You guide the character through the different stages of sleep to the final and relaxing REM phase.
                    It's a game about a dreamworld and the beauty it can offer.
                    At the same time you have to face the weird cruelties our subconsciousness is capable of creating.
                    Using your own eyes you interact and navigate through this dream and need to overcome your nightmares.
                </p>
                <ImgGallery cols={2} images={[
                    {
                        src : require("../../media/projects/ggj-2017-rem/screenshot-fish.png"),
                        src_thumb : require("../../media/projects/ggj-2017-rem/screenshot-fish-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2017-rem/screenshot-fish-32.png"),
                        alt : "The game character walking across the dreamworld",
                        title : "Your character walks across the dreamscapes when holding the space bar."
                    },{
                        src : require("../../media/projects/ggj-2017-rem/screenshot-owl.png"),
                        src_thumb : require("../../media/projects/ggj-2017-rem/screenshot-owl-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2017-rem/screenshot-owl-32.png"),
                        alt : "Some elements of the game are responsive to the look of your eye",
                        title : "There are many things to discover with your own eyes."
                    },{
                        src : require("../../media/projects/ggj-2017-rem/screenshot-train.png"),
                        src_thumb : require("../../media/projects/ggj-2017-rem/screenshot-train-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2017-rem/screenshot-train-32.png"),
                        alt : "Many self-made high quality assets found their way into the game",
                        title : "We have the art students of our team to thank for these wonderful assets!"
                    },{
                        src : require("../../media/projects/ggj-2017-rem/screenshot-tower.png"),
                        src_thumb : require("../../media/projects/ggj-2017-rem/screenshot-tower-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2017-rem/screenshot-tower-32.png"),
                        alt : "The player climbs a tower along a pre-defined path",
                        title : "Though the player can only decide to continue forward or stand still, the path is not always straight ahead!"
                    }
                ]} />
                <p>
                    Movement is straight-forward in this game: Your character walks as long as you hold space and stays still otherwise.
                    Most of the game is shrouded in fog and only when moving your eyes across the screen does the fog lift.
                    Additional events are triggered when looking at certain objects in the game.
                </p>
                <p>
                    We experimented with using the eye as means to interact with the game and while we inserted a hand-full of puzzles that make use of the mechanic, we found that misusing the eye as a mouse cursor was quite tedious for the player experience.
                    It is almost unbearable to keep looking at a button to wait for it to activate, for example.
                    Instead, we had to be clever about it.
                    My favorite puzzles are the one where you have to close your eyes to fall asleep at the beginning of the game and another one where you have to <i>not</i> look at a monster to pass.
                </p>
                <ImgGallery cols={2} images={[
                    {
                        src : require("../../media/projects/ggj-2017-rem/screenshot-climb.png"),
                        src_thumb : require("../../media/projects/ggj-2017-rem/screenshot-climb-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2017-rem/screenshot-climb-32.png"),
                        alt : "Walking along a Gustav Klimt bridge",
                        title : "Many of the assets take strong inspiration from Gustav Klimt."
                    },{
                        src : require("../../media/projects/ggj-2017-rem/screenshot-goddess.png"),
                        src_thumb : require("../../media/projects/ggj-2017-rem/screenshot-goddess-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2017-rem/screenshot-goddess-32.png"),
                        alt : "The objective of the game is a motherly, godlike woman in the style of The Kiss by Gustav Klimt",
                        title : "The games current objective is reaching this motherly goddess. You might find some resemblance to The Kiss by Gustav Klimt."
                    }
                ]} />
            </section>
            <section>
                <h2>The result</h2>
                <p>
                    As I would learn is often the case with game jams, we could not implement the sheer amount of great ideas.
                    I would have loved to deeper explore the topic of dreams and to create mysterious puzzles to solve in this dreamworld.
                </p>
                <p>
                    One idea we didn't get to was to switch artstyles in the different phases of the dream (a.k.a. levels).
                    So one playful dream would be inspired by <ExternalLink href="https://en.wikipedia.org/wiki/Gustav_Klimt" type="wiki">Gustav Klimt</ExternalLink>, a puzzling dream for of mysteries by <ExternalLink href="https://en.wikipedia.org/wiki/M._C._Escher" type="wiki">M. C. Escher</ExternalLink> or a scary nightmare could take place in the swirling chaos of <ExternalLink href="https://en.wikipedia.org/wiki/Vincent_van_Gogh" type="wiki">Vincent Van Goghs</ExternalLink> <i>Starry night</i>.
                </p>
                <Ribbon color="primary">
                    <div className="center--horizontal">
                        <Video
                            src={require("../../media/projects/ggj-2017-rem/owl-2.webm")}
                            srcFallback={require("../../media/projects/ggj-2017-rem/owl-2-firstframe.png")}
                            maxWidth="400px"
                            scrollIn={true}
                        />
                    </div>              
                </Ribbon>
            </section>
        </>
    );
};

//################################################################################################################################################################

projectContents.projectGGJHeadlinesPlease = function() {
    return (
        <>
            <section>
                <h2>Global Game Jam 2018</h2>
                <p>
                    The topic of the <ExternalLink href="https://globalgamejam.org/2018/games">Global Game Jam 2018</ExternalLink> was &lsquo;transmission&rsquo;.
                    I took part in the <SpecificTag name="gamejam" showCount={true} /> at a physical location at the <ExternalLink href="https://hfg-karlsruhe.de/">Hochschule für Gestaltung Karlsruhe</ExternalLink> which was organized by the <ExternalLink href="https://infoart.hfg-karlsruhe.de/gamelab/">GameLab Karlsruhe</ExternalLink>.
                    My team consisted of a good friend of mine from uni and another jammer we met there. He was particularly good with pixel art, which gave the game a unique feeling.
                </p>
                <p>
                    Game Jam topics are often deliberately vague, so we interpreted &lsquo;transmission&rsquo; as the distribution of news, information and opinion.
                    We used the <ExternalLink href="https://godotengine.org/">Godot Game Engine</ExternalLink> for development.
                </p>
            </section>
            <section>
                <h2>Putting together a headline</h2>
                <p>
                    In this game you play as a journalist. Your objective is to sort the flood of incoming intel into groups, and then select a fitting headline.
                    The game takes inspiration from <ExternalLink href="https://papersplea.se/">Papers, please</ExternalLink>.
                    Both games bombard you with information on a time limit while also forcing you to make morally gray decisions.
                </p>
                <p>
                    You get your intel from multiple sources in this game.
                    First, you get memos from fellow journalists. Secondly, your &lsquo;phax&rsquo;-machine constantly feeds more intel and thirdly, there is a &lsquo;Tweetr&rsquo; where just anyone can put their opinion on.
                    Almost anything in this game can be drag-and-dropped and your first goal is to sort all pieces of information onto separate sheets.
                </p>
                <p>
                    The more clues you put together, the more headline options become available.
                    After choosing one, the article is written and you have to send it via a vacuum tube (at the top left; usability testing has shown me that that was hard to find out).
                    Sometimes, a story can be told a lot of different ways, so keeping out for more insights is essential to find the right one... 
                </p>
                <ImgGallery cols={2} images={[
                    {
                        src : require("../../media/projects/ggj-2018-headlines-please/screenshot-0.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/screenshot-0-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/screenshot-0-32.png"),
                        alt : "Screenshot of the game showing the start of a level",
                        title : "You play at your desk, where you will find empty article drafts, a &lsquo;phax&rsquo;-machine and the &lsquo;Tweetr&rsquo;."
                    },{
                        src : require("../../media/projects/ggj-2018-headlines-please/screenshot-1.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/screenshot-1-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/screenshot-1-32.png"),
                        alt : "As a journalist, you have to drag-and-drop all pieces of information to sort them into stories",
                        title : "Your goal is to sort the pieces of information that come flying in from different sources into separate stories."
                    },{
                        src : require("../../media/projects/ggj-2018-headlines-please/screenshot-2.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/screenshot-2-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/screenshot-2-32.png"),
                        alt : "After collecting multiple sources for a story the play can select a headline for his article",
                        title : "The more insights you have gathered about a topic, the more headline options become available."
                    },{
                        src : require("../../media/projects/ggj-2018-headlines-please/screenshot-map.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/screenshot-map-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/screenshot-map-32.png"),
                        alt : "There are three countries in this games world, each with their different morals and ideology",
                        title : "Each of the three countries in the world has their own moral compass and ideology. Try finding the headline that fits the tone to gain more readers!"
                    }
                ]} />
            </section>
            <section>
                <h2>The world in your hand</h2>
                <p>
                    When your turn is over and your master pieces are published to the world, you get a rating.
                    There are three countries in this world, each with their respective ideology.
                    An articles might hit a nerve of one culture, but irritate the people of another.
                    Adjusting your writing style to fit the needs of a country is a valid strategy to get new readers - but is feeding the people what they want to hear really journalism? ;)
                </p>
                <p>
                    Over time, the stories that your company publishes reflect on the worlds countries.
                    Your articles change the perceived reality of the citizens and bit by bit, you can even alter their opinions.
                    Do you explain complex dilemmas in a neutral and scientific way, do you try to please your readers with all means possible or do you cash in on their fears?
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/ggj-2018-headlines-please/screenshot-rating-1.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/screenshot-rating-1-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/screenshot-rating-1-32.png"),
                        alt : "Each news story you publish is rated after the level.",
                        title : "Some headlines are almost sure to peak pique the readers interest and sell many copies. Bear in mind, that every headline slightly alters the peoples opinions!"
                    },{
                        src : require("../../media/projects/ggj-2018-headlines-please/screenshot-rating-2.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/screenshot-rating-2-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/screenshot-rating-2-32.png"),
                        alt : "You might gain or lose readers after every story you publish",
                        title : "Other headlines just might not land with your audience, reducing your returning readers."
                    }
                ]} />
            </section>
            <section>
                <h2>The news stories</h2>
                <p>
                    Writing engaging, fun and meaningful stories was challenging, given the time frame of 48 hours.
                    Each story needs a list of clues and which headlines they unlock.
                    The stories' headlines all have a certain political and ideological color.
                    For example, a report about an immigrant camp could promote both tolerance or racism depending on the formulation.
                    These values are secret to the user but used when rating how successful the headline was in each country.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/ggj-2018-headlines-please/concept-ideology.jpg"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/concept-ideology-1080.jpg"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/concept-ideology-32.jpg"),
                        alt : "Conceptualizing ideology categories and assigning news stories to them",
                        title : "Deciding on the ideological categories was hard enough, but we also had to find a multidue of news topics that would match them."
                    }
                ]} />
                <p>
                    There are 24 news stories in total. For some of these, certain conditions must be met.
                    A country might need a certain ideology or the relationship between two countries might need to be bad for certain events to trigger.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/ggj-2018-headlines-please/godot-1.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/godot-1-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/godot-1-32.png"),
                        alt : "The Godot Game Engine IDE showing the games main scene",
                        title : "The main scene of the game as shown in the Godot IDE. The left shows the scenes Nodes, the right shows the inspector where you can view and alter details of any Node."
                    },{
                        src : require("../../media/projects/ggj-2018-headlines-please/godot-2.png"),
                        src_thumb : require("../../media/projects/ggj-2018-headlines-please/godot-2-540.png"),
                        src_thumb_preload : require("../../media/projects/ggj-2018-headlines-please/godot-2-32.png"),
                        alt : "Textual definition of all news stories in the game in the Godot editor",
                        title : "Each news story is defined by a set of headlines and sources. All of them have ideological parameters associated with them. Some scenarios also have conditions that need to be met before they can be triggered in the game."
                    }
                ]} />
                <p>
                    Make sure you give try the game! Don't worry, if you can't keep up with the game in time, that's normal ;)
                    While possible in theory, playing the game on mobile is irritating and potentially a little buggy.
                </p>
            </section>
        </>
    )
};

//################################################################################################################################################################

projectContents.projectCNCBirdHouse = function() {
    return (<>
        <section>
            <h2>OpenSCAD</h2>
            <p>
                <ExternalLink href="https://openscad.org/">OpenSCAD</ExternalLink> is an open source CAD tool.
                Instead of the usual graphical CAD interfaces, this one only gives you a text input and a preview.
                The CADs are written in OpenSCADs own descriptive language with simple geometric shapes like cubes, spheres and cylinders.
                These primitives can be translated, transformed and rotated or combined to get their intersection or difference.
            </p>
            <p>
                The language includes many common programming paradigms like variables, methods, arrays, for loops and more.
                This <ExternalLink href="https://openscad.org/cheatsheet/">cheat sheet</ExternalLink> gives you an overview over the languages most important features, in case you're interested.
                In the example I created an example shape by removing a <code>cylinder</code> from a <code>cube</code> using <code>difference()</code>.
                The second example used the first example as a <code>module</code> to stack multiple on top of each other!
            </p>
            <p>
                One benefit of OpenSCAD are the ability to use constants for parameterization.
                When all distances, widths and thicknesses are put in separate variables, changing them later on will magically adjust the 3D model.
                I directly benefited from that feature when deciding on another wooden board last minute and all I had to do was change one variable.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/cnc-bird-house/openscad-difference.png"),
                    src_thumb : require("../../media/projects/cnc-bird-house/openscad-difference-540.png"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/openscad-difference-32.png"),
                    alt : "Creating simple 3D shapes with OpenSCAD",
                    title : "The geometric primitives (here: cube and cylinder) can be added to, removed from and intersected with each other."
                },{
                    src : require("../../media/projects/cnc-bird-house/openscad-modules.png"),
                    src_thumb : require("../../media/projects/cnc-bird-house/openscad-modules-540.png"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/openscad-modules-32.png"),
                    alt : "OpenSCAD supports both functions and for loops",
                    title : "Compositions can be combined to modules and reused as a single command and for loops allow calling these modules multiple times."
                }
            ]} />
        </section>
        <section>
            <h2>Creating the CAD birdhouse</h2>
            <p>
                The layout of the parts required for a birdhouse might be complex, but they can still be represented as intersections and unions of rotated and translated cubes.
                The walls, roof and floor are individual parts that I put into separate <code>modules</code>.
                The CNC milling machine only needs all the individual pieces next to each other like,
                but to make sure they would fit together seamlessly I created a 3D composition of the modules that shows the final result in birdhouse-form.
            </p>
            <ImgGallery cols={2} images={[
                {
                    src : require("../../media/projects/cnc-bird-house/cnc-full.png"),
                    src_thumb : require("../../media/projects/cnc-bird-house/cnc-full-540.png"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/cnc-full-32.png"),
                    alt : "Overview of CNC parts and the 3D preview",
                    title : "The pieces fit together like puzzle pieces."
                },{
                    src : require("../../media/projects/cnc-bird-house/cnc-components.png"),
                    src_thumb : require("../../media/projects/cnc-bird-house/cnc-components-540.png"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/cnc-components-32.png"),
                    alt : "The CADs for the wall pieces of the birdhouse",
                    title : "All the individual pieces are put next to each other to fit in the CNC machines workspace."
                },{
                    src : require("../../media/projects/cnc-bird-house/cnc-house.png"),
                    src_thumb : require("../../media/projects/cnc-bird-house/cnc-house-540.png"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/cnc-house-32.png"),
                    alt : "The 3D preview of the CAD pieces put together",
                    title : "There are holes in the front and back wall pieces for the bird seed to fall out."
                },{
                    src : require("../../media/projects/cnc-bird-house/cnc-house-split.png"),
                    src_thumb : require("../../media/projects/cnc-bird-house/cnc-house-split-540.png"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/cnc-house-split-32.png"),
                    alt : "3D preview of how the CAD pieces fit together",
                    title : "The house has two inner rooms that can be filled with bird seed from the top by taking of the roof."
                }
            ]} />
        </section>
        <section>
            <h2>Milling the pieces</h2>
            <p>
                CNC machines are amazing, save a lot of time and their precision is orders of magnitude better than anything I could hope to achieve by hand.
                Since the birdhouse parts are quite spacious I had to move and clamp the wood multiple times.
            </p>
            <p>
                The wooden board was quite thick, so the milling machine had to scan over the material multiple times.
                I was surprised by the amount of wooden chips and saw dust I had to vacuum during the milling process.
            </p>
            <ImgGallery cols={2} images={[
                {
                    src : require("../../media/projects/cnc-bird-house/milling-walls-1-1920.jpg"),
                    src_thumb : require("../../media/projects/cnc-bird-house/milling-walls-1-540.jpg"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/milling-walls-1-32.jpg"),
                    alt : "The wooden board clamped to the CNC mill",
                    title : "The working material must be clamped tightly to the machine and to make sure the material and the CAD align, the machine must be carefully calibrated."
                },{
                    src : require("../../media/projects/cnc-bird-house/milling-walls-2-1920.jpg"),
                    src_thumb : require("../../media/projects/cnc-bird-house/milling-walls-2-540.jpg"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/milling-walls-2-32.jpg"),
                    alt : "Saw dust from CNC milling",
                    title : "That's a lot of sawdust"
                },{
                    src : require("../../media/projects/cnc-bird-house/milling-walls-3-1920.jpg"),
                    src_thumb : require("../../media/projects/cnc-bird-house/milling-walls-3-540.jpg"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/milling-walls-3-32.jpg"),
                    alt : "The birdhouses wall pieces after being CNC milled",
                    title : "I was very happy about how neatly the wall pieces turned out."
                },{
                    src : require("../../media/projects/cnc-bird-house/milling-floor-1920.jpg"),
                    src_thumb : require("../../media/projects/cnc-bird-house/milling-floor-540.jpg"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/milling-floor-32.jpg"),
                    alt : "The birdhouses floor platform",
                    title : "The floor of the birdhouse was extra large and sturdy, so it took some time to mill."
                }
            ]} />
        </section>
        <section>
            <h2>Putting it all together</h2>
            <p>
                Unfortunately, I had to put in some manual work after all!
                The pieces needed some heavy sanding to look nice.
                Also, the wooden pins didn't quite fit like puzzle pieces, so they needed a lot of careful sanding as well.
            </p>
            <p>
                All that was left in the end were some colors and finish!
                After that, the birdhouse found its new place.
            </p>
            <p>
                The house has two inner compartments that can be filled with bird seed and a large platform so birds can comfortably sit.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/cnc-bird-house/birdhouse-result-1920.jpg"),
                    src_thumb : require("../../media/projects/cnc-bird-house/birdhouse-result-1080.jpg"),
                    src_thumb_preload : require("../../media/projects/cnc-bird-house/birdhouse-result-32.jpg"),
                    alt : "The complete birdhouse standing in the garden",
                    title : "The birdhouse at its new home out in the countryside. It is even visited by a large bird right now!"
                }
            ]} />
        </section>
    </>);
};

//################################################################################################################################################################

projectContents.projectBachelorThesis = function() {
    return (
        <>
            <section>
                <h2>Topic</h2>
                
                <p>
                    Ever since high school, I have had a strong aversion for biology, anatomy and medicine.
                    I was always fascinated both of natures complexity and the highly interdisciplinary knowledge required to even begin to understand it.
                    Choosing <SpecificTag overwriteTitle="Biomedical Technology" name="biomed-technology" showCount={true} /> as my minor in <SpecificTag overwriteTitle="university" name="uni" showCount={true} /> was an obvious choice that I definitely don't regret despite the challenges.
                </p>
                <p>
                    I was given a unique opportunity to write my bachelor thesis at a non-computer-science institute.
                    It took some time and nerve to even get it registered, since both faculties had to be satisfied with the works contents.
                    Since the <ExternalLink href="https://ibt.kit.edu/">Institute for Biomedical Technology (IBT)</ExternalLink> is part of the electrical engineering faculty, I had to learn a lot about topics I had never heard of just to understand the fundamentals!
                </p>
                <p>
                    Central topic of the work are <ExternalLink href="https://en.wikipedia.org/wiki/Stroke#Ischemic" type="wiki">ischemic strokes</ExternalLink>, meaning blood clots that (partially) block cerebral blood flow.
                    Ischemic strokes are the third leading cause of death in western countries, killing 6.7 million people in 2014.
                    New therapeutic approaches propose selective cooling of certain arteries to lower the metabolic rate and protect under supplied tissue.
                    Selective cooling would require detailed understanding of the cerebral blood flows spatial and temporal distribution.
                    To better our understanding of the blood flow in the intricate brain vessels, the IBT has developed a model in Matlab and the block diagram simulation tool <ExternalLink href="https://www.mathworks.com/products/simulink.html">Simulink</ExternalLink>.
                </p>
                <p>
                    My role in all this was:
                </p>
                <ul className="ul--text">
                    <li>to improve parameterization of the existing simulation</li>
                    <li>to research relevant parameters and integrate them</li>
                    <li>to analyze the sensitivity and significance of the parameters</li>
                    <li>to develop a simulation pipeline that runs on multiple servers</li>
                    <li>to design a UI for simulating and exploring the results</li>
                    <li>to investigate interplay of parameters and genetic variations</li>
                </ul>
                <p>
                    Working at the IBT was quite pleasant.
                    Many dedicated students, PhD students and research assistants were working in one place each with incredible research topics.
                    Meetings were held frequently to present progress, but having a plenum of experts is an excellent way to get ideas and advice on how to continue your research.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-overview.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-overview-1080.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-overview-32.png"),
                        alt : "Goal of the thesis",
                        title : "The steps and goals of the work as specified with my supervisor."
                    }
                ]} />
            </section>
            <section>
                <h2>Anatomy</h2>
                <p>
                    The main entry points for cerebral blood flow are the two carotid arteries and the vertebral artery.
                    From there, six major cerebral arteries distribute the blood further down the fine capillaries.
                    Interestingly, the three entry points and the cerebral arteries are connected to a circular artery system, called the <b>Circle of Willis</b>.
                    This unique circular structure effectively offers redundancy.
                    In fact, it can partially redirect blood flow even if one of the carotid arteries is severely blocked.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-anatomy.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-anatomy-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-anatomy-32.png"),
                        alt : "The Circle of Willis",
                        title : "The carotid arteries (center) and the vertebral artery (bottom) forming a circular structure with the six cerebral arteries."
                    },
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-graph.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-graph-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-graph-32.png"),
                        alt : "Topology of blood vessels",
                        title : "Topology of a historic model of the most crucial arteries of the entire body (left) and of this model focussing on cerebral arteries only."
                    },
                ]} />
                <p>
                    The physiology of all the vessels is highly patient-specific, so vessel length, diameter and elasticity are never the same from brain to brain.
                    Many factors are known that temporarily or permanently alter vessel or blood properties.
                    Doing sports can temporarily increase blood pressure and vessel diameter just as smoking permanently harms vessels. 
                    To top it off, the specific anatomy of the relevant arteries is subject to genetic differences and mutations!
                    So some people might have more or less bifurcations at certain arteries or some people might not even have parts of the vessels forming the Circle of Willis!
                </p>
                <p>
                    The vessel connectivity and graph geometry of the brain must be known in great detail for any simulation and was thankfully collected by scientist in the past.
                </p>
            </section>
            <section>
                <h2>The transmission line approach</h2>
                <p>
                    Calculating flow in elastic vessels of complex geometry is actually quite hard to pull off, so the IBT uses an analogue instead.
                    Instead of calculating fluid dynamics of blood through elastic tubes, a short section of an electrical diagram with an inductance, resistance and capacity is modeled and calculated!
                    This idea is called <b>Transmission Line Approach</b> and significantly facilitates the calculation.
                    It does a few approaches like laminar flow (which is quite the simplification!), but it can be adjusted for vessel elasticity, blood viscosity and all geometric properties.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-transmission-line.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-transmission-line-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-transmission-line-32.png"),
                        alt : "Transmission line approach of a short vessel segment",
                        title : "The transmission line approach assumes laminar flow in long, elastic tubes and uses an electrical analogue using resistance (R), inductance (L) and capacity (C)."
                    },
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-transmission-line-methods.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-transmission-line-methods-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-transmission-line-methods-32.png"),
                        alt : "Additions to the transmission line approach",
                        title : "The vessel segments can be stitched together to form chains or bifurcations. The capillaries, stenoses and collateral arteries are modeled using custom components."
                    },
                ]} />
                <p>
                    These segments must be cleverly merged to create chains or bifurcation.
                    Stenoses become high resistors between segments, and the fine capillaries that are not explicitly modeled become terminal resistances.
                </p>
            </section>
            <section>
                <h2>The model</h2>
                <p>
                    The geometry of the vessels was modeled as a graph with edges corresponding to a vessel segments.
                    The vessel segments are implemented as Simulink blocks - small fragments of C code that can be drag-and-dropped and connected to other blocks.
                    In our case, these blocks correspond to one vessel segment and house the equations for the transmission line approach.
                    Effectively, the Simulink simulation continuously recalculates a series of differential equations in small time steps.
                    Simulink is even able to execute these blocks in parallel to speed things up!
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-variations.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-variations-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-variations-32.png"),
                        alt : "Genetic variations of the arteries forming the Circle of Willis",
                        title : "There are many known genetic variations of the arteries forming the Circle of Willis. The thin <i>communicating arteries</i> are often underdeveloped or even completely absent."
                    },
                    {
                        src : require("../../media/projects/bachelor-thesis/simulink.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/simulink-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/simulink-32.png"),
                        alt : "Simulink blocks",
                        title : "Screenshot showing a small part of the Simulink model. The yellow boxes are vessel segments, the green and blue boxes represent capillaries."
                    },
                ]} />
                <p>
                    Of course, any input parameters effects must be integrated and customizable during initialization, including topology variations of the Circle of Willis.
                    The initialization is done via Matlab, which passes all the simulation parameters to Simulink.
                    When the desired number of seconds has been simulated, Matlab code then retrieves and saves the results.
                </p>
            </section>
            <section>
                <h2>Simulation and parameterization</h2>
                <p>
                    After a literature research, I created a set of seven parameters to integrate and investigate.
                    I changed the Matlab initialization quite a bit to allow for generic parameter additions and made sure to introduce some good programming patterns to improve the code quality.
                    In total, there were two parameters for topological variations, two for possible stenosis locations, three for vessel geometry properties.
                    While changing some properties was a matter of scaling, the topological variants and the stenosis locations required the connectivity of the Simulink model to be modifiable during initialization, which proved difficult to pull off.
                </p>
                <p>
                    Knowing I was going to simulate many different scenarios I wanted a way to streamline simulations instead of manually start a simulation every couple minutes.
                    The IBT also gave me access to their on-premise cluster servers where I could execute multiple simulations at the same time!
                    The result I came up with was a kind of simulation database in Matlab.
                    It allows querying a simulation using a specific input parameter set and checks if this specific simulation has been performed already.
                    If yes, the results are retrieved, if no, the simulation along with its input data is distributed to one of the cluster servers and calculated remotely.
                    I even managed to get some kind of asynchronous response in Matlab when the simulation is done and the results are ready.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-parameters.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-parameters-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-parameters-32.png"),
                        alt : "List of simulation input parameters",
                        title : "The complete list of input parameters and their discrete values, adding up to a total of 19008 simulations."
                    },
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-parameterization.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-parameterization-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-parameterization-32.png"),
                        alt : "Parameterizing the transmission line approach",
                        title : "Outline of the Matlab initialization and Simulink simulation. Input parameters are integrated at different points of time (pre-analogue and post-analogue)."
                    },
                ]} />
                <p>
                    This setup worked really well, allowing me to simulate any time of the day and without putting workload on the computer I was working on.
                    Also, managing multi-parameter simulation results by hand was tedious and prone to mistakes.
                </p>
            </section>
            
            <section className="space-content">
                <h2>GUI development</h2>
                <p>
                    Apart from the basics, Matlab was quite new to me before I started working on my thesis.
                    I was surprised by the possibilities to create graphs of any type and with that much customizability through code.
                    Apart from plots, any normal input element (button, select box, ...) can also be added to create complex UIs.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/bachelor-thesis/gui-1.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/gui-1-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/gui-1-32.png"),
                        alt : "Matlab interface screenshot",
                        title : "Main screen of the Interface. All parameters can be set and a preview of the Circle of Willis. If the simulation has already been performed, the results are shown."
                    },
                    {
                        src : require("../../media/projects/bachelor-thesis/slide-stenoses.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/slide-stenoses-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/slide-stenoses-32.png"),
                        alt : "Frequently occurring ischemic stroke locations",
                        title : "My literature research showed that most ischemic strokes are localized either in on of the two carotid arteries or one of two the middle cerebral arteries."
                    }
                ]} />
                <p>
                    I was in need of an environment to visualize and compare simulation results in meaningful ways.
                    As a first overview, I created a graphic representation of the Circle of Willis that showed certain vessels mean blood flow and any topological variations or stenoses present.
                    The flow over time for each vessel can then be investigated and compared.
                    To save some time later on, I even added &lsquo;Save PNG&rsquo; and &lsquo;Save EPS&rsquo; so I could directly export any plots and directly insert into the thesis itself.
                </p>
                <p>
                    Luckily, I was able to integrate the simulation pipeline into the UI, so any previous simulation results are instantly available and single simulations can be performed remotely with one click of a button.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/bachelor-thesis/gui-2.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/gui-2-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/gui-2-32.png"),
                        alt : "Matlab UI with blood flow over time through the cerebral arteries",
                        title : "A tool to investigate the flow over time for all arteries. Just clicking the checkbox shows the corresponding curve."
                    },
                    {
                        src : require("../../media/projects/bachelor-thesis/gui-3.png"),
                        src_thumb : require("../../media/projects/bachelor-thesis/gui-3-540.png"),
                        src_thumb_preload : require("../../media/projects/bachelor-thesis/gui-3-32.png"),
                        alt : "Matlab UI comparing the influence of input parameters",
                        title : "Another tool to compare the influence and interplay of two parameters."
                    },
                ]} />
            </section>
            <section>
                <h2>Data analysis</h2>
                <p>
                    Having created all the tools to simulate, explore and visualize, it was finally time to use them.
                    I looked at scenarios with critical stenoses and investigated how the other parameters would influence the severity.
                    For example, I was able to quantify the effect that slight changes in certain vessels' diameter have on their ability to compensate stenoses.
                    Some combinations of stenoses and genetic variations of the Circle of Willis proved to be more severe that others. 
                    The &lsquo;big simulation&rsquo; with parameter combinations adding up to 19008 simulations took a while to run even on the institutes servers, but in the end I was able to freely explore and compare.
                </p>
                <p>
                    With that many simulation results, it's not surprising that the appending of my written <SpecificTag name="latex" showCount={true} /> thesis exceeds 30 pages!
                </p>
                <p>
                    Contributing to research through simulations but also having improved the tools of the IBT they might still be using felt great.
                    I also remember the feeling of not being able to just google something, because the topic is being researched just now and the questions just don't have any answers yet!
                    I am glad to have made the decision to write my thesis in an academic area I was not that used to.
                    Though I was often stuck because I was missing basics in electrical engineering, I can proudly say that I learned a lot about it, but also about anatomy, differential equations, Matlab and simulations with Simulink.
                </p>
            </section>
        </>
    );
};

//################################################################################################################################################################

projectContents.projectHaDiKoHeimfest2023 = function() {
    return (
        <>
            <section>
                <h2>About the HaDiKo</h2>
                <p>
                    The <ExternalLink href="https://hadiko.de">HaDiKo</ExternalLink> is Germany's largest self-administrated student dormitory, offering a whopping 1102 rooms.
                    But what makes it so special is not its size, but the self-administration part.
                    The dormitory has its own politics along with elected politicians, boards and meetings.
                    Each of the six buildings acts as a federal state with its own boards and meetings.
                    Just looking at the <ExternalLink href="https://www.hadiko.de/wohnheim/selbstverwaltung">self-administration</ExternalLink> organisational chart will give you an idea.
                    Even the procedures for moving in and out as well as the room allocation is done by students!
                    But why all this? Well, it means the students take more responsibility but gain a lot of freedom.
                </p>
                <p>
                    For example, there are &lsquo;working groups&rsquo;, a group of people with a common hobby that gets a room and finances to buy equipment.
                    Which working group is founded and whether it gets its requested funds is decided by the politicians.
                    There is a long list of active working groups, including:
                    a wood workshop, a metal workshop, an electrical laboratory, but also a music room, a training room, a photo laboratory and many more.
                    <br />
                    There are also many services, that a &lsquo;minister&rsquo; manages and lends out to others.
                    For example: a VR room with equipment, a laminator, a billard room, projectors, a sewing machine and even a sauna.
                </p>
                <p>
                    All of these possibilities and offers are something a regular uni- or privately owned dormitory would never have.
                    By the way, almost all of this commitment is done voluntary with no money involved!
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-19-47-05-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-19-47-05-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-19-47-05-32.jpg"),
                        alt : "The grounds during the day",
                        title : "The Heimfest in the first few hours."
                    },
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-20-28-46-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-20-28-46-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-20-28-46-32.jpg"),
                        alt : "The stage of the Heimfest during daytime",
                        title : "Some of my friends came over to visit."
                    },{
                        src : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-21-49-47-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-21-49-47-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-21-49-47-32.jpg"),
                        alt : "The stage by night",
                        title : "The stage in the last few hours of the Heimfest."
                    }
                ]} />
            </section>

            <section>
                <h2>The Heimfest</h2>
                <p>
                    The HaDiKo Heimfest is a yearly event where all the self-administration culminates into an anniversary celebration.
                    Most of the times it's an outdoor concert right on the dormitory grounds, organized by a team of around 15 people and many more helpers.
                    We set up multiple food and beverage stands and this year we had a stage and four bands.
                    The Heimfest is also publicly registered, meaning it must be quite professional to pass hygiene- and safety-regulations.
                </p>
                <p>
                    The &lsquo;orga&rsquo; starts its work many months before the Heimfest usually, because there is a lot to consider and discuss.
                    Every person in the orga has a dedicated role like finances, renting material, publicity, sponsoring, music and band contact, light technician, sound technician, stand manager for each stand and catering.
                    The setup before, and the cleaning up after are supported by many voluntary helpers.
                    Same goes for the many shifts that need to be filled at the stands and in the security team.
                </p>
                <p>
                    My role this Heimfest was publicity, <SpecificTag name="design" showCount={true} />, <SpecificTag name="print" showCount={true} /> and sponsoring.
                    So my goal was to develop a unique brand identity, create promotional material in both print and digital and make sure as many people as possible got wind of the Heimfest.
                    Additionally, I wanted to use that same design for all the signage that we put up at the Heimfest itself.
                </p>
            </section>

            <section>
                <h2>Generating the background</h2>
                <p>
                    Similar to <Link to="/project/HaDiKo-Heimfest-2022">last years Heimfest</Link> I decided on an algorithmically generated background based on simple shapes.
                    After some creative trial-and-error I settled on circles, that change colors based on how many circle overlaps there are.
                    It's kind of hard to explain, so just take a look:
                </p>
                <Ribbon color="primary">
                    <div className="center--horizontal">
                        <Video
                            src={require("../../media/projects/hadiko-heimfest-2023/circle-shader.webm")}
                            srcFallback={require("../../media/projects/hadiko-heimfest-2023/circle-shader-firstframe.png")}
                            maxWidth="400px"
                            scrollIn={true}
                        />
                    </div>                    
                </Ribbon>
                <p>
                    You can also think of the algorithm like counting overlapping circles <i>per pixel</i>.
                    Since I was planning on creating high-resolution images for print and even videos with many frames, I realized quickly that I don't want to burden my CPU with such a tedious task.
                    Also, experimenting with parameters would be no fun if I had to wait seconds to minutes every time.
                </p>
                <p>
                    So what do you do if your CPU can't handle your graphical task? Just use the GPU instead!
                    A shader is actually the perfect fit for most &lsquo;per-pixel tasks&rsquo;, so that's what I decided on.
                    Instead of creating a C++ or C# project and setting up an openGL context I fired up the <ExternalLink href="https://www.godotengine.org/">Godot game engine</ExternalLink> to get started right away.
                    I setup a scene that allowed me to render a shader on any resolution and to parameterize and randomly generate the circle positions for the pattern.
                    The shader itself is written in the <ExternalLink href="https://docs.godotengine.org/en/stable/tutorials/shaders/shader_reference/shading_language.html">Godot shading language</ExternalLink> which is very similar to the <ExternalLink href="https://registry.khronos.org/OpenGL/specs/es/3.0/GLSL_ES_Specification_3.00.pdf">OpenGL shading language</ExternalLink>.
                    After passing the (potentially thousands) of circle positions to shader, the shader was relatively straight-forward:
                </p>
                <Code language="glsl" languageIndicator="Godot shading language" content={`shader_type canvas_item;

// constants
const int MAX_CIRCLES = 1024;
const int EMPTY = -1337; // arbitrary end signal

// circle positions and other uniforms
uniform int[MAX_CIRCLES] pos_x;
uniform int[MAX_CIRCLES] pos_y;
uniform int[MAX_CIRCLES] radii_sq;
uniform ivec2 pixel_size;
uniform int color_offset = 0;

// color palette
const int NUM_COLS = 7;
const vec4[] COLORS = {
    vec4(0.98431372642517, 0.96078431606293, 0.84313726425171, 1.0),
    vec4(0.96862745285034, 0.57254904508591, 0.32156863808632, 1.0),
    vec4(0.97647058963776, 0.5686274766922, 0.3137255012989, 1.0),
    vec4(0.84705883264542, 0.14901961386204, 0.37647059559822, 1.0),
    vec4(0.3098039329052, 0.1294117718935, 0.36862745881081, 1.0),
    vec4(0.19215686619282, 0.37254902720451, 0.58039218187332, 1.0),
    vec4(0.19215686619282, 0.73333334922791, 0.68627452850342, 1.0)
};

// fragment shader
void fragment() {
    ivec2 pos = ivec2(vec2(pixel_size) * UV);
    
    // iterate circles and count overlaps
    int count = 0;
    for (int i = 0; i < MAX_CIRCLES; i++) {
        // abort if end signal found
        if (pos_x[i] == EMPTY) {
            break;
        }
        // calc distance and check if in circle
        ivec2 circle_pos = ivec2(pos_x[i], pos_y[i]);
        int dx = pos.x - circle_pos.x;
        int dy = pos.y - circle_pos.y;
        if (dx * dx + dy * dy < radii_sq[i]) {
            count += 1;
        }
    }
    
    // set output color according to count and color palette
    int count_mod = (count + color_offset) % NUM_COLS;
    COLOR = COLORS[count_mod];
}`} />

                <p>
                    With this tool in my bag, generating backgrounds according to my needs during the marketing campaign was, as I had hoped, a breeze.
                    Here are a couple example patterns:
                </p>

                <ImgGallery cols={3} images={[
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/pattern_0-1920.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/pattern_0-540.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/pattern_0-32.png"),
                        alt : "Generated circle pattern 1",
                        title : "This one somewhat resembles the pattern used for the poster."
                    },{
                        src : require("../../media/projects/hadiko-heimfest-2023/pattern_1-1920.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/pattern_1-540.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/pattern_1-32.png"),
                        alt : "Generated circle pattern 2",
                        title : "The circle positions can also be on a regular grid."
                    },{
                        src : require("../../media/projects/hadiko-heimfest-2023/pattern_2-1920.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/pattern_2-540.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/pattern_2-32.png"),
                        alt : "Generated circle pattern 3",
                        title : "By messing with the total number of circles and their radii, the average number of circle overlaps and hence general color vibe can differ greatly."
                    },{
                        src : require("../../media/projects/hadiko-heimfest-2023/pattern_3-1920.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/pattern_3-540.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/pattern_3-32.png"),
                        alt : "Generated circle pattern 4",
                        title : "Fine-tuning the parameters sometimes lead to interesting results."
                    },{
                        src : require("../../media/projects/hadiko-heimfest-2023/pattern_4-1920.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/pattern_4-540.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/pattern_4-32.png"),
                        alt : "Generated circle pattern 5",
                        title : "Similar to the last but with greater radii."
                    },{
                        src : require("../../media/projects/hadiko-heimfest-2023/pattern_5-1920.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/pattern_5-540.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/pattern_5-32.png"),
                        alt : "Generated circle pattern 6",
                        title : "This one is almost on a regular grid but has slight imprecisions."
                    }
                ]} />
                <p>
                    By changing the circles positions and radii each frame and updating the shaders uniforms, the tool allows for creating videos as well.
                    I just had to make sure to output a video frame by frame with a constant frame rate to prevent stuttering.
                    We actually used these hallucination-inducing videos for save-the-date invitations on messengers.
                </p>
                <p>
                    The dormitory has a couple screens installed in entrance areas to advertise for services and upcoming events.
                    Bright colorful moving circles proved quite effective on these screens and were yet another way for me to advertise.
                </p>
                <Ribbon color="primary">
                    <div className="center--horizontal">
                        <Video
                            src={require("../../media/projects/hadiko-heimfest-2023/headspace.webm")}
                            srcFallback={require("../../media/projects/hadiko-heimfest-2023/headspace-firstframe.png")}
                            maxWidth="600px"
                            scrollIn={true}
                        />
                    </div>                    
                </Ribbon>
            </section>

            <section>
                <h2>Creating the promotional material</h2>
                <p>
                    The background pattern and its color palette have a strong character, making it recognizable.
                    At the same time, the high contrast and &lsquo;noise&rsquo; make placing text or image directly on the pattern virtually impossible, making it hard to work with.
                    To create a meaningful and useful design I had to find visual elements that would increase the contrast.
                </p>
                <p>
                    White boxes with negative text or white borders are the obvious choice,
                    but what really gave the design that special something was the purple-tinted blur:
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/collage-material.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/collage-material-1080.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/collage-material-32.png"),
                        alt : "Promotional material, tickets and badges",
                        title : "Overview of promotional print material."
                    }
                ]} />
                <p>
                    Creating the poster took the most time, since it was a highly iterative process and I tried including the orga's ideas and feedback.
                    With the poster as a base for the design, creating the rest of the material was a matter of copy-and-paste and adjust.
                </p>
                <p>
                    When the ordered material finally arrived, I was quite happy with the result!
                    Getting myself some helpers and gearing up with glue and brushes, we started our journey across Karlsruhe to put up:
                </p>
                <ul className="ul--text">
                    <li>75 DIN A2 posters</li>
                    <li>25 DIN A3 posters</li>
                    <li>1 banner on the uni canteen</li>
                </ul>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-0-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-0-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-0-32.jpg"),
                        alt : "Posters just after they arrived",
                        title : "The long-awaited arrival of 75 A2 posters and 25 A3 posters."
                    }, {
                        src : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-1-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-1-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-1-32.jpg"),
                        alt : "The banner just after it arrived",
                        title : "Three times one meter is larger than I thought! This banner was put up above the uni canteen."
                    }, {
                        src : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-2-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-2-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-2-32.jpg"),
                        alt : "Transporting the posters in a wagon",
                        title : "Putting up the posters is more fun as a group, so we set out into the city with everything we needed in a wagon."
                    }, {
                        src : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-3-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-3-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/putting-up-posters-3-32.jpg"),
                        alt : "Putting up a poster",
                        title : "Advertising on the uni campus was especially important..."
                    }
                ]} />
                <p>
                    We made sure to distribute posters at the multiple universities, in other student dormitories and places that students frequent.
                    The trips were actually fun, especially when taking the right people along!
                    Getting the permissions to put up the posters though was tedious, but worthwhile.
                </p>
                <p>
                    Just a week before the Heimfest I also advertised on the university's <ExternalLink href="https://www.reddit.com/r/KaIT/">subreddit</ExternalLink>.
                    Actually, it was stealth marketing. You see, we managed to get the [kœri]werk on board the Heimfest, which usually serves curry wurst in the university's canteen.
                    Besides selling good curry wurst and having a great marketing, they are generally very popular and have the highest meme value on the subreddit
                    (in fact, as of writing this, [kœri]werk is emblazoned on the cover photo of what's supposed to be the university's subreddit).
                    So in this <ExternalLink href="https://www.reddit.com/r/KaIT/comments/138na5y/wie_man_kann_das_k%C5%93riwerk_auch_f%C3%BCr_feste_mieten/">reddit post</ExternalLink> I just happened to mention that the [kœri]werk would be selling at the Heimfest ;)
                </p>
            </section>

            <section>
                <h2>Signposting the Heimfest </h2>
                <p>
                    Quite an important task I barely mentioned so far is the signage we put up during the Heimfest.
                    It would show prices, direct the way towards the exit or the toilets and give the guest information in general.
                    In total, I created 37 different signs.
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/collage-signs.png"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/collage-signs-1080.png"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/collage-signs-32.png"),
                        alt : "Signage for during the Heimfest",
                        title : "Compilation of some of the signs including price lists, rules, information, directions and more."
                    }
                ]} />
                <p>
                    Collecting the details to put on the signs required strong co-operation with the rest of the orga:
                    Which objects are illegal to bring onto the grounds? Ask the security.
                    Do the guests pay first and are their bags controlled after or the way? Ask the rest of the orga.
                    Is there deposit for the plates? Ask the food stand manager.
                </p>
                <p>
                    Also, information often changes last-second, making communication even more important.
                </p>
            </section>

            <section>
                <h2>Summary</h2>
                <p>
                    Did I mention the Heimfest was a complete success?
                    During the first hours, people had food, met up and talked. By the end they (and me) were mosh pitting to our headliners before the stage illuminated in (semi) professional lighting.
                    The Highest guest count by the security was at 1600, the cumulative count is estimated at 2000.
                    All the finances are calculated to make no profit, but we made some profit anyways (which will find its way to the self-administration)!
                </p>
                <ImgGallery images={[
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-30-38-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-30-38-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-30-38-32.jpg"),
                        alt : "The headliner of the Heimfest 2023: The Big Bs",
                        title : "The forth act was this Heimfests headliner: The Big Bs."
                    },
                    {
                        src : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-46-27-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-46-27-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-46-27-32.jpg"),
                        alt : "Professional lighting of the stage",
                        title : "The light technicians really pulled through and put together a professional lighting show."
                    },{
                        src : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-46-53-1920.jpg"),
                        src_thumb : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-46-53-540.jpg"),
                        src_thumb_preload : require("../../media/projects/hadiko-heimfest-2023/2023-05-20-Heimfest-22-46-53-32.jpg"),
                        alt : "The atmosphere in front of the stage was electric",
                        title : "The atmosphere in front of the stage was perfect by the last act. Some people would even mosh-pit!"
                    }
                ]} />
                <p>
                    Looking back at my work there, I can't help myself for feeling a little pride to have brought more than enough guests onto the grounds.
                    It's incredible that we as a team put up this professional concert in our free time.
                    I'm also quite happy to know a lot of people would be able to instantly recognize the design and associate it with the Heimfest!
                </p>
            </section>
        </>
    );
};

//################################################################################################################################################################

projectContents.projectIntergalacticBilliard = function() {
    return (
    <>
        <section>
            <h2>The assignments</h2>
            <p>
                Hearing of an advanced rendering course at Uni that would allow me to develop a game in a team of two, I didn't have to think long before convincing a friend and registering to the course.
                The requirements included using <SpecificTag name="c%2B%2B" showCount={true} />, the rendering API <SpecificTag name="opengl" showCount={true} /> and advanced rendering techniques we had learned in previous lectures.
                And, as it is often the case with uni assignments, usage of any frameworks was forbidden, meaning we got to create a simple 3D game engine from scratch.
                Additional to the programming task, four reports had to be submitted and two presentations had to be held to pass the course.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/intergalactic-billiard/stellar-system.png"),
                    src_thumb : require("../../media/projects/intergalactic-billiard/stellar-system.png"),
                    src_thumb_preload : require("../../media/projects/intergalactic-billiard/stellar-system-32.png"),
                    alt : "A generated stellar system with a sun, planets and a black hole in the background",
                    title : "Each stellar system has a central sun and generated planets orbiting around it. Some might even have asteroid belts."
                }
            ]} />
        </section>

        <section>
            <h2>Gameplay idea</h2>
            <p>
                In this game, you play as <ExternalLink href="https://lovecraft.fandom.com/wiki/Azathoth">Azathoth</ExternalLink>, an omnipotent being from the works by <ExternalLink href="https://lovecraft.fandom.com/wiki/H._P._Lovecraft">H. P. Lovecraft</ExternalLink> (and yes, of course there is a dedicated community that created a fandom wiki).
                The games objective is easily explained: knock planets around to cause as much chaos in the universe as possible.
                <br />
                The playing field is nothing shorter than a galaxy with a black hole in the center, stellar systems orbiting the black hole and planets orbiting the suns.
            </p>
        </section>

        <section>
            <h2>Notable features</h2>
            <p>
                Since our submission was graded, we went all out to show what we could do:
            </p>
            <ul className="ul--text">
                <li>Hierarchical SceneTree with affine transformations</li>
                <li>Basic GameObject that allows for custom meshes and shaders</li>
                <li>Shading using the <ExternalLink href="https://en.wikipedia.org/wiki/Phong_reflection_model" type="wiki">Phong reflection model</ExternalLink> and many light sources</li>
                <li>Procedural generation of planets and suns using <ExternalLink href="https://en.wikipedia.org/wiki/OpenSimplex_noise" type="wiki">OpenSimplex noise</ExternalLink></li>
                <li>Galaxy generation</li>
                <li>Fully functional orbital physics, gravity calculation and collision handling</li>
                <li>Adjusting mesh and shading quality dynamically</li>
                <li>Particle system using shaders</li>
                <li>Cube map as the sky box (or <i>universe box</i>, as we called it)</li>
                <li>Controllable camera with multiple projection modes</li>
                <li>A scary black hole in the center of it all</li>
            </ul>
        </section>

        <section className="space-content">
            <h2>Planet generation</h2>
            <p>
                As a base we created a spherical mesh using the <i>normalized cube</i> approach.
                In contrast to the usual approach (splitting the mesh along latitudinal and longitudinal lines), this gave us a way to create multiple meshes with different resolutions and exchange them dynamically according to our level-of-detail needs.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../media/projects/intergalactic-billiard/mesh-generation.png"),
                    src_thumb : require("../../media/projects/intergalactic-billiard/mesh-generation.png"),
                    src_thumb_preload : require("../../media/projects/intergalactic-billiard/mesh-generation-32.png"),
                    alt : "",
                    title : "Outlining sphere generation using a normalized cube at different resolutions."
                },
                {
                    src : require("../../media/projects/intergalactic-billiard/planet-generation-1.png"),
                    src_thumb : require("../../media/projects/intergalactic-billiard/planet-generation-1.png"),
                    src_thumb_preload : require("../../media/projects/intergalactic-billiard/planet-generation-1-32.png"),
                    alt : "Using OpenSimplex noise to add a heightmap to planets",
                    title : "Adding OpenSimplex noise to the planets' height map creates these bumpy rocks with different parameter options."
                },{
                    src : require("../../media/projects/intergalactic-billiard/planet-generation-2.png"),
                    src_thumb : require("../../media/projects/intergalactic-billiard/planet-generation-2.png"),
                    src_thumb_preload : require("../../media/projects/intergalactic-billiard/planet-generation-2-32.png"),
                    alt : "Different generated planets with seas.",
                    title : "Adding water below a certain threshold gets you oceans."
                },{
                    src : require("../../media/projects/intergalactic-billiard/planet-generation-3.png"),
                    src_thumb : require("../../media/projects/intergalactic-billiard/planet-generation-3.png"),
                    src_thumb_preload : require("../../media/projects/intergalactic-billiard/planet-generation-3-32.png"),
                    alt : "Texturizing the generated planet based on OpenSimplex noise",
                    title : "The planets can then be colored based on height and slope."
                }
            ]} />
            <p>
                Next came the fun part! Using the <ExternalLink href="https://en.wikipedia.org/wiki/OpenSimplex_noise" type="wiki">OpenSimplex noise function</ExternalLink> as a height map we generated the planets' terrain.
                Texturing the Planet turned out to be surprisingly simple. Basically, every planet has a color gradient associated to it, that is used to look up the color depending on the height.
                To improve on realism, we added oceans with specular reflections and polar caps.
                Of course, a galaxy needs a great deal of variety to look convincing, so we had to make sure to make the planet generation highly parameterizable and randomized the parameters.
            </p>
        </section>


        <section>
            <h2>Physics</h2>
            <p>
                Applying the gravitational pull of an object is simple enough, but we didn't just want the planets to crash into the sun (just yet).
                For a planet (or any orbital object, really) to be in a stable orbit means for its (inertial) centrifugal force and the force of the gravitational pull to be equal.
                That enabled us to calculate the initial velocity:
            </p>
            <div className="center--horizontal">
                <Img
                    src={require("../../media/projects/intergalactic-billiard/formula.png")}
                    srcPreload={require("../../media/projects/intergalactic-billiard/formula.png")}
                    alt="For an object to be in a stable orbit means its centrifugal force and the gravitational pull must be the same"
                    height="160px"
                />
                {/*
                https://latex2png.com/

                F_C = m * \frac{v^2}{r}
                \\
                F_G = G * \frac{m_1 m_2}{r^2}
                \\
                F_C = F_G \implies v = \sqrt{G\frac{m_s}{r}}
            */}
            </div>
        
            <Ribbon color="primary">
                <Video
                    src={require("../../media/projects/intergalactic-billiard/headspace.webm")}
                    srcFallback={require("../../media/projects/intergalactic-billiard/headspace-firstframe.png")}
                    scrollIn={true}
                />
            </Ribbon>
        
            <p>
                Applying the gravity in each frame and setting the initial velocity, the orbits actually stayed stable (much to our surprise).
                We were facing a big problem though: How do you calculate all gravitational pulls between a large quantity of masses?
                The obvious answer: By don't actually calculating them if not required.
                Assuming a planet is in stable orbit until pulled out by another big mass flying around, the gravity calculation is disabled and only activated if flung by the user or if any "rogue" planets applied a significant force.
            </p>
            <Ribbon color="primary">
                <Video
                    src={require("../../media/projects/intergalactic-billiard/collision-test-2.webm")}
                    srcFallback={require("../../media/projects/intergalactic-billiard/collision-test-2-firstframe.png")}
                    scrollIn={true}
                    scrollInDelay={0}
                />
                <Video
                    src={require("../../media/projects/intergalactic-billiard/collision-test-1.webm")}
                    srcFallback={require("../../media/projects/intergalactic-billiard/collision-test-1-firstframe.png")}
                    scrollIn={true}
                    scrollInDelay={1}
                />
            </Ribbon>
        </section>
        <section>
            <h2>Summary</h2>
            <p>
                There are many, many more details to this project I could talk about, such as the black hole distortion, the galaxy generation or the many post-processing effects.
                Let's just say we learned a lot during this assignment and since we were able to relatively freely decide on the features of the game it was a lot of fun too!
                And also, we good a really good grade ;)
            </p>
        </section>
    </>
    );
};

export default projectContents