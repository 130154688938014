import React, { Component } from 'react';
import { checkIsInViewport } from '../data/helpers';
import OnScrollIn from '../component/OnScrollIn';

export default class Video extends Component {
    constructor(props) {
        super(props);
        this.isInViewport = false;
        this.ref = React.createRef();

        this.canPlay = false;

        this.onCanPlay = this.onCanPlay.bind(this);
        this.checkIsInViewport = this.checkIsInViewport.bind(this);

        this.state = {isLoading: true}
    }

    componentDidMount() {
        document.addEventListener("scroll", this.checkIsInViewport);
        this.checkIsInViewport();
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.checkIsInViewport);
    }

    checkIsInViewport() {
        const isInViewport = checkIsInViewport(this.ref.current);

        if (isInViewport !== this.isInViewport) {
            isInViewport ? this.enteredViewport() : this.leftViewport();
            this.isInViewport = isInViewport;
        }
    }

    onCanPlay() {
        this.setState({isLoading: false});
        this.canPlay = true;
        if (this.isInViewport) {
            this.enteredViewport();
        } else {
            this.leftViewport();
        }

    }

    enteredViewport() {
        if (this.canPlay) {
            this.ref.current.play();
        }
        this.isInViewport = true;
    }

    leftViewport() {
        if (this.canPlay) {
            this.ref.current.pause();
        }
        this.isInViewport = false;
    }

    render() {
        let inner = (<>
            {this.props.srcFallback &&
                <img
                    className="video__fallback"
                    src={this.props.srcFallback}
                    style={this.props.maxWidth ? {maxWidth: this.props.maxWidth} : {}}
                    alt={""}
                />
            }
            <video
                muted
                loop
                ref={this.ref}
                style={this.props.maxWidth ? {maxWidth: this.props.maxWidth} : {}}
                onCanPlay={this.onCanPlay}

                onTimeUpdate={() => this.setState({isLoading: false})}

                onWaiting={() => this.setState({isLoading: true}) }
                onStalled={() => this.setState({isLoading: true}) }
                onError={() => this.setState({isLoading: true}) }
                onPause={() => this.setState({isLoading: true}) }
                onEmptied={() => this.setState({isLoading: true}) }
                onSuspend={(e) => this.setState({isLoading: true}) }
            >
                <source src={this.props.src} />
            </video>
            <div className="video__spinner">
                <i className="fa fa-spinner fa-spin fa-xl" />
            </div>
        </>);

        if (this.props.scrollIn) {
            inner = (<OnScrollIn delay={this.props.scrollInDelay} delayGroup={this.props.scrollInDelayGroup}>{inner}</OnScrollIn>);
        }

        return (
            <div className={"video" + (this.state.isLoading ? " video--loading" : "")}>{inner}</div>
        );
    }


}