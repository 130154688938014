
import React, { Component } from 'react';
import Link from '../component/Link';
import projects from "../data/getProjects";

export default class Tag extends Component {
    render() {
        const model = this.props.model;
        return (
            <Link
                className="tag"
                to={model.getHref()}
                style={{backgroundColor : model.getColor()}}
                preventPageTransition={this.props.preventPageTransition}
            >
                <i style={{backgroundColor: model.getHoverColor()}} />
                <span className="tag__content">
                    {this.props.overwriteTitle ? this.props.overwriteTitle : model.title}
                    {this.props.showCount &&
                        <>
                            <span className="tag__separator">&nbsp;</span>
                            {model.count}
                        </>
                    }
                </span>
            </Link>
        );
    }
}

export class SpecificTag extends Component {
    render() {
        let props = {...this.props}

        props.model = projects.getTag(this.props.name);
        
        return (
            <Tag {...props} />
        );
    }
}

