import React from "react";

import Page from './Page.js';

import { Box } from "../component/Box";
import ProjectList from "../views/ProjectList";
import Button from "../component/Button";
import CursorEffectImg from "../component/CursorEffectImg";
import {Link} from "react-router-dom";
import {address} from "../data/helpers";


export default class PageHome extends Page {
    constructor(props) {
        super(props);

        this.registerSideBarContent(this.renderSidebarContentPortrait);
        this.registerSideBarContent(this.renderSidebarContentBoxes.bind(this));
        this.setTitle(null);
    }

    renderSidebarContentPortrait() {
        return (
            <Box noSpace={true}>
                <CursorEffectImg
                    src={require("../../media/static/portrait-bw-1080.jpg")}
                    srcPreload={require("../../media/static/portrait-bw-32.jpg")}
                    srcEffect={require("../../media/static/portrait-1080.jpg")}
                    alt="Portrait Angelo Aracri"
                    height="300px"
                />
            </Box>
        )
    }

    renderSidebarContentBoxes() {
        return <div className="row">
            <div className="col-12 col-sm-6 col-md-12">
                {this.renderSidebarContentAbout()}
            </div>
            <div className="col-12 col-sm-6 col-md-12">
                {this.renderSidebarContentContact()}
            </div>
        </div>
    }

    renderSidebarContentAbout() {
        return (
            <Box>
                <div className="space-content--half">
                    <ul className="ul--icon-list">
                        <li>
                            <i className="fa fa-xl fa-code"></i>
                            Software Engineering
                        </li>
                        <li>
                            <i className="fa fa-xl fa-pen-nib"></i>
                            Interface Design
                        </li>
                        <li>
                            <i className="fa fa-xl fa-gamepad"></i>
                            Game Design
                        </li>
                    </ul>
                </div>
                <div className="space-content--half">
                    <Button
                        icon="fa-user"
                        size="lg"
                        href="/about"
                        block={true}
                    >
                        About Me
                    </Button>
                </div>
            </Box>
        )
    }

    renderSidebarContentContact() {
        return (
            <Box>
                <div className="space-content--half">
                    <ul className="ul--icon-list">
                        <li>
                            <Link to={address.linkGitHub} target="_blank" rel="noreferrer">
                                <i className="fa fab fa-xl fa-github"></i>
                                GitHub
                            </Link>
                        </li>
                        <li>
                            <Link to={address.linkLinkedIn} target="_blank" rel="noreferrer">
                                <i className="fa fab fa-xl fa-linkedin"></i>
                                LinkedIn
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="space-content--half">
                    <Button
                        icon="fa-envelope"
                        size="lg"
                        href="/contact"
                        block={true}
                    >
                        Contact Me
                    </Button>
                </div>
            </Box>
        )
    }

    renderContent() {
        return (
            <>
                <div className="space-content">
                    <ProjectList num={6} />
                </div>
                <div className="space-content center--horizontal">
                    <Button
                        icon="fa-list-ul"
                        size="lg"
                        href="/projects"
                    >
                        Discover more projects
                    </Button>
                </div>
            </>
        )
    }
}