import React, { Component } from 'react';
import { checkIsInViewport } from '../data/helpers';

let delayGroups = {}
function ensureDelayGroup(delayGroup) {
    delayGroups[delayGroup] = delayGroups[delayGroup] || {}
}
function addToDelayGroup(delayGroup, scrollIn) {
    ensureDelayGroup(delayGroup);
    delayGroups[delayGroup][scrollIn.ID] = scrollIn;
}
function getInDelayGroup(delayGroup) {
    return Object.values(delayGroups[delayGroup]) || {};
}
function getRowInDelayGroup(delayGroup, scrollIn) {
    const top = scrollIn.ref.current.getBoundingClientRect().top;

    return getInDelayGroup(delayGroup).filter((otherScrollIn) => {
        const otherTop = otherScrollIn.ref.current.getBoundingClientRect().top;
        return Math.abs(top - otherTop) < 10;
    }).sort((a, b) => {
        const LeftA = a.ref.current.getBoundingClientRect().left;
        const LeftB = b.ref.current.getBoundingClientRect().left;
        return LeftA < LeftB ? -1 : 1;
    });
}
function getVisibleInDelayGroup(delayGroup, scrollIn) {

    return getInDelayGroup(delayGroup).filter((otherScrollIn) => {
        return otherScrollIn.isInViewport()
    });
}
function removeDelayGroup(delayGroup) {
    delete delayGroups[delayGroup];
}
function removeFromDelayGroup(delayGroup, scrollIn) {
    if (delayGroups[delayGroup]) {
        delete delayGroups[delayGroup][scrollIn];
    }
}

let idCount = 0;
export default class OnScrollIn extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.checkIsInViewport = this.checkIsInViewport.bind(this);
        this.state = {
            visible: false,
            delay: 0
        };
        this.ID = idCount++;
    }

    componentDidMount() {
        if (!this.state.visible) {
            if (this.props.delayGroup) {
                addToDelayGroup(this.props.delayGroup, this)
            }
            document.addEventListener("scroll", this.checkIsInViewport);
            this.checkIsInViewport(true);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.checkIsInViewport);
        if (this.props.delayGroup) {
            removeFromDelayGroup(this.props.delayGroup, this);
        }
    }
    isInViewport() {
        return checkIsInViewport(this.ref.current, 30);
    }
    checkIsInViewport(initial=false) {
        if (this.state.visible) {
            return;
        }
        
        if (this.isInViewport()) {
            this.scrollIn(initial);
        }
    }
    scrollIn(initial=false) {
        if (this.state.visible) {
            return;
        }
        document.removeEventListener("scroll", this.checkIsInViewport);

        let delay = 0;
        if (this.props.delay) {
            delay = this.props.delay;
        } else if (this.props.delayGroup) {
            let i = 0;
            const scrollIns = initial ? getVisibleInDelayGroup(this.props.delayGroup, this) : getRowInDelayGroup(this.props.delayGroup, this);
            for (const scrollIn of scrollIns) {
                if (scrollIn === this) {
                    delay = i;
                    // console.log(this.props.delayGroup, scrollIn.ID, delay * DELAY_TIME)
                    break;
                }
                i++;
            }
        }

        this.setState({
            visible: true,
            delay: delay
        });
    }
    render() {
        return <div
            ref={this.ref}
            className={"on-scroll-in" + (this.state.visible ? " anim--swoop-in" : " anim--swoop-down") + (this.state.delay ? " anim--delay--" + this.state.delay : "")}
        >
            {this.props.children}
        </div>
    }
}