import React, { Component } from 'react';

export default class Img extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.refImg = React.createRef();
        this.imageLoaded = this.imageLoaded.bind(this);
    }
    componentDidMount() {
        if (this.refImg.current.complete) {
            this.imageLoaded();
        } else {
            this.refImg.current.addEventListener("load", this.imageLoaded);
        }
    }
    componentWillUnmount() {
        this.refImg.current.removeEventListener("load", this.imageLoaded);
    }
    render() {
        let style = this.props.height ? {
            height: this.props.height,
            //width: "auto"
        } : {};


        return (<>
            <div
                className="img img--loading"
                ref={this.ref}
                onClick={this.props.onClick || null}
            >
                <img
                    src={this.props.srcPreload}
                    className="img__preload"
                    alt={""}
                    title={this.props.title}
                    style={style}
                />
                <img
                    src={this.props.src}
                    className="img__loaded"
                    alt={this.props.alt}
                    title={this.props.title}
                    ref={this.refImg}
                    loading="lazy"
                />
                {this.props.children}
            </div>
        </>);
    }

    imageLoaded() {
        this.ref.current.classList.remove("img--loading")
    }
}