import React, { Component } from 'react';
import Link from '../component/Link';
import Button from "../component/Button";
import ShaderRect from "../component/ShaderRect";
import * as THREE from 'three';

const NAV_COLLAPSE_WIDTH = 480

export class NavLink extends Component {
    render() {
        return (
            <li>
                <Link
                    to={this.props.href}
                    extraOnClick={() => this.props.nav.current.closeNav()}
                >{this.props.text}</Link>
            </li>
        );
    }
}
export class NavLinkLogo extends Component {
    render() {
        return (
            <li>
                <Link
                    to="/"
                    className="logo"
                    extraOnClick={() => this.props.nav.current.closeNav()}
                >
                    <span className="invisible">{this.props.text}</span>
                </Link>
            </li>
        );
    }
}


export class Nav extends Component {
    constructor(props) {
        super(props);
        this.refHeader = React.createRef();
        this.refShaderRect = React.createRef();

        this.toggleNav = this.toggleNav.bind(this);
        this.windowResized = this.windowResized.bind(this);
        this.closeNav = this.closeNav.bind(this);
        this.openNav = this.openNav.bind(this);

        this.state = {open: false, collapsed: window.innerWidth < NAV_COLLAPSE_WIDTH};
    }
    componentDidMount() {
        //this.attachLiHandlers();
        window.addEventListener("resize", this.windowResized);
        this.windowResized();
    }

    componentWillUnmount() {
        //this.detachLiHandlers();
        window.removeEventListener("resize", this.windowResized);
    }

    windowResized() {
        this.setState({collapsed: window.innerWidth < NAV_COLLAPSE_WIDTH})
        
    }

    toggleNav() {
        if (this.state.open) {
            this.closeNav();
        } else {
            this.openNav();
        }
    }
    openNav() {
        this.setState({open: true});
    }
    closeNav() {
        this.setState({open: false});
    }

    getLis() {
        return this.refHeader.current.querySelectorAll("li");
    }

    attachLiHandlers() {
        let i = 0;
        for (const li of this.getLis()) {
            li.addEventListener("click", this.liClicked.bind(this, i++))
        }
    }
    detachLiHandlers() {
        let i = 0;
        for (const li of this.getLis()) {
            li.removeEventListener("click", this.liClicked.bind(this, i++))
        }
    }

    liClicked(i) {
        this.refShaderRect.current.uiRectClicked(i);
    }

    getLiPositions() {
        const pt4 = new THREE.Vector4(0.0, 0.0, 0.0, 0.0);
        let result = [pt4, pt4, pt4, pt4];

        if (!this.refHeader || !this.refHeader.current) {
            return result;
        }

        let i = 0;
        for (const li of this.getLis()) {
            const rect = li.getBoundingClientRect();

            result[i++] = new THREE.Vector4(rect.left, rect.top, li.offsetWidth, li.offsetHeight);
        }

        return result;
    }

    render() {
        let header_classes = [];
        if (this.state.open) header_classes.push("header--open");
        if (this.state.collapsed) header_classes.push("header--collapsible");
            
        return (
        <header id="header" ref={this.refHeader} className={header_classes.join(" ")}>
            <div className="header__background">
                <div className="header__canvas">
                    {!this.state.collapsed &&
                        <ShaderRect
                            uiRectsProducer={this.getLiPositions.bind(this)}
                            uiRectPages={{"":0, "about":1, "projects":2, "project":2, "contact":3}}
                            ref={this.refShaderRect}
                        />
                    }
                </div>
                
                <div className="container header__btn">
                    <Button
                        icon="fa-bars"
                        size="sm"
                        color="secondary"
                        onClick={(e) => {e.preventDefault(); this.toggleNav()}}
                        preventPageTransition={true}
                    />
                </div>
                
                <nav>
                    <ul>
                        {this.props.children}
                    </ul>
                </nav>
            </div>
        </header>
        );
    }
}