import React, { Component } from 'react';
import Img from '../component/Img';

const EFFECT_RADIUS = 105;

export default class CursorEffectImg extends Component {
    constructor(props) {
        super(props);
        this.mouseMove = this.mouseMove.bind(this);
        this.imgLoaded = this.imgLoaded.bind(this);
        this.windowResized = this.windowResized.bind(this);
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.ctx = this.ref.current.getContext("2d");
        this.img = new Image();
        this.img.onload = this.imgLoaded;
        this.img.src = this.props.srcEffect;
    }
    componentWillUnmount() {
        document.removeEventListener("mousemove", this.mouseMove);
        window.removeEventListener("resize", this.windowResized);
    }

    imgLoaded() {
        this.drawFrame();
        document.addEventListener("mousemove", this.mouseMove);
        window.addEventListener("resize", this.windowResized);
    }
    mouseMove(e) {
        const elementBox = this.ref.current.getBoundingClientRect();
        const effectPos = [e.clientX - elementBox.left, e.clientY - elementBox.top];
        this.drawFrame(effectPos);
    }
    windowResized(e) {
        this.drawFrame();
    }

    lastEffectPos = null
    drawFrame(effectPos) {
        if (!effectPos) {
            if (!this.lastEffectPos) {
                return;
            }
            effectPos = this.lastEffectPos;
        }
        this.lastEffectPos = effectPos;

        let canvasSize = [this.ref.current.offsetWidth, this.ref.current.offsetHeight];
        let drawPos = [0, 0];
        let drawSize = [canvasSize[0], canvasSize[1]];

        const ratioImg = this.img.width / (1.0*this.img.height);
        const ratioCanvas = canvasSize[0] / (1.0*canvasSize[1]);

        if (ratioCanvas > ratioImg) {
            drawSize[1] = canvasSize[0] / ratioImg;
            drawPos[1] = -(drawSize[1] - canvasSize[1]) / 2.0;
        } else {
            drawSize[0] = canvasSize[1] / ratioImg;
            drawPos[0] = -(drawSize[0] - canvasSize[0]) / 2.0;
        }

        // set canvas tag attributes
        this.ref.current.width = canvasSize[0];
        this.ref.current.height = canvasSize[1];

        // draw and clip
        this.ctx.beginPath();
        this.ctx.arc(effectPos[0], effectPos[1], EFFECT_RADIUS, 0, Math.PI * 2, true);
        this.ctx.clip();
        this.ctx.drawImage(this.img, drawPos[0], drawPos[1], drawSize[0], drawSize[1]);
    }

    
    render() {
        let style = this.props.height ? {
            height: this.props.height,
            width: "auto"
        } : {};

        return (
            <Img {...this.props}>
                <canvas className="img__cursor-effect" ref={this.ref} />
            </Img>
        );
    }
}