
import React, { Component } from 'react';
import Tag from "./Tag";
import Collapsible from 'react-collapsible';

import TagModel from "../data/Tag";
import projects from "../data/getProjects";

const COLLAPSIBLES_OPEN_WIDTH = 992;

export default class TagList extends Component {

    constructor(props) {
        super(props);
        this.models = this.props.models || [];
        // this.escapedSelectedTags = this.props.selectedTags || [];

        if (this.props.escapedTags) {
            for (const escapedTag of this.props.escapedTags) {
                const tag = projects.getTag(escapedTag)
                if (tag) {
                    this.models.push(tag);
                }
            }
        }
    }

    areCollapsiblesOpen() {
        return window.innerWidth >= COLLAPSIBLES_OPEN_WIDTH;
    }

    render() {
        if (this.props.categorize) {

            const categories = Object.keys(TagModel.TAG_CATEGORIES).map((category, i) => (
                <Collapsible
                    key={i}
                    className="space-content--half"
                    openedClassName="space-content--half"
                    triggerTagName="h3"
                    triggerClassName="Collapsible__trigger--hide"
                    triggerOpenedClassName="Collapsible__trigger--hide"
                    trigger={<>{category}<i className="Collapsible__collapse" /></>}
                    transitionTime={150}
                    open={this.areCollapsiblesOpen()}
                >
                    <ul className="ul--tag-list" key={i}>
                        {this.models.filter((model) => model.category === category).map((model, j) => (
                            <li key={j}>
                                <Tag model={model} showCount={this.props.showCount} preventPageTransition={this.props.preventPageTransition}></Tag>
                            </li>
                        ))}
                    </ul>
                </Collapsible>
            ))

            const categoryColThresh = Math.ceil(categories.length / 2.0)

            return (
            <div className="row">
                <div className="space-content--half col-xs-12 col-sm-6 col-md-12">
                    {categories.slice(0, categoryColThresh)}
                </div>
                <div className="space-content--half col-xs-12 col-sm-6 col-md-12">
                    {categories.slice(categoryColThresh)}
                </div>
            </div>);
        } else {
            return (
            <> 
                <ul className="ul--tag-list">
                    {this.models.map((model, i) => (
                        <li key={i}>
                            <Tag model={model} showCount={this.props.showCount} preventPageTransition={this.props.preventPageTransition}></Tag>
                        </li>
                    ))}
                </ul>
            </>);
        }
    }
}