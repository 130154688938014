import React from "react";

import Page from './Page.js';

import projects from "../data/getProjects";
import projectContents from "../data/getProjectContents";

import ProjectList from "../views/ProjectList";
import TagList from "../views/TagList";

import {Box} from "../component/Box";
import Headspace from "../component/Headspace";
import Button from "../component/Button";
import TOC from "../component/TOC";


export default class PageProject extends Page {
    constructor(props) {
        super(props);

        this.createSideButton = this.createSideButton.bind(this);
        this.registerSideBarContent(this.renderSideBarContentTagList.bind(this));
        this.registerSideBarContent(this.renderSideBarContentTOC.bind(this));
        this.registerSideBarContent(this.renderSideBarContentButtons.bind(this));
        this.registerSideBarContent(() => <div />);
        
        this.setSidebarSticky(true);

        this.refTOC = React.createRef();
    }

    renderSideBarContentButtons() {
        return (<div className="space-content">
            {this.props.model.sideButtons.map((button) => this.createSideButton(button))}
        </div>);
    }

    createSideButton(props) {
        return (
            <Button
                icon={"fa " + props.icon}
                href={props.href}
                block={true}
                external={true}
                key={"sideButton-" + props.text}
                preventPageTransition={true}
            >
                {props.text}
            </Button>
            
        );
    }

    componentDidMount() {
        super.componentDidMount();
        
        this.refTOC.current.scan(this.refContent.current);
    }

    renderSideBarContentTagList() {
        return (
            <Box title="Details">
                <time dateTime={this.props.model.getReducedDate()}>{this.props.model.getPrettyDate()}</time>
                <TagList models={this.props.model.tags} showCount={true}></TagList>
            </Box>
        )
    }
    renderSideBarContentTOC() {
        return (
            <Box title="Content">
                <TOC ref={this.refTOC} />
            </Box>
        )
    }
    renderHeadspace() {
        return (
            <Headspace
                height="full"
                img={this.props.model.imgHeadspace}
                video={this.props.model.videoHeadspace}
                key={"headspace-" + this.props.model.title}
            >
                <div className="center--vertical">
                    <div className="space-content"><h1>{this.props.model.title}</h1></div>
                    <div className="space-content"><h2>{this.props.model.subtitle}</h2></div>
                </div>
            </Headspace>
        );
    }
    renderContent() {
        this.setTitle(this.props.model.title);

        const ProjectContent = (typeof this.props.model.content === "function")
                ? this.props.model.content()
                : projectContents[this.props.model.content];

        return (
            <>
                <article className="project">
                    <ProjectContent />
                </article>
                <div className="space-content">
                    <h2>Similar Projects</h2>
                    <ProjectList
                        collection={projects.getProjectsBySimilarity(this.props.model, 3)}
                        num={3}
                        preventPageTransition={true}
                    />
                </div>
            </>
        )
    }
}

