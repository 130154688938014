import React, {Component} from "react";

import {CollapseBox} from "../../component/Box";
import {ExternalLink} from "../../component/IconLink";
import Button from "../../component/Button";
import Link from "../../component/Link";
import ImgGallery from "../../component/ImgGallery";
import {SpecificTag} from "../../views/Tag";

export default function PageContent() {
	return (<>
		<section>
			<h2>How it came to be</h2>
			<p>
				As a teenager, this browser game was a big part of my life.
				I developed it from scratch on my own, gained users, continued to improve and administrate it over the years, <b>created a community</b> and even earned some money placing ads.
				At its peak, Trainercard had around 7000 registered accounts and 200 daily returning users.
				Looking at the design, usability or code now gives me the creeps, but I still take much pride in it nevertheless and there is not doubt that it has strongly influenced my interests and life choices.
				Unfortunately, I had to take it down at some point because of changing internet law and the outdated, potentially insecure technologies used.
				To get a static glimpse of website, you will have to use the internet archives <ExternalLink href="https://web.archive.org/web/20230000000000*/http://trainercard.net">Wayback Machine</ExternalLink> or a similar service.
			</p>
			<p>
				Even after the release of the first iPhone in 2007 had sparked the beginning of the mobile era, browser games were still big and continued to be so a couple more years to come before apps took over.
				The internet was less focused around big platforms and more around specialized forums so it was more diverse, but it was also chaotic and confusing.
				As a teenager, I was fascinated by the sheer amount of forums, tools, fun little gimmicks and of course - browser games.
				In Germany, <ExternalLink href="https://www.die-staemme.de/">Die Stämme</ExternalLink> and <ExternalLink href="https://www.pennergame.de/">Pennergame</ExternalLink> were very popular at the time, just to name a few.
				It got to a point were half my school class would play the same browser game for a while!
			</p>
			<p>
				At the time I was learning <SpecificTag name="php" showCount={true} /> and <SpecificTag name="sql" showCount={true} /> from books, and I got to a chapter about dynamic image creation.
				PHP is not only able to output <SpecificTag name="html" showCount={true} /> files, but by changing the header parameters you can dynamically create any kind of file, really.
				Additionally, PHP offers handy drawing operations that can be used to render colored shapes and pixels, add text or insert other images.
			</p>
			<p>
				Coincidentally, I was using Pokémon graphics to test dynamic image composition when I had the innocent idea to create a PHP website where users could create their <b>Trainercard</b> - a link to a customizable PNG file that would show their favorite Pokémon.
				Like so many kids at the time, I was a complete Pokémon fan and I knew I was not alone.
				After registering you could customize your Trainercard by changing the name, Pokémon, trainer sprite, background image, text color and more on your profile page.
				The image could then be downloaded and - more crucially - included from anywhere on the Internet to be proudly presented to the world.
			</p>
			<ImgGallery cols="2" images={[
                {
                    src : require("../../../media/projects/trainercard/trainercard-1.png"),
                    src_thumb : require("../../../media/projects/trainercard/trainercard-1.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/trainercard-1-32.png"),
                    alt : "Every user of the Trainercard browser game got a dynamic PNG badge that was customizable and showed your Pokémon.",
                    title : "Players can customize the Trainercards background, text color, trainer sprite and decorate their Pokémon with accessories."
                },{
                    src : require("../../../media/projects/trainercard/trainercard-2.png"),
                    src_thumb : require("../../../media/projects/trainercard/trainercard-2.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/trainercard-2-32.png"),
                    alt : "The Traincard image show the players level, his trainer points, achievements and all his Pokémon.",
                    title : "Trainer points, levels and a list of achievements show your progress in the game."
                },{
                    src : require("../../../media/projects/trainercard/trainercard-4.png"),
                    src_thumb : require("../../../media/projects/trainercard/trainercard-4.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/trainercard-4-32.png"),
                    alt : "Users could arrange accessories on their Pokémon graphic to give it a unique look.",
                    title : "The users had many creative ideas to personalize their Trainercard and compete in the monthly content, but the sun glasses were definitely a favorite."
                },{
                    src : require("../../../media/projects/trainercard/trainercard-3.png"),
                    src_thumb : require("../../../media/projects/trainercard/trainercard-3.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/trainercard-3-32.png"),
                    alt : "The user is given creative freedom to design their Trainercard however they want to.",
                    title : "And then there is this one guy with 6 magikarp and 20.000 levels."
                }
            ]} />
			<p>
				Additionally, every account was given a unique levelup link - any visitor who clicked the link, could give the Pokémon a rare candy to increase the level shown on your Trainercard.
				You could only do so every 3 hours per computer, so you would either have to wait or get more people to click the link (of course I had to add counter measures later to prevent people from just changing their IP).
				This mechanic combined with the badge character of the Trainercard image made my users happily advertise the game in my stead and Trainercard was able to gain popularity without me ever paying for ads.
			</p>
			<p>
				Especially forum signatures were the perfect place to show of your Pokémon and levelup link so being an active member on multiple forums at the time myself, I didn't hesitate to place my own Trainercard in every signature I had.
				Unsurprisingly, the concept hit communities with a young audience and especially the german Pokémon forum <ExternalLink href="https://bisaboard.bisafans.de/">BisaBoard</ExternalLink> like a bomb.
				I remember the fateful day were I would literally wake up to 5000 registered accounts.
				Sure, many were duplicate accounts because I had no CAPTCHA or mail verification in place (yet), but that was enough motivation to focus on this project and this project alone.
			</p>

		</section>

		<section>
			<h2>The game</h2>
			<p>
				Since the start of the game, the Trainercard image and levelup link were the core gameplay mechanics, but over the years I added many more features to evolve it into a fully-fledged browser game.
				Users could soon place accessories on their Pokémon, compete in a contest for the best Trainercard of the month and unlock many new backgrounds and accessories by gaining levels.
			</p>
			<p>
				It <i>was</i> a browser game, so I was quick to add the questionable mechanic where you make the user repeatedly perform a certain <b>time-based action</b> every 10 minutes to a couple hours.
				Since I wanted to improve the bond to your virtual friend, I decided to make that time-based action taking a walk with your Pokémon.
				As a reward, the player received money and points to unlock more content and items to temporarily gain bonuses.
				And of course, the rewards were best if you kept taking the 10 minute walk over and over again instead of the 3-hour option a couple times a day...
				As the game grew, I added more of these time-based actions to choose from, so you could also send your Pokémon to a dojo to upgrade skills or go to your job to improve your career and earn money.
				This improved the gameplay complexity a little, because you have to plan the order to get the optimum reward.
			</p>
			<ImgGallery images={[
                {
                    src : require("../../../media/projects/trainercard/screenshot-walking-2.png"),
                    src_thumb : require("../../../media/projects/trainercard/screenshot-walking-2.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/screenshot-walking-2-32.png"),
                    alt : "To gather items and money, players could take a walk with their Pokémon. This is a common mechanic in browser games that makes the user keep coming back.",
                    title : "Me and my Lucario taking a walk. The sprites actually slowly move forwards to display the progress."
                }
            ]} />
			<p>
				At the time, Trainercard didn't really feel like a Pokémon game at all, since all interactions were done via standard input elements, links, and buttons.
				So in one huge update, I scratched together all my <SpecificTag name="javascript" showCount={true} /> knowledge and added a game for the player and his Pokémon to actually walk in!
				Instead of clicking a button to take a walk with your Pokémon, you simply had to use your arrow keys to move to the safari zone and select the route you by clicking the sign post.
				Similar to the UI of the Pokémon games themself, I hid all the UI for Trainercard customization, inventory, settings and more in a menu on the right to push the map to the center of the game.
			</p>
			<ImgGallery images={[
                {
                    src : require("../../../media/projects/trainercard/screenshot-map-1.png"),
                    src_thumb : require("../../../media/projects/trainercard/screenshot-map-1.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/screenshot-map-1-32.png"),
                    alt : "Users could walk in a Pokémon-like map and start all the typical browser game mechanics from there.",
                    title : "The first part of the map with the Kecleon shop, the safari zone, the entrance to the pavillon, the contest hall and a city to apply for jobs and go to work"
                },{
                    src : require("../../../media/projects/trainercard/screenshot-walking-1.png"),
                    src_thumb : require("../../../media/projects/trainercard/screenshot-walking-1.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/screenshot-walking-1-32.png"),
                    alt : "The player deciding on the route to walk on with his Pokémon. The routes take different amounts of time and different items can be found on every one.",
                    title : "Were there only links to click previously, you now had to walk to a specific location instead."
                }
            ]} />
            <p>
            	Many small gimmicks and secrets were hidden on the map, so players could unlock accessories and backgrounds by finding them in bushes or by talking to NPCs at specific times or dates.
            	The map continued to grow with each new update, as I needed more space for shops, the Pokémon day care, factories, the Dojo, ruins and more.
            </p>
            <p>
            	While you could only have one Pokémon on your Trainercard at first, I added a way for the player to build an entire team of 6 Pokémon by catching them.
            	Since there was obviously fighting feature, I added a version of block breaker as a mini game to catch them.
            	To win, you have to deal 10 damage to the egg, but the twist is the type effect damage system - so when the ball has last touched a fighting-type brick, it deals double damage to a stone-type brick and so on.
            </p>
            <ImgGallery images={[
                {
                    src : require("../../../media/projects/trainercard/factory-1.png"),
                    src_thumb : require("../../../media/projects/trainercard/factory-1.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/factory-1-32.png"),
                    alt : "To catch new Pokémon, players had to win a game of block breaker.",
                    title : "A block breaker clone to catch a new Pokémon. To catch it, you need to hit the egg 10 times."
                },{
                    src : require("../../../media/projects/trainercard/factory-2.png"),
                    src_thumb : require("../../../media/projects/trainercard/factory-2.png"),
                    src_thumb_preload : require("../../../media/projects/trainercard/factory-2-32.png"),
                    alt : "The block breaker version had a unique twist where the ball would take on a type and deal different amounts of damage based on Pokémon type advantages.",
                    title : "When hitting a block, the Pokéball is charged with the corresponding type and can deal very effective or non-effective damage to the egg."
                }
            ]} />
            <p>
            	I added friends and personal messages relatively early, but with no other social features, the only way to send friend requests was to enter their name into an input element.
            	There was a score board and a monthly contest where the users would vote on the best or most unique Trainercard, but apart from that there was no social component to the game.
            	<br />
            	Wanting to create more interaction between the users, I added a chat called the <b>Pavilion</b> where players could not only have conversations in separate chat rooms but also walk on the same map in realtime.
            	Seeing the other players and their Pokémon walking around and being able to just go to a table to enter a separate chat room made the chat feel more personal than most chats did.
            	Later, you could even offer trade deals and send presents to the other users right from the chat, turning the Pavilion into somewhat of a marketplace whenever there were new items or when there was a time-limited special.
            </p>
            <ImgGallery images={[
	            {
	                src : require("../../../media/projects/trainercard/pavillon-chatroom-1.png"),
	                src_thumb : require("../../../media/projects/trainercard/pavillon-chatroom-1-1080.png"),
	                src_thumb_preload : require("../../../media/projects/trainercard/pavillon-chatroom-1-32.png"),
	                alt : "The Pavilion was the social hub of Trainercard, where players could chat, see the other players and Pokémon in realtime, and trade items.",
	                title : "The Pavilions map, showing all other players and their Pokémon in realtime. Walking to a table or the pool puts you into a separate chat room."
	            },{
	                src : require("../../../media/projects/trainercard/pavillon-chatroom-2.png"),
	                src_thumb : require("../../../media/projects/trainercard/pavillon-chatroom-2-1080.png"),
	                src_thumb_preload : require("../../../media/projects/trainercard/pavillon-chatroom-2-32.png"),
	                alt : "A screenshot of some of the most active users and me in the Pavilion chat.",
	                title : "These two screenshots are actually group photos I took many years ago with some of the most active users at the time."
	            }
	        ]} />
		</section>

		<section>
			<h2>How I made it</h2>
			
			<p>
				Before .NET, Python and Node.js took over, PHP was big. And I mean <i>really</i> big.
				PHP was the server language powering every one of those forums, content management systems, funny video platforms and social platforms.
				Even when highly interactive platforms with tons of budget like Facebook got big, they used PHP under the hood. 
			</p>
			<p>
				Having only known the static nature of HTML pages with small JavaScripts, I was fascinated by PHPs capability to dynamically assemble HTML or even JavaScript and CSS, thus giving every user a different page.
				The &lsquo;PHP & MySQL for Kids&rsquo; book that was my teacher also introduced me to the world of databases, that could store data between PHP page requests.
				So together with my previous frontend knowledge I had all the tools required to reach the interactiveness of a browser game.
				Well, to be honest, it was learning by doing and often times I would learn the hard way.
			</p>
			<p>
				I used a free web hoster that supported PHP and MySQL in exchange for placing ads and merely getting the sub-domain <i>trainercard.kilu.net</i>.
				Very soon however, lag started spiking when many Trainercard images were generated in a short amount of time, so I payed the costs for a proper web hoster, managed to convince my parents to sign the deal for me and took on the domain of <i>trainercard.net</i>.
			</p>
			<p>
				Initially I wasn't worried about duplicate accounts, but as soon as I added the message feature, I knew that both CAPTCHAs and mail verification were required.
				Since users had already been playing and put lots of time into their accounts, I actually added an <i>optional</i> mail verification to activate all social features.
			</p>
			<p>
				Though I had gone through the examples and exercises about MySQL in the book, I had never used any database technology in a real application so as the game and number of features grew, I had to grasp the more advanced SQL concepts like <code>JOIN</code>, <code>INDEX</code> or <code>GROUP BY</code>.
				And by the end, there were <b>a lot of database tables</b>.
				Just to name a few:
				the accounts, their items, unlocked accessories/backgrounds/items/routes, friend requests, personal messages, contest results as well as contest votes, currently running time-based actions like taking a walk but also login attempts, CAPTCHA results and attempts, the IP address of any visitor that has opened a levelup link and blocked users.
			</p>
			<p>
				<ExternalLink href="https://www.phpmyadmin.net/">phpMyAdmin</ExternalLink> is a PHP server tool to handle an instance of MySQL.
				It gives your PHP code access to MySQL and usually comes with a web interface to inspect and manipulate your database.
				The screenshot below gives an impression of the number of databases (left) and how the SQL language can be used to join tables together to receive useful information.
			</p>
			<ImgGallery images={[
	            {
	                src : require("../../../media/projects/trainercard/phpmyadmin-sql.png"),
	                src_thumb : require("../../../media/projects/trainercard/phpmyadmin-sql-1080.png"),
	                src_thumb_preload : require("../../../media/projects/trainercard/phpmyadmin-sql-32.png"),
	                alt : "The phpMyAdmin interface of Trainercard, showing some of the database tables.",
	                title : "With my phpMyAdmin, you can inspect your database table structure and query SQL. With this command, the number of collected items by a specific user are summed up using 3 tables."
	            }
	        ]} />
	        <p>
	        	When Trainercard was more about customizing a PNG file and less about a game world to walk in and a community to interact with, most of the pages were pure PHP sites.
	        	You clicked a link or button, your browser would open a new address by requesting a file and the server would interpret the PHP script to create the HTML.
	        	But it was an interesting time in web development, as JavaScript and JavaScript frameworks like <SpecificTag name="jquery" showCount={true} /> were on the rise, enabling easy-to-use animations even before CSS3 and suddenly you could use <b>AJAX</b> to send HTTP requests to servers via JavaScript without reloading the entire page.
	        	This game changer drastically increased the interactivity of websites and by adding more and more JavaScript, Trainercard slowly morphed into a real game rather than a collection of links to click in specific time intervals.
	        	The personal and chat room messages were polled in the background, a box would come flying in whenever you got money, items, a friend request or a trade deal and I animated the hell out of every element using jQuery.
	        	More and more complex features like shops and item trading could be done by walking to a certain place in the games world <b>without ever-changing the web address</b>.
        	</p>
        	<p>
		        A great example for learning by doing the hard way would be my experience with the chat room:
				To save on MySQL workload, the chat messages were stored in text files.
				The browser would periodically poll a PHP script for new messages and display them using JavaScript.
				When sending a message however, the PHP script must open and then append a message to the text file.
				For some long weeks I was investigating a bug where chat messages would sometimes not be sent when the chat was crowded and it took a lot of effort and nerve to realize I had unknowingly created a <b>race condition</b>.
				A term that describes (as I would learn much later) a situation in which two write operations are competing, potentially overwriting one another.
				MySQL had secretly kept that from happening, but for the text files I had to lock the chat file during write operations so no other write operation could happen at the same time.
			</p>
		
		</section>

		<section>
			<h2>Updates, Maintenance and Specials</h2>
			<p>
				Developing a game is one thing, but administration and maintenance was something I had never done but had to master for this ever-growing pile of PHP, MySQL, JavaScript, HTML and CSS.
				That included deployment via FTP, updating the PHP and phpMyAdmin version to stay secure, frequent MySQL backups, identifying bottlenecks in the PHP code, realizing certain database table entries are never deleted and finding out why or adjusting the web hosting plan to the user number.
				Many important architectural decisions at the core of Trainecard were poorly made due to my inexperience and the historical growth of the project, so at some point I had to refactor and optimize parts of the game to prevent server timeouts and long waiting times. 
			</p>
			<p>
				Apart from the technical nature of keeping a browser game alive, there were also support mails to answer:
				Users forgot their password for an account with no mail (so there is no password reset), others users had questions, reported bugs and some eager users even put together ideas for future updates.
				Via mail, a couple other web masters and programmers from various german Pokémon websites and I even banded together once to promote each others platforms.
			</p>
			<p>
				Internet law in Germany also changed multiple times, so I had to stay up to date with the necessary disclaimers, terms and conditions.
				I was also fully aware of the danger zone that was using original Nintendo copyright content and earning money through advertisement, but at the time, all was good as long as I wouldn't charge users any money directly.
			</p>
			<p>
				The internet was changing fast in those years, as it saw new technologies and features emerging left and right.
				Being on the forefront of HTML5 and CSS3 and experiencing the transition from PHP website to interactive web app first-hand with a live project was amazing and I was eager to integrate these new technologies as fast as possible for my community.
			</p>
			<p>
				Of course, users would sometimes find bugs to exploit and while some reported them to me, other users were quick to make the most use of them before it was patched out.
				Thinking about it now, there was even somewhat of a black market where a handful of players traded information on bugs for rare items!
				<br />
				The community encountered an interesting bug when using items.
				By spamming the reload button on the <i>use item</i> page, the item would sometimes be removed from the inventory 10 times, while the effect was executed 12 times.
				This got even worse as the rumor spread, since users kept spamming the reload button, the server started reaching its limits, making the bug even more likely and my server bill higher.
				The issue was the way I split the database update up into two SQL commands.
				Instead of atomarily deleting one item entry in the database table per item use, I first saved the entries ID in a PHP variable and then deleted it at a later time of the PHP script, so I you were lucky, the same ID would be selected twice and only one item would be taken from your inventory.
			</p>
			<p>
				The most obvious &lsquo;cheat&rsquo; by far was to bypass the 3-hour time between levelups.
				The users found that they could delete their cookies and change their IP address to click the levelup again, so I added safe-guards that identified and blocked suspicious levelup behavior and started saving the browser version, window size and some more browser-specific details to be able distinguish users.
				I even logged such cases to be able to identify the users in order to warn them or temporarily ban their account.
			</p>
			<p>
				Some users would even create multiple accounts, get some easy rewards with the new account, start time-consuming actions to collect money and items, send them to their main account and even try to vote for themselves in the monthly content.
				To counter those strategies, I became more restrictive with the mail verification and added a 30-day period before you could start voting in the contest a 5-day period before trading or giving presents was possible.
				I also added a registration intro that would play when you created a new account which was fun when you first registered, but tedious if goal was to create many accounts.
				<br />
				Sometimes, it felt a little like war between me and the cheaters :)
			</p>
		</section>

		<section>
			<h2>A community</h2>
			<p>
				I can say without a doubt, that the development of the <b>Pavilion chat</b> was the most important key to Trainercards success. 
				Many active users would come together every afternoon to talk about Trainercard, the lastest Pokémon game and what ever else interested internet-affine kids in the mid 2010s.
				Without it, Trainercard was just creating a PNG and posting it online, but after I uploaded that update, it became a buzzing community of hardcore fans.
				People loved discussing updates, trying to be the first to unlock its contents and finding optimal strategies and for around 5 years, I continued to add more features, keeping the game fresh.
				Often times I would hang out in my own chat room during development and talk with my users, discuss updates, come up with ideas for accessories and find bugs together, which my user base valued highly.
			</p>
			<ImgGallery images={[
	            {
	                src : require("../../../media/projects/trainercard/trainercards.png"),
	                src_thumb : require("../../../media/projects/trainercard/trainercards-1080.png"),
	                src_thumb_preload : require("../../../media/projects/trainercard/trainercards-32.png"),
	                alt : "Players were competing in a score board and the monthly contest.",
	                title : "The leaderboard shows all Trainercards and there were many unique ideas."
	            }
	        ]} />
	        <p>
				When I was not around to keep an eye on the chat however, I needed help to fight spam and insults.
				After a &lsquo;casting&rsquo; in the Pavilion, three moderators were elected and given chat room kick and ban features.
				It was nice having people help me and I'm thankful that they did!
			</p>
			<p>
				Creating this community from scratch was a unique experience and it was a time I will never forget.
				Among my users, I found many friends that would follow me along my journey, helping me create graphics and images, moderating the chat, playtesting, bug-reporting and even drawing Trainercard fan art!
				A big <b>Thank you</b> to all people that were a part of this and made it possible!
			</p>
		</section>
		<section>
			<ImgGallery images={[
	            {
	                src : require("../../../media/projects/trainercard/a-lot-of-pokemon.jpg"),
	                src_thumb : require("../../../media/projects/trainercard/a-lot-of-pokemon-1080.jpg"),
	                src_thumb_preload : require("../../../media/projects/trainercard/a-lot-of-pokemon-32.jpg"),
	                alt : "Many Pokémon sprites randomly drawn onto each other.",
	                title : "When you give me a folder of Pokémon sprites, this is what happens..."
	            }
	        ]} />
		</section>

	</>)
}