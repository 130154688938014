import React, { Component } from 'react';

export default class Page extends Component {
    constructor(props) {
        super(props);
        this.refContent = React.createRef();
        this.setSidebarSticky(false);
    }

    setTitle(title) {
        document.title = (title ? title + " | " : "") + "Angelo Aracris Portfolio"
    }

    render() {
        if (document.body.classList.contains("router-page-unmount")) {
            document.body.classList.remove("router-page-unmount")
            document.body.classList.add("router-page-mounting")
        }

        return (
            <>
                {this.renderHeadspace()}
                <div className="content">
                    <div className="container">

                            {this.showsSidebar() ?
                                (<div className="row has-sidebar">
                                    {this.renderSidebar()}
                                    <div role="main" className="col-md-8 col-lg-9" ref={this.refContent}>
                                        {this.renderContent()}
                                    </div>
                                </div>) :
                                (<div role="main" className="col" ref={this.refContent}>
                                    {this.renderContent()}
                                </div>)
                            }

                    </div>
                </div>
            </>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (document.body.classList.contains("router-page-mounting")) {
            document.body.classList.remove("router-page-mounting")
        }
    }

    renderHeadspace() {
        return (<div className="no-headspace" />);
    }

    setSidebarSticky(sticky) {
        this.stickySidebar = sticky;
    }

    sideBarContentFcts = []
    registerSideBarContent(contentFct) {
        this.sideBarContentFcts.push(contentFct);
    }
    unregisterSideBarContent(contentFct) {
        const index = this.sideBarContentFcts.indexOf(contentFct);
        if (index > -1) {
            this.sideBarContentFcts.splice(index, 1);
        }
    }

    showsSidebar() {
        return this.sideBarContentFcts.length > 0
    }

    renderSidebar() {
        if (this.showsSidebar()) {
            return (
                <aside className={"col-md-4 col-lg-3" + (this.stickySidebar ? " sticky" : "")}>
                    {this.sideBarContentFcts.map((contentFct, i) => this.renderSidebarBox(contentFct, i))}
                </aside>
            );
        } else {
            return null;
        }

    }

    renderSidebarBox(content, i) {
        return (
            <div className="space-content" key={i}>
                {content.call()}
            </div>
        );
    }

    renderContent() {
        return (<b>renderContent not overwritten!</b>)
    }
}