import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Navigate } from "react-router-dom";

export default class Link extends Component {
    constructor(props) {
        super(props);
    
        this.onClick = this.onClick.bind(this);
        this.onUnmountDone = this.onUnmountDone.bind(this);

        this.state = { unmountDone: false };

        this.navCount = 0;
    }

    render() {
        let propsCopy = {...this.props};
        delete propsCopy['preventPageTransition'];
        delete propsCopy['onClick'];
        delete propsCopy['extraOnClick'];

        return (<>
                <ReactLink {...propsCopy} onClick={this.props.onClick ? this.props.onClick : this.onClick} />
                {this.state.unmountDone &&
                    <Navigate to={this.props.to} key={this.navCount++} />
                }
            </>);

    }

    onClick(e) {
        if (this.props.extraOnClick) {
            this.props.extraOnClick();
        }
        if (!this.props.preventPageTransition) {
            e.preventDefault();
            e.stopPropagation();
            if (window.location.pathname !== this.props.to) {
                document.body.classList.add("router-page-unmount");
                setTimeout(this.onUnmountDone, 300);    
            }
        }
    }

    onUnmountDone() {
        //document.body.classList.remove("router-page-unmount")
        this.setState({"unmountDone": true});
    }

    componentWillUnmount() {
        // just to make sure
        //document.body.classList.remove("router-page-unmount");
    }
}