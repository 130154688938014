import React, { Component } from 'react';
import * as THREE from 'three';

export default class ShaderRect extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();

        this.updateSize = this.updateSize.bind(this);
        this.mouseMove = this.mouseMove.bind(this);
        this.uiRectClicked = this.uiRectClicked.bind(this);
    }

    render() {
        return (
            <div className="shader-rect" ref={this.ref} />
        );
    }

    componentDidMount() {
        this.initCanvas();
        window.setTimeout(this.updateSize.bind(this), 100);
        window.setTimeout(this.updateSize.bind(this), 500);
        window.addEventListener("resize", this.updateSize);
        document.addEventListener("mousemove", this.mouseMove);

        const config = { attributes: false, childList: true, subtree: true };

        var previousUrl = '';
        this.observer = new MutationObserver(function() {
            if (document.location.href !== previousUrl) {
                previousUrl = document.location.href;

                const split = window.location.pathname.split('/');
                const page = (split.length < 2) ? "" : split[1];
                const uiRectId = (this.props.uiRectPages[page] === undefined) ? -1 : this.props.uiRectPages[page];
                this.uiRectClicked(uiRectId);

                this.updateSize();
            }
        }.bind(this));

        this.observer.observe(document.body, config);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateSize);
        document.removeEventListener("mousemove", this.mouseMove);
        this.observer.disconnect();
        this.renderer.dispose();
    }

    getDims() {
        return {
            width: this.ref.current.offsetWidth,
            height: this.ref.current.offsetHeight
        };
    }

    getTime() {
        const timeNow = Date.now() / 1000.0;
        if (!this.timeStart) {
            this.timeStart = timeNow;
        }

        return timeNow - this.timeStart;
    }

    getVertexShader() {
        return `
            void main() {
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position.xyz, 1.0);
            }
        `;
    }

    getFragmentShader() {
        return `
uniform vec4[${this.uiRects.length}] uiRects;
uniform vec2 mousePos;
uniform vec2 iResolution;
uniform float iTime;
uniform float clickEffect;
uniform int clickEffectUiRect;

const vec4 COL_BG = vec4(122.0/255.0, 49.0/255.0, 190.0/255.0, 1.0);
const vec3 COL_EFFECT = vec3(33.0/255.0, 176.0/255.0, 189.0/255.0);
const float RADIUS = 70.0;

float rescale(float value, float valueMin, float valueMax, float resultMin, float resultMax) {
    return min(1.0, max(0.0, (value - valueMin) / (valueMax - valueMin))) * (resultMax - resultMin) + resultMin;
}
float rescaleUnclamped(float value, float valueMin, float valueMax, float resultMin, float resultMax) {
    return (value - valueMin) / (valueMax - valueMin) * (resultMax - resultMin) + resultMin;
}

float dstAxisAlignedRect(vec2 uv, vec2 tl, vec2 br) {
    vec2 d = max(tl-uv, uv-br);
    return length(max(vec2(0.0), d)) + min(0.0, max(d.x, d.y));
}

void main() {
  vec2 pt = gl_FragCoord.xy;

  float t = rescale(iTime - clickEffect, 0.0, 1.0, -1.0, 1.0);
  t = max(0.0, min(1.0, 1.0 - t * t));

  float energy = 0.0;

  for (int i = 0; i < uiRects.length(); i++) {
    vec4 uiRect = uiRects[i];

    float energyClick = rescale(float(i - clickEffectUiRect), -1.0, 1.0, -1.0, 1.0);
    energyClick = (1.0 - energyClick*energyClick) * t;

    float dstPt = dstAxisAlignedRect(pt, uiRect.xy, uiRect.xy + uiRect.zw);
    float dstMouse = dstAxisAlignedRect(mousePos, uiRect.xy, uiRect.xy + uiRect.zw);

    vec2 center = uiRect.xy + 0.5 * uiRect.zw;
    vec2 centerOffset = -0.5 * (mousePos - center);
    centerOffset.x = min(uiRect.z * 0.5, max(-uiRect.z * 0.5, centerOffset.x));
    centerOffset.y = min(uiRect.w * 0.5, max(-uiRect.w * 0.5, centerOffset.y));
    float dstPtShifted = dstAxisAlignedRect(pt + centerOffset * (1.0 - energyClick), uiRect.xy, uiRect.xy + uiRect.zw);

    float isInsideEnergy = rescale(dstPt, -2.0, 0.0, 1.0, 0.0);
    float edgeEnergy = rescale(abs(dstPt), 2.0 + energyClick * 2.0, 0.0, 0.0, 1.0);
    float energyMouse = max(rescale(dstMouse, -10.0, 20.0, 1.0, 0.0), energyClick);

    float amplWarp = 0.3 + energyClick * 0.7;
    float dstPtWarped = dstPtShifted + amplWarp * sin(5.0 * iTime + 0.4 * pt.x) + amplWarp * cos(5.0 * iTime + 0.4 * pt.y);
    float waveX = rescaleUnclamped(dstPtWarped, 0.0, -25.0, 0.0, 2.0 * 3.1415) - (2.5*energyClick);
    float waveY = sin(waveX * 3.0 - 22.0 * iTime) * 0.5 + 0.5;

    float innerDecayEnergy = rescale(dstPtShifted, 0.0, -20.0, 1.0, 0.0);

    energy += (edgeEnergy + 0.7*(waveY * waveY * waveY + 0.6 * energyClick) * innerDecayEnergy * isInsideEnergy) * energyMouse;
  }
  
  gl_FragColor.rgba = COL_BG;
  gl_FragColor.rgb += COL_EFFECT * energy;
  
}
        `;
    }

    initCanvas() {
        this.dims = this.getDims();

        this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

        this.scene = new THREE.Scene();

        const geometry = new THREE.PlaneGeometry(2, 2);

        this.uiRects = this.getUIRects();

        this.shaderMaterial = new THREE.ShaderMaterial({
            uniforms: {
                iTime: {value: this.getTime()},
                iResolution: {value: new THREE.Vector2(this.dims.width, this.dims.height)},
                mousePos: {value: new THREE.Vector2(-42.0, -42.0)},
                uiRects: {value: this.uiRects},
                clickEffect: {value: -100.0},
                clickEffectUiRect: {value: -1}
            },
            vertexShader: this.getVertexShader(),
            fragmentShader: this.getFragmentShader(),
        });

        this.mesh = new THREE.Mesh(geometry, this.shaderMaterial);
        this.scene.add(this.mesh);

        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setSize(this.dims.width, this.dims.height);
        this.renderer.setAnimationLoop(this.renderCanvas.bind(this));

        this.ref.current.innerHTML = "";
        this.ref.current.appendChild(this.renderer.domElement);
    }

    getUIRects() {
        return this.props.uiRectsProducer();
    }

    uiRectClicked(i) {
        this.shaderMaterial.uniforms.clickEffectUiRect.value = i;
        this.shaderMaterial.uniforms.clickEffect.value = this.getTime();
    }

    mouseMove(e) {
        if (this.shaderMaterial) {
            this.shaderMaterial.uniforms.mousePos.value = new THREE.Vector2(e.pageX, this.ref.current.offsetHeight - e.pageY + window.scrollY);
        }
    }

    updateSize() {
        const newDims = this.getDims();
        if (this.dims.width !== newDims.width || this.dims.height !== newDims.height) {
            this.dims = newDims;
            
            this.shaderMaterial.uniforms.iResolution.value = new THREE.Vector2(this.dims.width, this.dims.height);
            this.renderer.setSize(this.dims.width, this.dims.height);
        }
        this.shaderMaterial.uniforms.uiRects.value = this.getUIRects();
        
    }

    renderCanvas(_time) {
        this.shaderMaterial.uniforms.iTime.value = this.getTime();
        this.renderer.render(this.scene, this.camera);
    }
}