import React from "react";

import Page from './Page.js';
import {Box} from "../component/Box";
import TagList from "../views/TagList";
import projects from "../data/getProjects";
import ProjectList from "../views/ProjectList";
import Headspace from "../component/Headspace";
import Button from "../component/Button";

export default class PageProjects extends Page {
    constructor(props) {
        super(props);

        this.refProjectList = React.createRef();
        this.refTagList = React.createRef();
        this.refTagListActive = React.createRef();

        this.filterTags = this.props.filterTags || [];

        this.registerSideBarContent(this.renderSideBarContentTagList.bind(this));

        this.setSidebarSticky(false);

        this.setTitle("Projects");
    }

    renderSideBarContentTagList() {
        return (
            <>
                {this.filterTags.length > 0 &&
                    <Box>
                        <h2><i className="h--i fa fa-magnifying-glass" />Active Filter</h2>
                        <div className="space-content--half">
                            <TagList
                                models={this.filterTags}
                                ref={this.refTagListActive}
                                preventPageTransition={true}
                            />
                        </div>
                        <Button
                            icon="fa-xmark"
                            size="sm"
                            href="/projects"
                            block={true}
                            preventPageTransition={true}
                        >Clear</Button>
                    </Box>
                }
                <Box>
                    <h2 className="space-content"><i className="h--i fa fa-tags" />Tags</h2>
                    <TagList
                        models={projects.getAllTags()}
                        selectedTags={this.filterTags}
                        ref={this.refTagList}
                        showCount={true}
                        categorize={true}
                        preventPageTransition={true}
                    />
                </Box>
            </>
        )
    }
    renderHeadspace() {
        return (
            <Headspace height="160px" img={require("../../media/headspace/headspace-books-1920.jpg")}>
                <div className="center--vertical">
                    <h1>Projects</h1>
                </div>
            </Headspace>
        );
    }
    renderContent() {
        return (
            <ProjectList
                key={"ProjectList-" + this.filterTags.map((t) => t.title).join('-')}
                num={9}
                filterTags={this.filterTags}
                ref={this.refProjectList}
                showLoadMore={true}
            />
        )
    }
}

