import Tag from "./Tag.js";

export default class Projects {
    constructor(projects) {
        this.projects = projects;
        this.tags = [];
        this.strTags = {}
        this.encodedTags = {};

        this.getProjectsBySimilarity = this.getProjectsBySimilarity.bind(this);
        this.sortSimilarity = this.sortSimilarity.bind(this);
        this.prepareProjects();
        this.prepareTags();
    }
    getAll() {
        return this.projects;
    }

    getAllTags() {
        return this.tags;
    }

    getProject(projectString) {
        return this.encodedProjects[projectString]
    }

    getTag(tagString) {
        return this.encodedTags[tagString];
    }

    getTags(tagStrings) {
        return tagStrings.map((tagString) => this.encodedTags[tagString]);
    }

    prepareProjects() {
        this.encodedProjects = {};
        for (const project of this.projects) {
            this.encodedProjects[project.getID()] = project;
        }
    }

    prepareTags() {
        let tagDict = {};
        for (const project of this.projects) {
            for (const tag of project.tagStrings) {
                if (!(tag in tagDict)) {
                    tagDict[tag] = 0;
                }
                tagDict[tag] ++;
            }
        }

        for (let tag in tagDict) {
            const tagInst = new Tag(tag, tagDict[tag]);
            this.tags.push(tagInst);
            this.strTags[tag] = tagInst;
        }


        this.tags.sort((a, b) => {
            if (a.count < b.count) return 1;
            if (a.count > b.count) return -1;
            return 0;
        });

        for (let tag of this.tags) {
            this.encodedTags[tag.getID()] = tag;
        }
    }

    linkTags() {
        for (const project of this.projects) {
            project.tags = project.tagStrings.map((tag) => this.strTags[tag]);
        }
    }
    

    sortDate(p1, p2) {
        const d1 = p1.date.getTime();
        const d2 = p2.date.getTime();
        if (d1 < d2) return -1;
        if (d1 > d2) return 1;
        return 0;
    }
    sortScore(p1, p2) {
        const d1 = p1.score;
        const d2 = p2.score;
        if (d1 < d2) return 1;
        if (d1 > d2) return -1;
        return 0;
    }
    sortSimilarity(project, p1, p2) {
        const s1 = project.sameTagCount(p1);
        const s2 = project.sameTagCount(p2);
        if (s1 < s2) return 1;
        if (s1 > s2) return -1;
        return this.sortScore(p1, p2);
    }

    filterAndSort(projects, filterTags, sortFct) {
        let newProjects = [...projects];

        if (filterTags.length > 0) {
            newProjects = newProjects.filter((project) => {
                for (const tag of project.tags) {
                    if (filterTags.includes(tag)) {
                        return true;
                    }
                }
                return false;
            });
        }

        newProjects.sort(sortFct);

        return newProjects;
    }

    getProjectsBySimilarity(project, num=3) {
        let projs = [...this.projects];
        
        // remove self
        let index = projs.indexOf(project);
        if (index > -1) {
            projs.splice(index, 1);
        }
        
        // sort by similarity
        projs.sort(this.sortSimilarity.bind(this, project));

        // for (const p of projs) {
        //     console.log(p.title, project.sameTagCount(p));
        // }
        return projs.slice(0, num);
    }
}