import React, { Component } from 'react';
import Video from "./Video";

export default class Headspace extends Component {
    constructor(props) {
        super(props);

        this.refImage = React.createRef();
        this.refVideo = React.createRef();

        this.onScroll = this.onScroll.bind(this);
    }
    componentDidMount() {
        document.addEventListener("scroll", this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll);
    }

    onScroll(e) {
        if (this.props.img) {
            this.refImage.current.style.backgroundPosition = `center calc(50% + ${0.3*window.pageYOffset}px)`;
        }
        if (this.props.video) {
            this.refVideo.current.style.transform = `translateY(${0.3 * window.pageYOffset}px)`;
        }
    }

    render() {
        return (
            <div
                className={"headspace" + (this.props.height === "full" ? " headspace--full" : "")}
            >
                {this.props.img &&
                <div
                    className="headspace__img"
                    style={this.props.img ? {backgroundImage: `url(${this.props.img})`} : {}}
                    ref={this.refImage}
                />
                }

                {this.props.video &&
                <div
                    className="headspace__video"
                    ref={this.refVideo}
                >
                    
                    <Video
                        src={this.props.video}
                    />
                </div>
                }

                <div className="headspace__darkener">
                    <div
                        className="headspace__inner"
                        style={this.props.height !== "full" ? {height: this.props.height} : {}}
                    >
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}