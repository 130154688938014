import React from "react";
import {Link} from "react-router-dom";

import Page from './Page.js';
import Button from "../component/Button";


export default class Page404 extends Page {

    constructor(props) {
        super(props);
        this.setTitle("404");

        this.refEye = React.createRef();

        this.mouseMove = this.mouseMove.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.mouseMove);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.mouseMove);
    }

    mouseMove(e) {
        const eye = this.refEye.current;
        const rect = eye.getBoundingClientRect();
        const eyeX = rect.left + eye.offsetWidth * 0.5;
        const eyeY = rect.top + eye.offsetHeight * 0.5;

        const mouseX = e.pageX;
        const mouseY = e.pageY;

        const dirX = mouseX - eyeX;
        const dirY = mouseY - eyeY;
        const dirLen = Math.sqrt(dirX*dirX + dirY*dirY);
        const dirXNorm = dirX / dirLen;
        const dirYNorm = dirY / dirLen;

        const dirCut = Math.min(0.2*dirLen, eye.offsetWidth * 90 / 1200);
        const lookAtX = dirCut * dirXNorm;
        const lookAtY = dirCut * dirYNorm;

        eye.style.transform = "translate(" + lookAtX + "px, " + lookAtY + "px)"
        
    }

    renderContent() {
        return (
            <>
            <div className="eye">
                <img alt="Invalid URL" title="Error 404 - the requested page could not be found" className="eye__back" src={require("../../media/static/eye-back.png")} ref={this.refEye} />
                <img alt="The requested page could not be found" title="Error 404 - the requested page could not be found" className="eye__front" src={require("../../media/static/eye-front.png")} />
            </div>
            <div className="space-content">
                <div className="text--center">
                    <h1>Error 404</h1>
                    <p>
                        The requested page could not be found! Either you found a broken link on some other site or something went terribly wrong! If you think this could be a bug <Link to="/contact">please contact me</Link>!
                    </p>
                </div>
            </div>
            <div className="space-content">
                <div className="text--center">
                    <Button
                        icon="fa-house"
                        size="lg"
                        href="/"
                    >
                        Back to Home
                    </Button>
                </div>
            </div>
            </>
            
        )
    }
}