import React from "react";

import Page from './Page.js';
import Headspace from "../component/Headspace";
import {address} from "../data/helpers";
import IconLink, {ExternalLink} from "../component/IconLink";

export default class PagePrivacy extends Page {

    constructor(props) {
        super(props);
        this.setTitle("Privacy");
    }

    renderHeadspace() {
        return (
            <Headspace height="160px" img={require("../../media/headspace/headspace-grundgesetz-1920.jpg")}>
                <div className="center--vertical">
                    <h1>Privacy</h1>
                </div>
            </Headspace>
        );
    }
    renderContent() {
        return (
            <>
                <div className="space-content">
                    <p>
                        Personal data (usually referred to just as „data“ below) will only be processed by us to the extent necessary and for the purpose of providing a functional and user-friendly website, including its contents, and the services offered there.</p> <p>Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation (hereinafter referred to as the „GDPR“), „processing“ refers to any operation or set of operations such as collection, recording, organization,  structuring, storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment, or combination, restriction, erasure, or destruction performed on personal data, whether by automated means or not.</p> <p>The following privacy policy is intended to inform you in particular about the type, scope, purpose, duration, and legal basis for the processing of such data either under our own control or in conjunction with others. We also inform you below about the third-party components we use to optimize our website and improve the user experience which may result in said third parties also processing data they collect and control.
                    </p>
                    <p>
                        Our privacy policy is structured as follows:
                    </p>
                    <p>
                        I. Information about us as controllers of your data
                        <br />
                        II. The rights of users and data subjects
                        <br />
                        III. Information about the data processing
                    </p>
                </div>

                <div className="space-content">
                    <h2>I. Information about us as controllers of your data</h2>
                    <p>
                        The party responsible for this website (the „controller“) for purposes of data protection law is:
                    </p>
                    {address.getCompleteBlock()}

                    <p>
                        The controller’s data protection officer is:
                    </p>
                    <p>
                        {address.name}<br />
                        <IconLink icon="envelope" href={"mailto:" + address.mail_legal}>{address.mail_legal}</IconLink><br />
                        <IconLink icon="phone" href={"tel:" + address.phone}>{address.phone}</IconLink><br />
                    </p>
                </div>

                <div className="space-content">
                    <h2>II. The rights of users and data subjects</h2>
                    <p>
                        With regard to the data processing to be described in more detail below, users and data subjects have the right
                    </p>
                    <ul className="ul--text">
                        <li>to confirmation of whether data concerning them is being processed, information
                            about the data being processed, further information about the nature of the data
                            processing, and copies of the data (cf. also Art. 15 GDPR);
                        </li>
                        <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li>
                        <li>to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or,
                            alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3
                            GDPR, to restrict said processing per Art. 18 GDPR;
                        </li>
                        <li>to receive copies of the data concerning them and/or provided by them and to have
                            the same transmitted to other providers/controllers (cf. also Art. 20 GDPR);
                        </li>
                        <li>to file complaints with the supervisory authority if they believe that data
                            concerning them is being processed by the controller in breach of data protection
                            provisions (see also Art. 77 GDPR).
                        </li>
                    </ul>
                    <p>
                        In addition, the controller is obliged to inform all recipients to whom it discloses data of any such corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para. 1, 18 GDPR. However, this obligation does not apply if such notification is impossible or involves a disproportionate effort. Nevertheless, users have a right to information about these recipients.
                    </p>
                    <p>
                        <strong>
                            Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the controller’s future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data processing for the purpose of direct advertising is permissible.
                        </strong>
                    </p>
                </div>

                <div className="space-content">
                    <h2>III. Information about the data processing</h2>
                    <p>
                        Your data processed when using our website will be deleted or blocked as soon as the purpose for its storage ceases to apply, provided the deletion of the same is not in breach of any statutory storage obligations or unless otherwise stipulated below.
                    </p>
                    <h3>Cookies</h3>
                    <h4>a) Session cookies</h4>
                    <p>
                        We use cookies on our website. Cookies are small text files or other storage technologies stored on your computer by your browser. These cookies process certain specific information about you, such as your browser, location data, or IP address.
                    </p>
                    <p>
                        This processing makes our website more user-friendly, efficient, and secure, allowing us, for example, to display our website in different languages or to offer a shopping cart function.
                    </p>
                    <p>
                        The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies are used to collect data to initiate or process contractual relationships.
                    </p>
                    <p>
                        If the processing does not serve to initiate or process a contract, our legitimate interest lies in improving the functionality of our website. The legal basis is then Art. 6 Para. 1 lit. f) GDPR.
                    </p>
                    <p>
                        When you close your browser, these session cookies are deleted.
                    </p>
                    <h3>b) Third-party cookies</h3>
                    <p>
                        If necessary, our website may also use cookies from companies with whom we cooperate for the purpose of advertising, analyzing, or improving the features of our website.</p> <p>Please refer to the following information for details, in particular for the legal basis and purpose of such third-party collection and processing of data collected through cookies.
                    </p>
                    <h4>c) Disabling cookies</h4>
                    <p>
                        You can refuse the use of cookies by changing the settings on your browser. Likewise, you can use the browser to delete cookies that have already been stored. However, the steps and measures required vary, depending on the browser you use. If you have any questions, please use the help function or consult the documentation for your browser or contact its maker for support. Browser settings cannot prevent so-called flash cookies from being set. Instead, you will need to change the setting of your Flash player. The steps and measures required for this also depend on the Flash player you are using. If you have any questions, please use the help function or consult the documentation for your Flash player or contact its maker for support.
                    </p>
                    <p>
                        If you prevent or restrict the installation of cookies, not all of the functions on our site may be fully usable.
                    </p>
                    <h3>Contact</h3>
                    <p>
                        If you contact us via email or the contact form, the data you provide will be used for the purpose of processing your request. We must have this data in order to process and answer your inquiry; otherwise we will not be able to answer it in full or at all.
                    </p>
                    <p>
                        The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR.
                    </p>
                    <p>
                        Your data will be deleted once we have fully answered your inquiry and there is no further legal obligation to store your data, such as if an order or contract resulted therefrom.
                    </p>
                    <h3>LinkedIn</h3>
                    <p>
                        We maintain an online presence on LinkedIn to present our company and our services and to communicate with customers/prospects. LinkedIn is a service of LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a subsidiary of LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085, USA.
                    </p>
                    <p>
                        We would like to point out that this might cause user data to be processed outside the European Union, particularly in the United States. This may increase risks for users that, for example, may make subsequent access to the user data more difficult. We also do not have access to this user data. Access is only available to LinkedIn.
                    </p>
                    <p>
                        The LinkedIn privacy policy can be found here:
                    </p>
                    <p>
                        <ExternalLink href="https://www.linkedin.com/legal/privacy-policy">linkedin.com/legal/privacy-policy</ExternalLink>
                    </p>
                    <h3>LinkedIn</h3>
                    <p>
                        LinkedIn Ireland  Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a subsidiary of LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.
                    </p>
                    <p>
                        Privacy Policy: <ExternalLink href="https://www.linkedin.com/legal/privacy-policy">linkedin.com/legal/privacy-policy</ExternalLink>
                    </p>
                </div>

                <div className="space-content">
                    <p>
                        <ExternalLink href="https://www.generator-datenschutzerklärung.de">Model Data Protection Statement</ExternalLink> for <ExternalLink href="https://www.ratgeberrecht.eu/">Anwaltskanzlei Weiß &amp; Partner</ExternalLink>
                    </p>
                </div>
            </>
        )
    }
}