
import React, { Component } from 'react';
import Project from "./Project";
import projects from "../data/getProjects";
import Button from "../component/Button";

let idCount = 0;
export default class ProjectList extends Component {

    constructor(props) {
        super(props);
        this.num = this.props.num ? this.props.num : 6;
        this.id = idCount ++;
    }

    loadMore() {
        this.num += 6;
        this.forceUpdate();
    }

    render() {
        const origCollection = this.props.collection ? this.props.collection : projects.getAll();
        let collection = projects.filterAndSort(
            origCollection,
            this.props.filterTags || [],
            projects.sortScore,
        );
        const lengthCollection = collection.length;
        collection = collection.slice(0, this.num);

        let elements = [];
        for (let i = 0; i < collection.length; i++) {
            elements.push((
                <div key={collection[i].title} className="col-sm-6 col-lg-4">
                    <Project model={collection[i]} preventPageTransition={this.props.preventPageTransition} projectListId={this.id} />
                </div>)
            );
            if (i > 0 && i % 2 === 1) {
                elements.push(<div key={Math.random()} className="clearfix--sm-md"></div>);
            }
            if (i > 0 && i % 3 === 2) {
                elements.push(<div key={Math.random()} className="clearfix--lg"></div>);
            }
        }

        return (
            <>
                <div className="row space-content">
                    {elements}
                </div>
                <div className="space-content--negative" />
                {(this.props.showLoadMore && this.num < lengthCollection) &&
                    <div className="space-content center--horizontal">
                        <Button
                            key="load-more"
                            icon="fa-angles-down"
                            size="lg"
                            onClick={this.loadMore.bind(this)}
                            preventPageTransition={true}
                        >
                            Load more
                        </Button>
                    </div>
                }
            </>
        );
    }
}
